import Login from '@/pages/esp/user/login.vue';
import PasswordChange from '@/pages/esp/user/password-change.vue';

export default [
  {
    path: '/login',
    name: 'Login',
    components: { default: '', top: '', bottom: '', left: '', all: Login },
    meta: { requiresAuth: false, historyType: 0, isAllPage: true },
  },
  {
    path: '/password-change',
    name: 'PasswordChange',
    components: { default: '', top: '', bottom: '', left: '', all: PasswordChange },
    meta: { requiresAuth: true, historyType: 0, isAllPage: true },
  },
];
