import Vue from 'vue'
import Vuex from 'vuex'
import { state } from './state';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';

import createPersistedState from 'vuex-persistedstate';
import persistedStateStore from './modules/persistedstate-store.js';

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        persistedStateStore,
    },
    plugins: [
        // vuex-persistedstate 를 사용하여 store 의 상태를 localstorage 에 저장
        createPersistedState({
            paths: [
                'persistedStateStore',
            ]
        })
    ],
    state,
    mutations,
    actions,
    getters,
});

export default store;
