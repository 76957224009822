<!--
  PACKAGE_NAME : src\pages\esp\system\menu\favorite.vue
  FILE_NAME : favorite
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 메뉴 즐겨찾기
-->
<template>
  <div style="display: block; width: 100%">
    <div class="grid grid-flow-row-dense grid-cols-6">
      <MenuSelect class="col-span-5" ref="menuSelect" />
      <DxButton
        text="추가"
        class="btn_XS default filled col-span-1 self-end"
        style="margin-bottom: 15px"
        :disabled="getSelectMenu() == null"
        @click="addToGrid"
      />
    </div>
    <div class="grid grid-flow-row-dense grid-cols-1">
      <!-- 나의 즐겨찾기 메뉴 dxGrid -->
      <DxDataGrid
        ref="favoriteMenu"
        :height="287"
        :data-source="favoriteMenu"
        :onRowPrepared="onRowPrepared"
        key-expr="menuId"
        no-data-text="데이터가 존재하지 않습니다."
      >
        <DxRowDragging :allow-reordering="true" :on-reorder="onReorder" />
        <DxSorting mode="none" />
        <DxScrolling mode="virtual" />
        <DxColumn caption="나의 즐겨찾기 메뉴" data-field="menuId" cell-template="menuNameTemplate" />
        <DxColumn :width="100" caption="삭제" data-field="id" cell-template="deleteButton" />
        <template #menuNameTemplate="{ data }">
          <div style="text-align: left">
            {{ menuNameFromStore(data.value) }}
          </div>
        </template>

        <template #deleteButton="{ data }">
          <div class="grid place-items-center">
            <Button class="btn-icon close" @click="onPrepareDelete(data)"></Button>
          </div>
        </template>
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
  import MenuSelect from '@/pages/esp/system/menu/favorite-menu-select.vue';
  import { DxDataGrid, DxColumn, DxScrolling, DxRowDragging, DxSorting } from 'devextreme-vue/data-grid';
  import { DxButton } from 'devextreme-vue/button';

  import { isSuccess, isEmpty } from '@/plugins/common-lib';

  const arraysEqualWithOrder = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
        return false;
      }
    }

    return true;
  };

  export default {
    components: {
      MenuSelect,
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxRowDragging,
      DxSorting,
      DxButton,
    },
    props: {},
    watch: {
      favoriteMenu(newValue) {
        this.$refs?.favoriteMenu?.instance?.refresh();
      },
    },
    data() {
      return {
        orginMenu: [],
        favoriteMenu: [],
        deleteList: [],
        selectMenu: null,
        isChanged: false,
      };
    },
    computed: {},
    methods: {
      findMenuFromStore(id, depth = 3) {
        return this.$store.getters.getMenuList.find(d => d.id === id && d.menuDepth === depth);
      },
      menuNameFromStore(id) {
        const menu = this.findMenuFromStore(id);
        const menu2 = this.findMenuFromStore(menu?.parentId ?? 0, 2) ?? null;
        const menu1 = this.findMenuFromStore(menu2?.parentId ?? 0, 1) ?? null;

        if (menu1 != null && menu2 != null) {
          return `${menu1.menuNm} > ${menu2.menuNm} > ${menu?.menuNm ?? '없음'}`;
        } else {
          return menu?.menuNm ?? '없음';
        }
      },
      getSelectMenu() {
        const selectList = this.$refs?.menuSelect?.getSelectMenuInfo() ?? [];
        return selectList.menu3?.[0] ?? null;
      },
      reorderFavoriteMenu(newList = []) {
        // reorder
        let order = 1;
        this.favoriteMenu = newList.map(item => {
          return { ...item, order: order++ };
        });
      },
      onReorder(e) {
        const visibleRows = e.component.getVisibleRows();
        const toIndex = this.favoriteMenu.findIndex(item => item.menuId === visibleRows[e.toIndex].data.menuId);
        const fromIndex = this.favoriteMenu.findIndex(item => item.menuId === e.itemData.menuId);
        const newList = [...this.favoriteMenu];

        newList.splice(fromIndex, 1);
        newList.splice(toIndex, 0, e.itemData);

        this.reorderFavoriteMenu(newList);
      },
      onRowPrepared(e) {
        if (e.rowType === 'data' && isEmpty(e.data.id)) {
          e.rowElement.bgColor = '#e5f7f9';
        }
      },
      onPrepareDelete(e) {
        const findId = e.data.menuId;
        const findIndex = this.favoriteMenu.findIndex(item => item.menuId === findId);
        if (findIndex > -1) {
          if (!isEmpty(e.data.id)) {
            this.deleteList.push({ menuId: findId });
          }
          this.favoriteMenu.splice(findIndex, 1);
        }
      },
      addToGrid() {
        const selectList = this.$refs.menuSelect.getSelectMenuInfo();
        const selectMenu = selectList.menu3 ?? [];

        if (isEmpty(selectMenu)) {
          this.$_Msg('선택한 메뉴가 없습니다.');
          return;
        }

        if (this.favoriteMenu.findIndex(item => item.menuId === selectMenu[0].id) > -1) {
          this.$_Msg('이미 추가된 메뉴입니다.');
          return;
        }

        this.favoriteMenu.push({
          id: null,
          menuId: selectMenu[0].id,
          order: null,
        });
      },
      async deleteMenuFavorite() {
        if (this.deleteList.length === 0) {
          return false;
        }

        const payload = {
          actionname: 'MENU_FAVORITE_DELETE',
          data: {
            data: this.deleteList.map(item => {
              return {
                menuId: item.menuId,
              };
            }),
          },
          useErrorPopup: true,
        };
        await this.CALL_API(payload);
        this.isChanged = true;
      },
      async saveMenuFavorite() {
        const payload = {
          actionname: 'MENU_FAVORITE_SAVE',
          data: this.favoriteMenu
            .filter(item => isEmpty(item.id))
            .map(item => {
              return {
                menuId: item.menuId,
              };
            }),
          loading: true,
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        }
        this.isChanged = true;
      },
      async saveForModal() {
        try {
          if (this.deleteList.length > 0) {
            // 삭제 데이터 있는지 확인.
            await this.deleteMenuFavorite();
          } else if (arraysEqualWithOrder(this.orginMenu, this.favoriteMenu)) {
            // 같은지 여부 비교
            return { closeFlag: false, error: '변경된 내용이 없습니다.' };
          }

          if (this.favoriteMenu.length) {
            await this.saveMenuFavorite();
          }
        } catch (e) {
          return { closeFlag: false, error: e.message };
        }

        return { closeFlag: true, isChanged: this.isChanged };
      },
      async resetSelect() {
        this.$refs.menuSelect.resetSelect();
        this.reorderFavoriteMenu(this.$store.getters.getFavorite);
        this.orginMenu = [...this.favoriteMenu];
        this.deleteList = [];
        this.selectMenu = null;
        this.isChanged = false;
      },
      async mounted() {
        await this.resetSelect();
      },
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
      this.mounted();
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
  };
</script>

<style scoped></style>