import moment from 'moment';
const currentByFormat = format => {
  return moment().format(format);
};

const ReportManager = {
  setInitData() {
    return {
      init: {
        currentYear: currentByFormat('YYYY'),
        currentMonth: currentByFormat('MM'),
        currentDate: currentByFormat('YYYYMMDD'),
        report: '',
        periodYear: [],
        periodMonth: [],
        periodBtns: [],
        v_periodBtns: ReportManager.getPeriodBtns(),
        reportTypeOpts: [],
        v_reportTypeOpts: ReportManager.getReportTypes(),
        searchCondition: [],
        times: new Array(25).fill().map((v, i) => {
          const time = i < 10 ? `0${i}` : i.toString();
          return { name: time, value: time };
        }),
        minutes: ['00', '15', '30', '45'].map(v => ({
          name: v,
          value: v,
        })),
      },
      status: {
        depth: 1,
        addSearchByte: 0,
        objKey: '',
        periodResult: '',
        periodType: 'DAY',
        startDt: currentByFormat('YYYY-MM-DD'),
        endDt: currentByFormat('YYYY-MM-DD'),
        selectedBtnType: 'check', // check || input
        windowPopup: false,
        showWindowBtn: true,
        openConditionModal: false,
        disabledDays: false,
        disabledTimes: false,
        // 국세청(내선번호 타겟 YN - U_USER > STAST_FL => TARGET_FL)
        stastFl: {
          Y: true,
          N: false,
        },
      },
      target: {
        //부서 리스트
        dept1: [],
        dept2: [],
        dept3: [],
        dept4: [],
        dept5: [],
        v_dept1: [],
        v_dept2: [],
        v_dept3: [],
        v_dept4: [],
        v_dept5: [],
        chk_dept1: [],
        chk_dept2: [],
        chk_dept3: [],
        chk_dept4: [],
        chk_dept5: [],
        disabled1: false,
        disabled2: false,
        disabled3: false,
        disabled4: false,
        disabled5: false,
        //단일 리스트(Custom)
        v_item1: '',
        v_item2: '',
        v_item3: '',
        v_item4: '',
        v_item5: '',
        customList: [],
      },
      formData: {
        searchParams: {
          actionname: 'REPORT_RESULT_LIST',
          type: 'master-query', //XML 파일 결정해주는 값
          day: 'grid',
          startDt: currentByFormat('YYYYMMDD'),
          endDt: currentByFormat('YYYYMMDD'),
          targetQuery: '',
          days: [1, 2, 3, 4, 5, 6, 7],
          obj: [], // customList, obj${n} 은 동적으로 생길 수 있음
          objType: null,
          objKey: null,
          optionId: null,
          summary: null,
          report_type: 'daily',
          startTime_H: '00',
          startTime_M: '00',
          endTime_H: '24',
          endTime_M: '00',
          // 경로
          solution: '',
          subPath: '',
        },
      },
    };
  },
  getPeriodBtns() {
    return [
      { name: '일', type: 'DAY' },
      { name: '월', type: 'MONTH' },
      { name: '년', type: 'YEAR' },
      { name: '기간선택', type: 'RANGE' },
    ];
  },
  reportTypes: [
    { name: '일별', value: 'daily' },
    { name: '월별', value: 'monthly' },
    { name: '시간별', value: 'hour' },
    { name: '15분별', value: 'i15' },
    { name: '30분별', value: 'i30' },
    { name: '일+시간', value: 'daytimes' },
  ],
  getReportTypes() {
    return this.reportTypes;
  },
  getReportTypeName(type) {
    return this.reportTypes.find(v => v.value === type)?.name || '기간별';
  },
  getDayOfTheWeek() {
    // 기간 -> 요일 선택
    return [
      { id: 'mon', alt: '월', value: '2', checked: true },
      { id: 'tue', alt: '화', value: '3', checked: true },
      { id: 'wed', alt: '수', value: '4', checked: true },
      { id: 'thu', alt: '목', value: '5', checked: true },
      { id: 'fri', alt: '금', value: '6', checked: true },
      { id: 'sut', alt: '토', value: '7', checked: true },
      { id: 'sun', alt: '일', value: '1', checked: true },
      { id: 'holi', alt: '공휴일', value: 'Y', checked: true },
    ];
  },
  getGridFilter() {
    return [
      { name: '데이터', value: 1 },
      { name: '소계', value: 2 },
      { name: '합계', value: 4 },
    ];
  },
  getTargetBtns() {
    return [{ name: '부서', type: 'DEPT' }];
  },
};

export { ReportManager };
