<!--
  PACKAGE_NAME : src/components/report/searchbox/condition/modal-add-content.vue
  FILE_NAME : modal-add-content.vue
  AUTHOR : vnemftnsska2
  DATE : 2024/07/09
  DESCRIPTION :
-->
<template>
	<transition>
		<div class="contents-wrap">
			<div class="contents-box">
				<div class="conts-row">
					<div class="conts-box">
						<div class="conts-title">검색조건명<span style="color: red">*</span></div>
						<DxTextBox v-model="title" :styling-mode="stylingMode" :width="250" @key-up="onCheckTextSize($event)">
							<DxValidator ref="validator">
								<DxRequiredRule message="조건검색명은 필수입니다." />
							</DxValidator>
						</DxTextBox>
						<div class="limitLength-box">{{ textSize }}/{{ limitLength }}</div>
					</div>
					<div class="bottomMessage">
						현재의 검색 조건을 저장하고, 검색 조건 목록을 클릭하여 불러올 수 있습니다.<br />
						해당 검색 조건을 저장하시겠습니까?
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';

export default {
	components: {
		DxTextBox,
		DxValidator,
		DxRequiredRule,
	},
	props: {},
	watch: {},
	data() {
		return {
			title: '',
			textSize: 0,
			limitLength: 50,
			stylingMode: 'outlined', //[outlined, filled, underlined]
		};
	},
	computed: {},
	methods: {
		onCheckTextSize(e) {
			const text = e.event.target.value;
			this.textSize = text.length;
		},
		getTitleAndLimitSize() {
			return { title: this.title.trim(), limitSize: this.limitLength + 1 };
		},
		init() {
			this.title = '';
			this.textSize = 0;
			this.$refs.validator.instance.reset();
		},
	},
	created() {},
	mounted() {},
	destroyed() {},
};
</script>

<style scoped>
.contents-box {
	width: 100%;
	padding: 20px 10px;
	background-color: #fff;
}

.conts-row:not(:last-child) {
	margin-bottom: 20px;
}

.conts-row .conts-box {
	display: inline-block;
}

.conts-row .conts-box > div {
	display: inline-block;
}

.conts-row .conts-box .conts-title {
	width: 120px;
	display: inline-block;
	text-align: center;
	padding: 12px;
}

.conts-row .conts-box .conts-input {
	display: -webkit-inline-box;
}

.conts-row .conts-box .limitLength-box {
	margin-left: 5px;
	display: inline-block;
}

.bottomMessage {
	margin-top: 20px;
	font-size: 12px;
	text-align: center;
}
</style>
