<!--
  PACKAGE_NAME : src\components\report\search-box.vue
  FILE_NAME : search-box.vue
  AUTHOR : kwmoon
  DATE : 2024-06-19
  DESCRIPTION : 위자드 보고서 상단 검색 박스
  TODO: 코드 정리 및 영역별 컴포넌트 분리 예정
-->
<template>
  <div class="container">
    <!--   검색 컴포넌트 컨테이너(모달 제외)  -->
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <!-- START: 검색조건 관리 -->
      <popoverSearchCondition
        ref="popoverSearchCondition"
        :info="reportInfo"
        :getReportSearchParams="getReportSearchParams"
        :onClickSearchCondition="handleSetSearchCondition"
      />

      <!-- START: 기간 / 대상 보고서 유형 -->
      <div class="report-search">
        <div class="page_search_box">
          <div class="inner2 clearfix">
            <div class="flex justify-between">
              <div>
                <!-- 기간설정 -->
                <button
                  ref="dateLayerBtn"
                  :class="styles.offLayerBtn"
                  style="height: 29px"
                  @click="toggleHiddenSection($event, 'dateHiddenLayer')"
                >
                  기간 <span :class="styles.offUpDown"></span>
                </button>
                <div
                  class="ui-datepicker-item"
                  style="margin-right: 5px"
                  @click="toggleHiddenSection($event, 'dateHiddenLayer', 'dateLayerBtn')"
                >
                  <input type="text" class="pointer calr dx-outline" :value="setPeriodDate" style="width: 192px" readonly />
                </div>
                <span class="mr-10 pointer" @click="toggleHiddenSection($event, 'dateHiddenLayer', 'dateLayerBtn')">
                  <input type="text" class="pointer dx-outline" :value="status.periodResult" style="width: 255px" readonly />
                </span>
                <!-- 대상설정 -->
                <span class="mar-ri10">
                  <button
                    v-for="v in PAGE_DATA.targetBtns"
                    :class="styles.offLayerBtn"
                    style="height: 29px"
                    :ref="`${v.type}LayerBtn`"
                    :key="v.type"
                    :data-type="v.type"
                    @click="toggleHiddenSection($event, `${v.type}HiddenLayer`)"
                  >
                    {{ v.name }} <span :class="styles.offUpDown"></span>
                  </button>
                </span>
                <span>
                  <input id="selectedSizeMessage" type="text" class="w150 dx-outline mr-0" :value="selectedTargetSizeMessage" readonly />
                </span>

                <!-- 보고서유형 -->
                <span v-show="init.reportTypeOpts.length > 0">
                  <span class="ml-20 mr-15">유형</span>
                  <span class="top2">
                    <DxSelectBox
                      placeholder="보고서 유형"
                      :data-source="init.reportTypeOpts"
                      display-expr="name"
                      value-expr="value"
                      v-model="formData.searchParams.report_type"
                      :styling-mode="PAGE_DATA.dxStyled"
                      @value-changed="handleChangeReportType"
                      width="100px"
                      :height="30"
                    >
                    </DxSelectBox>
                  </span>
                </span>
              </div>
              <div v-if="isWizardReport">
                <button type="button" class="btn_M box-btn-search" style="min-height: 29px; height: 29px" @click="onSearchReport(0)">
                  조회
                </button>
                <button
                  v-show="status.showWindowBtn"
                  type="button"
                  class="btn_XXS white"
                  style="height: 29px"
                  @click="onSearchReport(0, true)"
                >
                  <img src="@/assets/images/report/popup_icon.png" /> 새창 조회
                </button>
                <button type="button" id="popoverSearchConditionBtn" class="btn_XXS white px-0.5" style="height: 29px; padding: 0">
                  <img src="@/assets/images/report/search_manage_icon.png" />
                </button>
                <button
                  v-show="$store.getters.getLoginId === 'ecstel' || $_isPermitted('auth_edit_xml')"
                  type="button"
                  class="btn_XXS white"
                  style="height: 29px; padding: 0"
                  @click="toggleXmlModal"
                >
                  <img src="@/assets/images/report/edit_xml_icon.png" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- 캘린더 클릭 시 레이어 출력 -->
        <div ref="dateHiddenLayer" class="border-t border-gray-200 pt-6 pb-3 hidden">
          <div class="flex flex-wrap justify-between py-2 space-y-6 md:space-y-4 lg:space-y-0">
            <!-- 기간선택 -->
            <div class="w-full lg:w-1/3 p-2">
              <h2 class="text-xl font-medium mb-2">기간 선택</h2>
              <div class="space-y-4 md:space-y-2 lg:space-y-0 lg:flex lg:items-center lg:space-x-4 mb-4">
                <div ref="periodSection" class="flex space-x-1">
                  <button
                    v-for="({ name, type }, index) in init.periodBtns"
                    class="btn_XXS blue2"
                    :key="index"
                    :data-type="type"
                    @click="onClickPeriodBtn(type)"
                  >
                    {{ name }}
                  </button>
                </div>
                <div class="space-y-2 lg:space-y-0 lg:flex lg:items-center lg:space-x-2 w-full lg:w-auto">
                  <!-- 년, 월 -->
                  <div v-show="['MONTH', 'YEAR'].includes(status.periodType)" class="flex flex-wrap space-x-1.5">
                    <DxSelectBox
                      v-model="init.currentYear"
                      :data-source="init.periodYear"
                      display-expr="name"
                      value-expr="value"
                      :styling-mode="PAGE_DATA.dxStyled"
                      @value-changed="onChangePeriod($event, 'YEAR')"
                      width="123px"
                      :height="30"
                    ></DxSelectBox>
                    <DxSelectBox
                      v-show="['MONTH'].includes(status.periodType)"
                      v-model="init.currentMonth"
                      :data-source="init.periodMonth"
                      display-expr="name"
                      value-expr="value"
                      :styling-mode="PAGE_DATA.dxStyled"
                      @value-changed="onChangePeriod($event, 'MONTH')"
                      width="100px"
                      :height="30"
                    ></DxSelectBox>
                  </div>
                  <!-- 일, 기간선택 -->
                  <div v-show="['DAY', 'RANGE'].includes(status.periodType)" class="flex flex-wrap items-center space-x-2">
                    <!-- 일 -->
                    <div class="ui-datepicker period flex items-center space-x-1.5">
                      <DxDateBox
                        ref="startDt"
                        v-model="status.startDt"
                        :styling-mode="PAGE_DATA.dxStyled"
                        :openOnFieldClick="true"
                        width="123px"
                        @value-changed="onChangePeriod($event, 'DAY')"
                        type="date"
                        display-format="yyyy-MM-dd"
                        dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                      ></DxDateBox>
                      <!-- 기간선택 -->
                      <div v-show="['RANGE'].includes(status.periodType)" class="flex items-center space-x-1.5">
                        <span>~</span>
                        <DxDateBox
                          ref="endDt"
                          v-model="status.endDt"
                          :styling-mode="PAGE_DATA.dxStyled"
                          width="123px"
                          :openOnFieldClick="true"
                          @value-changed="onChangePeriod($event, 'RANGE')"
                          type="date"
                          display-format="yyyy-MM-dd"
                          dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                        ></DxDateBox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 요일선택 -->
            <div class="w-full lg:w-1/3 p-2">
              <h2 class="text-xl font-medium mb-2">요일 선택</h2>
              <div class="flex flex-wrap space-x-4 mb-6">
                <span v-for="item in PAGE_DATA.daysChks" :key="item.value" class="flex items-center pt-1.5 space-x-1.5">
                  <DxCheckBox
                    :key="item.id"
                    :text="item.alt"
                    :value="item.value"
                    v-model="item.checked"
                    :disabled="status.disabledDays"
                    @value-changed="onClickPeriodOptions"
                  />
                </span>
              </div>
            </div>
            <!-- 시간선택 -->
            <div class="w-full lg:w-1/3 p-2">
              <h2 class="text-xl font-medium mb-2">시간 선택</h2>
              <div class="flex flex-wrap items-center space-x-1.5 mb-4">
                <DxSelectBox
                  placeholder="시"
                  :data-source="init.times"
                  display-expr="name"
                  v-model="formData.searchParams.startTime_H"
                  value-expr="value"
                  :styling-mode="PAGE_DATA.dxStyled"
                  @value-changed="onClickPeriodOptions"
                  width="82px"
                  :disabled="status.disabledTimes"
                  :height="30"
                />
                <span>:</span>
                <DxSelectBox
                  placeholder="분"
                  :data-source="init.minutes"
                  display-expr="name"
                  v-model="formData.searchParams.startTime_M"
                  value-expr="value"
                  :styling-mode="PAGE_DATA.dxStyled"
                  @value-changed="onClickPeriodOptions"
                  width="82px"
                  :disabled="status.disabledTimes"
                  :height="30"
                />
                <span>~</span>
                <DxSelectBox
                  placeholder="시"
                  :data-source="init.times"
                  display-expr="name"
                  v-model="formData.searchParams.endTime_H"
                  value-expr="value"
                  :styling-mode="PAGE_DATA.dxStyled"
                  @value-changed="onClickPeriodOptions"
                  width="82px"
                  :disabled="status.disabledTimes"
                  :height="30"
                />
                <span>:</span>
                <DxSelectBox
                  placeholder="분"
                  :data-source="init.minutes"
                  display-expr="name"
                  v-model="formData.searchParams.endTime_M"
                  value-expr="value"
                  :styling-mode="PAGE_DATA.dxStyled"
                  @value-changed="onClickPeriodOptions"
                  width="82px"
                  :disabled="status.disabledTimes"
                  :height="30"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- END: 기간 / 대상 / 보고서 유형 -->
        <!-- START: 대상[CHECK] 레이어 -->
        <div ref="checkHiddenLayer" class="border-top-1px layer-box hidden">
          <div v-if="computedCheckBtnOptions" v-show="!isMultiSelectable" class="border-t border-gray-400 p-3 flex justify-between">
            <div class="flex items-center space-x-2 w-1/2">
              <!-- 조회기준 텍스트와 SelectBox -->
              <span class="text-base font-medium mx-2">조회기준</span>
              <!-- 조회그룹 (RadioGroup) -->
              <span>
                <DxRadioGroup
                  class="check-type col"
                  ref="targetGroupRadio"
                  :dataSource="computedCheckBtnOptions"
                  :value="1"
                  display-expr="name"
                  value-expr="depth"
                  layout="horizontal"
                  @value-changed="changeRadioInGroupSection"
                />
              </span>
            </div>

            <!-- 옵션과 체크박스 그룹 -->
            <div v-show="PAGE_DATA.optionItems.length > 0" class="flex items-center space-x-2 w-1/2">
              <span class="text-base font-medium mr-2">옵션</span>
              <DxCheckBox
                v-for="(item, i) in PAGE_DATA.optionItems"
                :key="i"
                :text="item.name"
                :value="item.checked"
                @value-changed="updateCheckOptionItem($event, i)"
              />
            </div>
          </div>
          <!-- 조회그룹 트리리스트 박스 -->
          <div v-if="computedCheckBtnOptions" class="border-t border-gray-200 h-[450px] flex flex-wrap justify-start gap-2">
            <DepthTreeBox
              class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5"
              v-for="(v, i) in computedCheckBtnOptions"
              ref="depthTreeBox"
              :title="v.name"
              :key="v.name"
              :is="DepthTreeBox"
              :dataName="`dept${i + 1}`"
              :nextDataName="v.hasNext ? `dept${i + 2}` : undefined"
              :treeDatas="target[(i === 0 || isMultiSelectable ? '' : 'v_') + `dept${i + 1}`]"
              :disabled="isMultiSelectable ? false : target[`disabled${i + 1}`]"
              :pageLoaded="pageLoaded"
              @clearCheckedItems="clearCheckedItems(i + 1)"
              @setCheckedItems="setCheckedItems"
            />
          </div>
          <!-- 조회그룹 추가 옵션 -->
          <div
            v-if="checkBtnInputOptions && checkBtnInputOptions.length > 0"
            class="border-t border-gray-200 h-[450px] flex flex-wrap justify-start gap-2 p-2"
          >
            <div class="w-[18%] mr-2" v-for="(option, i) in checkBtnInputOptions" :key="i">
              <label class="mr-10 text-sm w-full">{{ option.name }}</label>
              <DxTextBox
                class="w-full"
                :show-clear-button="true"
                :styling-mode="PAGE_DATA.dxStyled"
                :width="option.width || 120"
                :input-attr="{ style: 'font-size: 11px; padding: 0 5px; height: 25px;' }"
                @value-changed="handleChangeInputOption($event, i)"
              />
            </div>
          </div>
        </div>
        <!-- END: 대상[DEPTH CHECK] 레이어 -->

        <!-- START: 대상[INPUT] 레이어 -->
        <div ref="inputHiddenLayer" class="page_search_box line_top_1px layer-box hidden">
          <div class="p-20" v-if="PAGE_DATA.inputBtnInfo">
            <div class="flex">
              <div class="" v-for="(option, i) in PAGE_DATA.inputBtnInfo.options" :key="i">
                <label class="mr-10">{{ option.name }}</label>
                <DxTextBox
                  class="mr-20"
                  v-model="target['v_item' + (i + 1)]"
                  :show-clear-button="true"
                  :styling-mode="PAGE_DATA.dxStyled"
                  :width="300"
                >
                </DxTextBox>
              </div>
              <button type="button" class="btn_XS blue2" @click="onClickAddCustomItems">대상 추가</button>
            </div>
            <div class="mt-20" style="width: 1240px; max-height: 200px">
              <DxDataGrid ref="inputTargetGrid" :height="180" :data-source="target.customList" no-data-text="데이터가 존재하지 않습니다.">
                <DxScrolling mode="standard" />
                <DxEditing mode="cell" :allow-updating="true" :use-icons="true" />
                <DxColumn cell-template="indexTemplate" alignment="center" :allow-editing="false" :visible="true" :width="80" />
                <DxColumn
                  v-for="(item, i) in PAGE_DATA.inputBtnInfo.options"
                  :key="i"
                  :caption="item.name"
                  :dataField="'item' + (i + 1)"
                  alignment="center"
                />
                <DxColumn
                  caption="삭제"
                  cell-template="removeTemplate"
                  alignment="center"
                  :allow-editing="false"
                  :visible="true"
                  :width="80"
                />
                <template #indexTemplate="{ data }">
                  <div>
                    {{ data.rowIndex + 1 }}
                  </div>
                </template>
                <template #removeTemplate="{ data }">
                  <div>
                    <DxButton
                      text="삭제"
                      template="<span class='mdi mdi-trash-can'></span>"
                      @click="onDeleteInputTargetItem(data.rowIndex)"
                    />
                  </div>
                </template>
              </DxDataGrid>
            </div>
          </div>
        </div>
        <!-- END: 대상[INPUT] 레이어 -->
      </div>
    </div>

    <!-- POPUP(ON, OFF) -->
    <div id="app1">
      <WindowPopup v-model="status.windowPopup" :urls="this.newPopupUrl" :datas="formData.searchParams" :params="newWindow.params" />
    </div>

    <!-- START: XML 히스토리 및 수정 팝업  -->
    <ESPModalPopup
      :isOpen="modal.isOpenXmlModal"
      :option="modal.xmlModalOption"
      @saveModal="saveXmlModal"
      @closeModal="toggleXmlModal(false)"
    >
      <template #content>
        <XmlEditor
          ref="xmlEditor"
          :options="modal.editorOption"
          :history="modal.xmlHistories"
          :reportId="reportInfo ? reportInfo.reportId : 0"
        />
      </template>
    </ESPModalPopup>
    <!-- END: XML 히스토리 및 수정 팝업  -->
  </div>
</template>

<script>
  //Dev-Extreme
  import { DxButton } from 'devextreme-vue/button';
  import { DxTagBox } from 'devextreme-vue/tag-box';
  import { DxPopover } from 'devextreme-vue/popover';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import { DxDataGrid, DxColumn, DxScrolling, DxPaging, DxSelection, DxEditing } from 'devextreme-vue/data-grid';
  // 조회그룹 컴포넌트
  import DepthTreeBox from './depth-tree-box.vue';
  import ModalUserSearch from './modal-user-search.vue';
  // 제작 컴포넌트
  import DxPopup from '../devextreme/esp-dx-modal-popup.vue';
  import WindowPopup from './WindowPopup.vue';
  import ESPModalPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import { ReportManager } from '@/pages/report/report';
  import XmlEditor from '@/pages/report/config/modal-xml-editor-update';
  //Util
  import moment from 'moment';
  import { cloneObj, getResData, isEmpty, isSuccess, isTrue } from '@/plugins/common-lib';
  import { DxNumericRule } from 'devextreme-vue/form';
  import { DxNumberBox } from 'devextreme-vue/number-box';
  import DxRadioGroup from 'devextreme-vue/radio-group';
  import PopoverSearchCondition from '@/components/report/searchbox/condition/popover-search-condition.vue';

  //Data
  let vm = null;
  const $ = window.$;
  const DEPTH_NUMBERS = [1, 2, 3, 4, 5];
  export default {
    components: {
      PopoverSearchCondition,
      DxRadioGroup,
      DxNumberBox,
      DxNumericRule,
      DxCheckBox,
      DxButton,
      DxDateBox,
      DxSelectBox,
      DepthTreeBox,
      WindowPopup,
      DxTextBox,
      DxDropDownBox,
      DxDataGrid,
      DxColumn,
      DxSelection,
      DxEditing,
      DxPaging,
      DxScrolling,
      DxPopup,
      DxTagBox,
      DxPopover,
      ModalUserSearch,
      ESPModalPopup,
      XmlEditor,
    },
    watch: {
      pageLoaded() {
        if (this.pageLoaded === false) return;
        this.setPageLayout();
        this.initMounted();
      },
    },
    props: {
      /** 보고서 정보 */
      reportInfo: {
        type: Object,
        required: true,
        default: () => ({}),
      },
      newPopupUrl: String,
      pageLoaded: Boolean,
      getChkDepthTarget: Function,
      getSelectedReportFilterKeys: {
        type: Function,
        default: () => ['1', '2', '4'],
      },
      isWizardReport: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        DepthTreeBox: 'DepthTreeBox',
        PAGE_DATA: {
          /***** components setting start ****/
          checkBtnInfo: null,
          inputBtnInfo: null,
          optionItems: [],
          //OPTIONS
          useStorageData: true, //로컬스토리지에 저장되어 있는 조회그룹 사용할지 여부
          showReportType: true, //보고서 타입 보이기 여부
          multiDownload: [], // 보고서 일괄 다운로드 (자신 ID도 적어야 함)
          /** MasterQuery
           * {
           *     name: sql name 속성 값
           *     type: "master-query"
           *     objKey: 파라미터 objKey 값
           * }
           */
          lastDepthApi: undefined,
          targetBtns: ReportManager.getTargetBtns(),
          daysChks: ReportManager.getDayOfTheWeek(),
          /***** components setting end by report.js ****/

          //STYLE
          dxStyled: 'outlined',
          //ERROR_MESSAGE
          ERR_MSG_DATE: '선택한 날짜를 확인해주시기 바랍니다.',
          ERR_MSG_DAYS: '선택한 요일을 확인해주시기 바랍니다.',
          ERR_MSG_TIME: '선택한 시간을 확인해주시기 바랍니다.',
          ERR_MSG_TARGET: '대상을 하나 이상 선택해주시기 바랍니다.',
          ERR_MSG_CUSTOM_TARGET: '대상을 추가해주시기 바랍니다.',
          ERR_MSG_MAX_SELECTED_TARGET: '대상은 최대 {n}개까지 선택 가능합니다.',
        },
        styles: {
          onLayerBtn: 'btn_XXS blue2 on',
          onUpDown: 'mdi mdi-chevron-up icon-white',
          offLayerBtn: 'btn_XXS white',
          offUpDown: 'mdi mdi-chevron-down',
        },
        modal: {
          isOpenXmlModal: false,
          xmlModalOption: {
            title: 'XML 관리',
            width: '80%',
            height: '85%',
            minWidth: null,
            minHeight: null,
          },
          editorOption: {
            type: 'REPORT',
            reportNm: '',
            useRight: true,
            rsWidth: '30%', // 우측 섹션 넓이
          },
          xmlHistories: [],
        },
        newWindow: { params: {} },
        // Param Datas
        ...ReportManager.setInitData(), // init, status, target, formData
      };
    },
    computed: {
      /** 기간 input 박스 날짜 출력 */
      setPeriodDate() {
        const { startDt, endDt } = this.formData.searchParams;
        return `${moment(startDt).format('YYYY/MM/DD')} ~ ${moment(endDt).format('YYYY/MM/DD')}`;
      },
      /** 선택한 조회 대상 개수 출력 */
      selectedTargetSizeMessage() {
        const depth = this.status.depth;
        const selectedBtnType = this.status.selectedBtnType;
        const countMessage = [];
        let allCount = 0;
        try {
          if ('input' === selectedBtnType) {
            allCount = this.target.customList.length;
            countMessage.push(`단일: ${allCount.toLocaleString('ko-KR')} 개`);
          } else {
            // selectedType => check
            const name = this.PAGE_DATA.checkBtnInfo.name;
            let selectedTargetSize = this.getSelectedTarget(depth).length;
            countMessage.push(`${name}: ${selectedTargetSize.toLocaleString('ko-KR')} 개`);
            allCount = selectedTargetSize;
          }
        } catch (e) {
          // console.log('selectedSizeMessage:', e);
        }

        let resultMessage = '선택 없음';
        if (allCount > 0) resultMessage = countMessage.join(' | ');
        this.changeFontSizeOfMessage(resultMessage);
        return resultMessage;
      },
      computedCheckBtnOptions() {
        return this.PAGE_DATA?.checkBtnInfo?.options;
      },
      checkBtnInputOptions() {
        return this.PAGE_DATA?.checkBtnInfo?.inputOptions;
      },
      /** 트리구조 계층 구조인지, 또는 동시 설정 가능 구조인지 여부 */
      isMultiSelectable() {
        const checkBtnInfo = this.PAGE_DATA.checkBtnInfo;
        if (checkBtnInfo) {
          return !!checkBtnInfo.isMultiSelectable;
        }
        return false;
      },
    },
    methods: {
      /** 대상(input) 삭제 기능 - 단일(customList) */
      onDeleteInputTargetItem(rowIndex) {
        this.target.customList.splice(rowIndex, 1);
      },
      /** 선택 대상 출력 메시지가 길면 글씨를 작게 표현 */
      changeFontSizeOfMessage(message) {
        const fontSizeCls = 'fs-12';
        const countElem = document.getElementById('selectedSizeMessage');
        if (countElem) {
          const messageSize = message.split('').length;
          if (messageSize > 10 && !countElem.classList.contains(fontSizeCls)) {
            countElem.classList.add(fontSizeCls);
          } else if (messageSize < 11) {
            countElem.classList.remove(fontSizeCls);
          }
        }
      },
      /** 화면 최초 셋팅: 기간 > 기간선택(월) > 기간선택(년) */
      setPeriodMonthAndYear() {
        const months = new Array(12).fill().map((v, i) => {
          const month = i + 1;
          const value = month < 10 ? `0${month}` : month.toString();
          return { name: `${month}월`, value };
        });

        const years = [];
        const ersInitYear = this.$_getReportStartDt.substring(0, 4);
        let year = moment().format('YYYY');
        while (ersInitYear <= year) {
          years.push({ name: year + '년', value: year.toString() });
          year--;
        }
        this.init.periodMonth = months;
        this.init.periodYear = years;
      },
      /**
       * 화면 최초 셋팅
       * 기간 > 시간선택
       */
      setTimeList() {
        const times = new Array(25).fill().map((v, i) => {
          const time = i < 10 ? `0${i}` : i.toString();
          return { name: time, value: time };
        });

        const minutes = ['00', '15', '30', '45'].map(v => ({
          name: v,
          value: v,
        }));
        this.init.times = times;
        this.init.minutes = minutes;
      },
      /**
       * @title: 조회대상 리스트 조회
       * @filePath: data/solution/subPath/common/report-target.xml
       * @description: 위 파일 내에 name === objType 로 반환하는 element의 쿼리를 실행하여 조회대상 리스트 반환
       */
      async fetchReportTargetData(data, useLoading = true) {
        return this.CALL_REPORT_API({
          actionname: 'REPORT_TARGET_RESULT_LIST',
          data: { data },
          loading: useLoading,
        });
      },
      /**
       * @title: [대상] 조회대상 리스트 조회
       * @description
       * 보고서 XML 파일 내 "search - objType" 과 <br>
       * 일치하는 name을 조회하여 <br>
       * dept 1 ~ 5 까지 나누어 저장 (depth 별)
       * */
      async setTargetDataList() {
        const res = await this.getReportTargetList();
        if (!isSuccess(res)) {
          return;
        }

        // 계층구조(기본값)
        const isMultiSelectable = this.isMultiSelectable;
        const objectList = getResData(res);

        // dept 1~5 , v_dept 1~5 까지 key:value 생성
        const tempObj = {};
        [1, 2, 3, 4, 5].forEach(v => {
          tempObj[`dept${v}`] = [];
          tempObj[`v_dept${v}`] = [];
        });

        objectList.forEach(v => {
          const depth = v.DEPTH;
          tempObj[`dept${depth}`].push(v);
          if (depth === '1' || isMultiSelectable) {
            // 계층 구조일 시 1뎁스만 추가
            tempObj[`v_dept${depth}`].push(v);
          }
        });

        Object.keys(tempObj).forEach(v => (this.target[v] = cloneObj(tempObj[v])));
      },
      /**
       * @title: 조회대상 리스트 조회
       * @filePath: data/solution/subPath/common/report-target.xml
       * @description: 위 파일 내에 name === objType 로 반환하는 element의 쿼리를 실행하여 조회대상 리스트 반환
       */
      async getReportTargetList() {
        if (isEmpty(this.reportInfo)) return;
        const { objType: name, solution, subPath } = this.reportInfo;
        return await this.fetchReportTargetData({
          name: name,
          solution: solution,
          subPath: subPath,
          loginId: this.$store.getters.getLoginId,
        });
      },
      /**
       * 국세청용
       * stastFl: N 값 설정이 되어있을 때 사용하는 함수
       * @param targetDepth
       * @returns {Promise<void>}
       */
      async setLastDepthDataByApi(targetDepth) {
        // 마지막 뎁스 클릭 시 return
        const lastDepth = this.computedCheckBtnOptions.length;
        if (targetDepth.indexOf(lastDepth) > -1) return;
        const { chk_dept1, chk_dept2, chk_dept3, chk_dept4 } = this.target;
        const { Y, N } = this.status.stastFl;
        // 통계 대상
        let stastFl = 0; // 대상/비대상 체크
        if (Y && !N) stastFl = 1; // 대상 체크
        else if (!Y && N) stastFl = 2; // 비대상 체크
        this.setSearchParam('stastFl', stastFl);
        const payload = {
          ...this.PAGE_DATA.lastDepthApi,
          type: 'object_layout',
          stastFl: stastFl,
          obj: [...chk_dept1, ...chk_dept2, ...chk_dept3, ...chk_dept4],
        };

        const res = await this.fetchReportTargetData(payload);
        if (isSuccess(res)) {
          this.target[`v_dept${lastDepth}`] = getResData(res);
        }
      },
      /** 아이템, 속성을 이용해 속성 값 배열로 반환 */
      getValuesFromKey(items, key) {
        return items.map(item => item[key]);
      },
      /**
       * 트리박스 아이템 선택했을 때 발생하는 이벤트
       * 1. 네비게이션 만들기
       * 2. 선택한 자식 아이템 찾기
       * 3. 페이지데이터 내 lastDepthApi 존재할 시 실행
       * @param name
       * @param nextName
       * @param items
       * @param isClick 마우스 클릭(true)
       */
      setCheckedItems(name, nextName, items, isClick) {
        const values = this.getValuesFromKey(items, 'VALUE');
        const nodeIds = this.getValuesFromKey(items, 'NODE_ID') || values; // 기존 XML 에러 방어

        this.target[`chk_${name}`] = values;
        if (this.PAGE_DATA.checkBtnInfo.nonDisabled) {
          return;
        }

        if (nextName !== undefined) {
          //Next Depth 존재 시 Next Tree 셋팅
          const navigations = items.map(v => ({
            ...v,
            isNav: true,
            PARENT_ID: -1, // 해당 아이템 트리박스 최상단으로 놓기
            TEXT: v.NAMETREE ? v.NAMETREE.replace(/\|/g, '>') : v.TEXT,
          }));
          const nextDatas = this.target[nextName].filter(v => nodeIds.find(v2 => v2 === v.PARENT_ID));
          this.target[`v_${nextName}`] = [...navigations, ...nextDatas];
        }

        //lastDepthApi 실행
        if (isClick && this.PAGE_DATA.lastDepthApi !== undefined) {
          this.setLastDepthDataByApi(name);
        }
      },
      /** 1~5 순서로 트리박스 초기화, depth 까지 */
      clearCheckedItems(depth) {
        while (DEPTH_NUMBERS.includes(depth)) {
          this.target[`chk_dept${depth}`].length = 0;
          if (this.$refs.depthTreeBox[depth - 1] !== undefined) {
            this.$refs.depthTreeBox[depth - 1].setSelectedKeys([]);
          }
          depth++;
        }
      },
      /** 조회기간 옵션 클릭 이벤트 */
      onClickPeriodOptions() {
        //공휴일
        const holiYN = this.PAGE_DATA.daysChks.find(v => v.id === 'holi').checked ? 'Y' : 'N';
        const holiStr = holiYN === 'N' ? '' : ' 공휴일';
        this.setSearchParam('holi', holiYN);

        //요일
        const checkedDays = this.PAGE_DATA.daysChks.filter(v => v.id !== 'holi' && v.checked);
        const daysAlts = checkedDays.map(v => v.alt);
        const daysValues = checkedDays.map(v => v.value);
        this.setSearchParam('days', daysValues);

        //시간
        const stH = this.getSearchParam('startTime_H');
        const stM = this.getSearchParam('startTime_M');
        const etH = this.getSearchParam('endTime_H');
        const etM = this.getSearchParam('endTime_M');
        this.setSearchParam('endTime', `${etH}${etM}`);
        this.setSearchParam('startTime', `${stH}${stM}`);

        //Input 박스 출력
        this.status.periodResult = `${daysAlts.join(' ')}${holiStr} (${stH}:${stM} ~ ${etH}:${etM})`;
      },
      /**
       * 조회기간 [일, 월, 년, 기간 선택] 옵션 하이라이트 변경
       * @param type
       */
      changeColorPeriodBtn(type) {
        this.status.periodType = type; // 현재 선택된 버튼
        let isOff = true; // 해당 타입 찾지 못 했을 경우 첫번재꺼 선택되도록 하기 위한 값
        const buttonElems = this.$refs.periodSection.childNodes;
        buttonElems.forEach(elem => {
          const hasOn = elem.classList.contains('on');
          const sameType = elem.dataset.type === type;
          if (hasOn && sameType) {
            isOff = false;
          } else if (!hasOn && sameType) {
            isOff = false;
            elem.classList.add('on');
          } else {
            elem.classList.remove('on');
          }
        });

        if (isOff && buttonElems.length > 0) {
          // 첫번째 값 선택하도록
          this.onClickPeriodBtn(buttonElems.at(0).dataset.type);
        }
      },
      /**
       * [일 | 월 | 년 | 기간선택] 버튼 클릭 이벤트
       * RANGE 가 아니면 현재 시점으로 기간 셋팅
       */
      onClickPeriodBtn(type) {
        // ** 1. 선택한 버튼 컬러 변경 및 periodType 셋팅
        this.changeColorPeriodBtn(type);
        // ** 2. 일, 월, 년도, 기간 클릭 시 Input 변경
        const today = moment().format('YYYY-MM-DD');
        if (['MONTH', 'YEAR'].includes(type)) {
          const tDt = today.split('-');
          this.init.currentYear = tDt[0];
          this.init.currentMonth = tDt[1];
          const funcName = 'change' + ('MONTH' === type ? 'Month' : 'Year');
          this[funcName]();
        } else {
          if (this.status.periodType === 'DAY') {
            this.status.startDt = today;
            this.status.endDt = today;
          }
          this.changeDay();
        }
      },
      getSearchParam(key) {
        return this.formData.searchParams[key];
      },
      setSearchParam(key, value) {
        this.formData.searchParams[key] = value;
      },
      /**
       * depth 별 선택된 대상 가져오기
       * 사용할 때 'input'이라 명시한 건 customList에서 가지고 온다는 걸 의미함.
       */
      getSelectedTarget(depth) {
        const selectedBtnType = this.status.selectedBtnType;
        if ('input' === selectedBtnType) {
          return this.target.customList;
        } else {
          return this.target[`chk_dept${depth}`];
        }
      },
      validateMessageByParams() {
        // 기간
        const startDt = this.getSearchParam('startDt');
        const endDt = this.getSearchParam('endDt');
        const isDateError = startDt > endDt;
        if (isDateError) return this.PAGE_DATA.ERR_MSG_DATE;
        // 요일
        const checkedDays = this.PAGE_DATA.daysChks.filter(v => v.checked);
        if (checkedDays.length === 0) return this.PAGE_DATA.ERR_MSG_DAYS;
        // 시간
        const startTime = this.getSearchParam('startTime');
        const endTime = this.getSearchParam('endTime');
        const isTimeError = startTime > endTime;
        if (isTimeError) return this.PAGE_DATA.ERR_MSG_TIME;

        // 대상선택
        if ('input' === this.status.selectedBtnType && this.getSelectedTarget('input').length === 0) {
          return this.PAGE_DATA.ERR_MSG_CUSTOM_TARGET;
        } else {
          const depth = this.status.depth;
          if (this.getSelectedTarget(depth).length === 0) {
            return this.PAGE_DATA.ERR_MSG_TARGET;
          }
        }
        return '';
      },
      /**
       * 보고서 조회 전 최종적으로 모든 파라미터 업데이트
       * @param {Number} excelFlag 웹(0), 엑셀(1), 일괄(2)
       */
      updateAllReportParam(excelFlag) {
        const { targetQuery, solution, subPath } = this.reportInfo;
        const { configValue } = this.$_getSystemData('cti_system_type');
        this.setSearchParam('ctiType', configValue);
        this.setSearchParam('solution', solution);
        this.setSearchParam('subPath', subPath);
        this.setSearchParam('excel', excelFlag);

        //Set customObj(단일) || depthObj(뎁스별)
        this.setSearchParam('obj', []);
        this.setSearchParam('customObj', []);
        DEPTH_NUMBERS.forEach(v => this.setSearchParam(`obj${v}`, []));
        this.setSearchParam('objHeader', this.getObjHeader(this.status.selectedBtnType));

        this.setSearchParam('startDt', this.getSearchParam('startDt'));
        this.setSearchParam('endDt', this.getSearchParam('endDt'));

        if ('input' === this.status.selectedBtnType) {
          this.setSearchParam('customObj', this.getSelectedTarget('input'));
        } else {
          //Set obj 1~5
          this.setSearchParam('obj', this.getSelectedTarget(this.status.depth));
          if (this.isMultiSelectable) {
            DEPTH_NUMBERS.forEach(v => this.setSearchParam(`obj${v}`, this.getSelectedTarget(v)));
          }

          const { maxSelectedCnt } = this.PAGE_DATA.checkBtnInfo;
          if (maxSelectedCnt !== undefined && maxSelectedCnt < this.getSearchParam('obj').length) {
            return this.$_Msg(this.PAGE_DATA.ERR_MSG_MAX_SELECTED_TARGET.replace('{n}', maxSelectedCnt));
          }
        }

        const depth = this.getSearchParamDepth();
        this.setSearchParam('depth', depth);
        this.setSearchParam('objKey', this.findObjKey(depth));
        this.setSearchParam('targetQuery', targetQuery);
        this.setSearchParam('loginId', this.$store.getters.getLoginId);
        this.setSearchParam('objType', this.reportInfo.objType);

        // optionItems 추가
        this.setCheckOptionItems();
        // inputOptions 추가
        this.setInputOptionParams();

        this.updateCurrentDateParam();
      },
      /** ERS - T 테이블 체크를 위한 파라미터 추가 */
      updateCurrentDateParam() {
        const endYM = this.getSearchParam('endDt').substr(0, 6);
        const hasCurrentYM = moment().format('YYYYMM') === endYM ? 'Y' : 'N';
        this.setSearchParam('endYM', endYM);
        this.setSearchParam('hasCurrentYM', hasCurrentYM);
      },
      /**
       * @description 조회대상(체크, 개별)에 대한 헤더 정보 조회
       * @param {String} type: check || input
       * */
      getObjHeader(type) {
        try {
          let options = this.computedCheckBtnOptions;
          if ('input' === type) {
            options = this.PAGE_DATA.inputBtnInfo.options;
          }

          return options.map(v => v.name);
        } catch (err) {
          return [];
        }
      },
      /**
       * 보고서 조회 기능
       * @param {Number} excelFlag 웹(0), 엑셀(1), 일괄(2)
       * @param {Boolean} isWin 새창 여부
       */
      onSearchReport(excelFlag, isWin = false) {
        //Validation
        const errMessage = this.validateMessageByParams();
        if (errMessage.trim()) return this.$_Msg(errMessage);

        //Set Params
        this.updateAllReportParam(excelFlag);

        //조회 [popOn.checked: 새창 / else: 현재 브라우저]
        if (excelFlag === 0 && isWin) {
          this.newWindow.params = this.getReportSearchParams();
          this.status.windowPopup = isWin;
        } else {
          this.toggleHiddenSection(null, '');
          this.$emit('onSearchClick', this.formData.searchParams, this.getHiddenColumns());
        }
      },
      /**
       * 보고서 조회 시 파라미터로 보낼 depth 별
       * 숨겨야할 컬럼을 반환
       * status.depth가 아닌 searchParams.depth를 기준으로 반환
       */
      getHiddenColumns() {
        const depth = this.getSearchParamDepth();
        const selectedCheckBtn = this.computedCheckBtnOptions[depth - 1];
        if (selectedCheckBtn && selectedCheckBtn.hiddenColumns) {
          return selectedCheckBtn.hiddenColumns;
        }
        return [];
      },
      getSearchParamDepth() {
        const options = this.computedCheckBtnOptions;
        let depth = options.length;
        // 마지막 뎁스
        if ((this.getChkDepthTarget && this.getChkDepthTarget()) || this.status.selectedBtnType === 'input') {
          return depth;
        }
        return this.status.depth;
      },
      /***** [START]검색조건관리 *****/
      openConditionModal(openOrClose) {
        this.status.openConditionModal = openOrClose;
      },
      /** @description 선택한 옵션(pageData -> optionItems) 값 업데이트 */
      updateCheckOptionItem($event, index) {
        this.PAGE_DATA.optionItems[index].checked = $event.value;
      },
      /** @description 옵션(pageData -> optionItems) 값 가지고 오는 기능 */
      getCheckOptionItems() {
        return this.PAGE_DATA.optionItems.map((v, i) => {
          return { id: `option${i + 1}`, checked: v.checked };
        });
      },
      /** @description 보고서 조회 파라미터 내 셋팅 */
      setCheckOptionItems() {
        const checkOptionItems = this.getCheckOptionItems();
        if (checkOptionItems.length > 0) {
          checkOptionItems.forEach(v => vm.setSearchParam(v.id, v.checked));
        }
      },
      /** @description 보고서 조회 파라미터 내 셋팅 */
      setInputOptionParams() {
        if (isEmpty(this.checkBtnInputOptions)) {
          return;
        }
        // 보고서 조회 시 필요한 파라미터 셋팅
        this.checkBtnInputOptions.forEach(v => vm.setSearchParam(v.id, v.value));
      },
      /** @description 조회그룹 -> Input 옵션 값 변경 함수 */
      handleChangeInputOption($event, index) {
        this.checkBtnInputOptions[index].value = $event.value;
      },
      /**
       * 현재 셋팅 된 파라미터들 값을 search / target / status 로 분리해서 리턴
       * 검색조건관리 및 새로운창 파라미터 셋팅 시 사용
       * 해당 함수를 사용해야 컴포넌트에 값 셋팅이 가능(트리뎁스 포함)
       */
      getReportSearchParams() {
        const _that = this;
        // Search Param
        const searchParamKeys = [
          //날짜
          'startDt',
          'endDt',
          //요일 및 공휴일
          'days',
          'holi',
          //시간
          'startTime_H',
          'startTime_M',
          'endTime_H',
          'endTime_M',
          //레포트유형
          'report_type',
        ];

        const searchParamData = searchParamKeys.reduce((acc, key) => {
          acc[key] = _that.formData.searchParams[key];
          return acc;
        }, {});

        // Status
        const statusData = {
          depth: _that.status.depth,
          selectedBtnType: _that.status.selectedBtnType,
        };

        // 커스텁(단일) 또는 뎁스 별 선택한 데이터
        let targetData = null;
        if ('input' === this.status.selectedBtnType) {
          targetData = { customList: this.target.customList };
        } else {
          targetData = DEPTH_NUMBERS.reduce((acc, number) => {
            const key = `chk_dept${number}`;
            const datasByDept = _that.target[key];
            if (datasByDept.length > 0) acc[key] = datasByDept;
            return acc;
          }, {});
        }

        return {
          search: searchParamData,
          target: targetData,
          status: statusData,
          filter: this.getSelectedReportFilterKeys(),
        };
      },
      /** description: 검색조건관리 리스트 클릭 시 화면 및 파라미터 셋팅 */
      changeSectionBySearch(search) {
        // 1. 요일 및 공휴일
        const { days, holi } = search;
        this.PAGE_DATA.daysChks.forEach(v => {
          v.checked = days.indexOf(v.value) !== -1;
        });

        this.PAGE_DATA.daysChks.at(-1).checked = holi === 'Y';

        // 2. 기간 및 시간
        const _that = this;
        const alreadyUsedKeys = ['days', 'holi']; // 이미 셋팅해서 제외할 Key
        Object.entries(search).forEach(([key, value]) => {
          if (!alreadyUsedKeys.includes(key)) {
            _that.formData.searchParams[key] = value;
          }
        });

        const startDt = this.getSearchParam('startDt');
        const endDt = this.getSearchParam('endDt');
        const sDt = startDt.split('-');
        const eDt = endDt.split('-');
        this.init.currentYear = eDt[0];
        this.init.currentMonth = eDt[1];
        this.status.startDt = startDt;
        this.status.endDt = endDt;
        if (startDt === endDt) {
          this.changeColorPeriodBtn('DAY');
        } else {
          const sameY = sDt[0] === eDt[0];
          const sameM = sDt[1] === eDt[1];
          const lastDd = moment(endDt).endOf('month').format('DD');
          if (sameY && startDt.indexOf('01-01') > -1 && endDt.indexOf('12-31') > -1) {
            this.changeColorPeriodBtn('YEAR');
          } else if (sameY && sameM && sDt[2] === '01' && eDt[2] === lastDd) {
            this.changeColorPeriodBtn('MONTH');
          } else {
            this.changeColorPeriodBtn('RANGE');
          }
        }
      },
      /** @description 대상 버튼(check or input) 및 조회기준 radio button 선택 */
      changeSectionByStatus(status) {
        const { depth, selectedBtnType } = status;
        this.status.selectedBtnType = selectedBtnType; // 조회대상 버튼 (check or input) 선택

        // 조회기준 라디어버튼이 있고, Depth가 여러 개 있는 트리리스트 컴포넌트인 경우
        const selectedRadio = this.computedCheckBtnOptions.find(v => v.depth === depth);
        if (selectedRadio) {
          this.$refs.targetGroupRadio.instance.option('value', depth);
        }
      },
      /** @description 조회그룹 변경 기능 */
      changeSectionByTarget(target) {
        // 트리 박스 내 선택한 오브젝트 리스트 셋팅
        if (isEmpty(target)) {
          return;
        }

        // 개별 아이템 선택 시
        if (Object.hasOwn(target, 'customList')) {
          vm.target.customList = target.customList;
          return;
        }

        Object.values(target).forEach((values, i) => {
          const treeBoxByDepth = vm.$refs.depthTreeBox[i];
          if (!isEmpty(treeBoxByDepth)) {
            treeBoxByDepth.setSelectedKeys(this.convertNodeIds(i + 1, values));
          }
        });
      },
      /** 조회그룹(XML) 데이터 NODE_ID 포맷(DEPTH-VALUE)으로 변경 */
      convertNodeIds(depth, values) {
        return values.map(v => `${depth}-${v}`);
      },
      /** @description 검색 조건 셋팅 */
      handleSetSearchCondition(dxEvent) {
        if (dxEvent.columnIndex !== 0) return;
        const { title, param } = dxEvent.data;
        if ([title, param].includes(undefined)) {
          this.$_Msg('해당 조건이 정상적으로 적용되지 않았습니다.');
        }

        const jsonData = JSON.parse(param);
        const { search, target, status } = jsonData;
        this.changeSectionBySearch(search);
        this.changeSectionByStatus(status);
        this.changeSectionByTarget(target);

        // 셋팅 후 히든섹션 닫기
        this.toggleHiddenSection(null, '');

        // 리스트 박스 닫고 알림 UP
        this.$refs.popoverSearchCondition.toggleConditionPopover(false);
        this.$_Toast(`${title} 조건으로 설정되었습니다.`);
      },
      toggleHiddenSection(e, targetID, btnRef) {
        let isOn = true;
        let btnElem = null;
        // ** 1. 클릭한 버튼 컬러 변경 및 대상[그룹, 내선번호 등]일 시 값 세팅
        if (e !== null) {
          // 1. 버튼 on/off 체크
          const target = e.target;
          btnElem = target;
          if ('INPUT' === target.tagName) {
            btnElem = this.$refs[btnRef];
          } else if ('SPAN' === target.tagName) {
            btnElem = target.parentNode;
          }
          isOn = btnElem.classList.contains('on');

          // 2. 대상 버튼 클릭 시 => selectedBtnType, objKey 셋팅
          const { type } = btnElem.dataset;
          if (type !== undefined) {
            this.status.selectedBtnType = type; // Change Button Type
          }
        }

        // ** 2. 버튼 컬러 변경
        const _this = this;
        const targetBtnRefs = _this.PAGE_DATA.targetBtns.map(v => `${v.type}LayerBtn`);
        const showedBtnList = [...targetBtnRefs, 'dateLayerBtn'];
        showedBtnList.forEach(btnRef => {
          const refBtn = _this.$refs[btnRef][0] || _this.$refs[btnRef];
          const child = refBtn.lastChild;
          const onOff = !isOn && btnElem === refBtn ? 'on' : 'off';
          refBtn.classList = _this.styles[`${onOff}LayerBtn`];
          child.classList = _this.styles[`${onOff}UpDown`];
        });

        // ** 3. 레이어 드롭다운 열고 닫는 로직
        const HIDDEN_CLASS = 'hidden';
        showedBtnList.forEach(v => {
          const layerId = v.replace('LayerBtn', 'HiddenLayer');
          const layerRef = _this.$refs[layerId];
          if (targetID === layerId) {
            layerRef.classList.toggle(HIDDEN_CLASS);
            if (v === 'checkLayerBtn') _this.refreshTreeBoxList();
          } else if (layerRef.classList.contains(HIDDEN_CLASS) === false) {
            layerRef.classList.add(HIDDEN_CLASS);
          }
        });
      },
      /** 컴포넌트 내 블라인드 처리가 없어지지 않아 Refresh 시키는 로직 추가 */
      refreshTreeBoxList() {
        this.$refs.depthTreeBox.forEach(v => {
          v.refreshTreeList();
        });
      },
      handleChangeReportType(e) {
        this.setSearchParam('report_type', e.value);
      },
      /** 날짜선택 [일] 버튼*/
      changeDay() {
        const isDay = 'DAY' === this.status.periodType;
        let startDt = this.status.startDt;
        let endDt = isDay ? startDt : this.status.endDt;
        startDt = moment(startDt).format('YYYYMMDD');
        endDt = moment(endDt).format('YYYYMMDD');
        this.setDatesParam(startDt, endDt);
      },
      /** 날짜선택 [월] 버튼*/
      changeMonth() {
        const periodYear = this.init.currentYear;
        const periodMonth = this.init.currentMonth;
        const YYYYMM = `${periodYear}${periodMonth}`;
        const startDt = `${YYYYMM}01`;
        const endDt = moment(startDt).endOf('month').format('YYYYMMDD');
        this.setDatesParam(startDt, endDt);
      },
      /** 날짜선택 [년] 버튼*/
      changeYear() {
        const periodYear = this.init.currentYear;
        const startDt = `${periodYear}0101`;
        const endDt = `${periodYear}1231`;
        this.setDatesParam(startDt, endDt);
      },
      /** 날짜선택 [기간선택] 버튼*/
      onChangePeriod(e, type) {
        const { periodType } = this.status;
        if (['DAY', 'RANGE'].includes(periodType)) {
          this.changeDay();
        } else if ('MONTH' === periodType && ['MONTH', 'YEAR'].includes(type)) {
          this.changeMonth();
        } else if ('YEAR' === periodType) {
          this.changeYear();
        }
      },
      /** 날짜 파라미터(startDt, endDt) 셋팅 */
      setDatesParam(startDt, endDt) {
        this.setSearchParam('startDt', startDt);
        this.setSearchParam('endDt', endDt);
      },
      /** Query 에 사용될 objKey 값 조회 By Depth */
      findObjKey(depth) {
        const options = this.PAGE_DATA.checkBtnInfo?.options;
        return options.find(v => v.depth === depth)?.objKey;
      },
      /**
       * 조회대상: 조회기준 라디오 버튼 값 변경 시 실행되는 기능
       * 1. Query 에 사용될 objKey 값 셋팅
       * 2. 해당 뎁스보다 높은 뎁스의 트리박스 비활성화
       * */
      changeRadioInGroupSection(e) {
        const depth = e?.value ?? 1;
        this.status.depth = depth;
        this.disabledTreeBox(depth);
      },
      /** 현재 선택한 뎁스보다 낮은 경우 트리박스 비활성화 */
      disabledTreeBox(depth) {
        const _that = this;
        DEPTH_NUMBERS.forEach(v => {
          _that.target[`disabled${v}`] = !(v <= depth);
        });
      },
      /** input타입(개별) 버튼 item(내선번호, 발신번호 등) 값 입력 후 추가하여 그리드 내 셋팅하는 함수 */
      onClickAddCustomItems() {
        const data = {};
        this.PAGE_DATA.inputBtnInfo.options.forEach((v, i) => {
          const name = `item${i + 1}`;
          data[name] = vm.target[`v_${name}`];
        });

        //VALID
        if (Object.values(data).filter(v => v.trim() !== '').length === 0) {
          return this.$_Msg('값을 입력해주시기 바랍니다.');
        }

        //CLEAR
        this.PAGE_DATA.inputBtnInfo.options.forEach((v, i) => {
          const name = `item${i + 1}`;
          vm.target[`v_${name}`] = '';
        });

        //ADD
        this.target.customList.push(data);
      },
      /** UI 옵션 셋팅 by [ 로컬스토리지, 검색조건(설정값) ] */
      updateSearchBoxBySearchParam(params, isSetStorage = true) {
        const { search, target, status } = params;
        this.changeSectionBySearch(search);
        this.changeSectionByStatus(status);
        if (isSetStorage) {
          this.changeSectionByTarget(target);
        }
      },
      /** [새창보기] 를 통한 스타일 변경, 옵션 셋팅, 조회 실행  */
      setNewWindowStyles() {
        // 새창보기 버튼 제거
        this.status.showWindowBtn = false;
        // 패딩 제거
        $('#wrap').css('padding', 0).removeClass('left-show');
        $('#wrap > header').remove();
        $('#wrap > aside').remove();
        $('#wrap > main > .content').css('margin-left', 0);
      },
      async mountedSetNewWindow(popupItems) {
        this.setNewWindowStyles();
        if ([null, undefined].includes(popupItems)) {
          return;
        }

        //컴포넌트 생성 전 셋팅 막기 위해 [nextTick] 사용
        const { options, params } = JSON.parse(popupItems);
        this.$nextTick(() => {
          this.updateSearchBoxBySearchParam(options);
        });

        // FormData 셋팅
        this.formData.searchParams = params;
        this.$emit('onSearchClick', this.formData.searchParams);
      },
      mountedSetOptionByLocalStorage() {
        if (!Object.hasOwn(this.reportInfo, 'reportId')) {
          return;
        }

        const reportId = this.reportInfo?.reportId;
        const lsData = localStorage.getItem(`ECSS_RPT_${reportId}`);
        if (lsData) {
          this.$nextTick(() => {
            this.updateSearchBoxBySearchParam(JSON.parse(lsData), this.PAGE_DATA.useStorageData);
          });
          return;
        }

        this.$nextTick(() => {
          this.changeColorPeriodBtn('DAY');
        });
      },
      initPageData() {
        return {
          /***** components setting start ****/
          checkBtnInfo: null,
          inputBtnInfo: null,
          optionItems: [],
          //OPTIONS
          useStorageData: true, //로컬스토리지에 저장되어 있는 조회그룹 사용할지 여부
          showReportType: true, //보고서 타입 보이기 여부
          multiDownload: [], // 보고서 일괄 다운로드 (자신 ID도 적어야 함)
          /** MasterQuery
           * {
           *     name: sql name 속성 값
           *     type: "master-query"
           *     objKey: 파라미터 objKey 값
           * }
           */
          lastDepthApi: undefined,
          targetBtns: ReportManager.getTargetBtns(),
          daysChks: ReportManager.getDayOfTheWeek(),
          /***** components setting end by report.js ****/

          //STYLE
          dxStyled: 'outlined',
          //ERROR_MESSAGE
          ERR_MSG_DATE: '선택한 날짜를 확인해주시기 바랍니다.',
          ERR_MSG_DAYS: '선택한 요일을 확인해주시기 바랍니다.',
          ERR_MSG_TIME: '선택한 시간을 확인해주시기 바랍니다.',
          ERR_MSG_TARGET: '대상을 하나 이상 선택해주시기 바랍니다.',
          ERR_MSG_CUSTOM_TARGET: '대상을 추가해주시기 바랍니다.',
          ERR_MSG_MAX_SELECTED_TARGET: '대상은 최대 {n}개까지 선택 가능합니다.',
        };
      },
      async setPageData() {
        const { solution, subPath, reportId } = this.reportInfo;
        try {
          let pageData = '';
          if (Object.hasOwn(this.reportInfo, 'pageData')) {
            pageData = this.reportInfo.pageData;
          } else if (Object.hasOwn(this.reportInfo, 'pagedata')) {
            //TODO: 분리 작업 전 'pagedata' 로 받아오고 있었으며, 추후 삭제 예정
            pageData = this.reportInfo.pagedata;
          }
          const pageDataFunc = new Function(`return ${pageData.trim()}`).bind(this)();
          this.PAGE_DATA = { ...this.initPageData(), ...pageDataFunc };
          // await Object.entries(pageDataFunc).forEach(([key, value]) => (vm.PAGE_DATA[key] = value));
        } catch (e) {
          const xmlPath = `${solution}/${subPath ? subPath + '/' : ''}${reportId}.xml`;
          this.$_Msg(`보고서 XML 파일(${xmlPath}) <pageData> 값을 확인해주시기 바랍니다.<br>${e}`);
        }
      },
      async toggleXmlModal(toggleBool = true) {
        if (toggleBool) {
          this.modal.xmlHistories = await this.fetchXmlHistoryByReportId(this.reportInfo?.reportId);
        }
        //toggleBool의 경우 boolean이 아닐 수도 있어서 아래와 같이 처리
        this.modal.isOpenXmlModal = toggleBool ? true : false;
      },
      async fetchXmlHistoryByReportId(reportId) {
        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_XML_HISTORY_LIST',
          path: `/${reportId}`,
          loading: true,
        });

        if (isSuccess(res)) {
          return getResData(res);
        }
        return [];
      },
      async saveXmlModal() {
        const { cmEditor: reportXml, description } = this.$refs.xmlEditor;
        const validatePageDataRet = this.$refs.xmlEditor.validatePageData();
        if (validatePageDataRet.isError) {
          this.$_Msg(validatePageDataRet.msg);
          return false;
        }

        if (description.length > 100) {
          return this.$_Msg('설명은 100자 이내로 작성해주시기 바랍니다.');
        }

        //XML, 설명, 레포트 ID
        const params = {
          xmlData: reportXml,
          reportId: this.reportInfo?.reportId,
          description: description,
          useSqlPass: true,
        };

        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_XML_UPDATE',
          data: params,
          loading: true,
          useErrorPopup: false,
        });

        if (isSuccess(res)) {
          await this.successSaveModal();
          return;
        }

        this.$_Msg(res.data.header.resMsg);
      },
      /**
       * XML 편집 모달 저장 성공 시 메시지 팝업 이후 화면 reload
       * @returns {Promise<void>}
       */
      async successSaveModal() {
        await this.$_Msg('XML 파일이 편집되었습니다.');
        location.reload();
      },
      setTargetBtnInfo() {
        // set checkBtnInfo
        this.PAGE_DATA.checkBtnInfo = this.PAGE_DATA.targetBtns.find(v => v.type === 'check');
        if (this.PAGE_DATA.checkBtnInfo) {
          const maxDepth = this.computedCheckBtnOptions.length - 1;
          this.PAGE_DATA.checkBtnInfo.options = this.computedCheckBtnOptions.map((v, vi) => {
            return { ...v, hasNext: v.hasNext !== false && vi < maxDepth };
          });
        }
        // set inputBtnInfo
        this.PAGE_DATA.inputBtnInfo = this.PAGE_DATA.targetBtns.find(v => v.type === 'input');
      },
      setPageLayout() {
        if (!this.reportInfo) return;
        const {
          // 기간
          searchTypeDay,
          searchTypeMonth,
          searchTypeYear,
          searchTypeFromto,
          // 보고서유형
          reportTypeDay,
          reportTypeMonth,
          reportTypeHour,
          reportTypeMin30,
          reportTypeMin15,
          reportTypeDayhour,
        } = this.reportInfo;

        //기간
        const periodDatas = {
          DAY: searchTypeDay,
          MONTH: searchTypeMonth,
          YEAR: searchTypeYear,
          RANGE: searchTypeFromto,
        };

        const dateBtnOptions = [
          { name: '일', type: 'DAY' },
          { name: '월', type: 'MONTH' },
          { name: '년', type: 'YEAR' },
          { name: '기간선택', type: 'RANGE' },
        ];

        this.setTimeList();
        this.setPeriodMonthAndYear();
        this.init.periodBtns = dateBtnOptions.filter(btn => periodDatas[btn.type]);

        //유형
        const reportTypeDatas = {
          daily: reportTypeDay,
          monthly: reportTypeMonth,
          hour: reportTypeHour,
          i15: reportTypeMin30,
          i30: reportTypeMin15,
          daytimes: reportTypeDayhour,
        };

        this.init.reportTypeOpts = this.init.v_reportTypeOpts.filter(opt => isTrue(reportTypeDatas[opt.value]));
        this.setPageData();
        this.setTargetBtnInfo();

        this.status.disabledDays = !isTrue(this.reportInfo.conditionWeeks);
        this.status.disabledTimes = !isTrue(this.reportInfo.conditionTimes);
      },
      initMounted() {
        this.changeRadioInGroupSection();
        this.onClickPeriodOptions();
        this.setTargetDataList();

        const newWindowOptions = sessionStorage.getItem(window.name);
        if (newWindowOptions) {
          // ** 새창보기 (sessionStorage Name 값이 Null이 아닐 시 동작)
          this.mountedSetNewWindow(newWindowOptions);
        } else {
          //이전 조회 이력이 있다면 조회했던 옵션으로 셋팅한다.
          //조회했던 옵션은 로컬스토리지에 "RPT_MID_${reportId}"로 저장되어있다.
          this.mountedSetOptionByLocalStorage();
        }

        // set XML Modal Info
        this.mountedSetXmlModalInfo();
      },
      mountedSetXmlModalInfo() {
        // set XML Modal Info
        if (this.reportInfo) {
          const { menuNm, reportId } = this.reportInfo;
          this.modal.editorOption.reportNm = menuNm;
          this.modal.editorOption.reportId = reportId;
        }
      },
    },
    created() {
      vm = this;
      this.setPageLayout();
    },
    async mounted() {
      this.initMounted();
    },
    destroyed() {},
  };
</script>

<style scoped>
  .container {
    background-color: #fff;
    margin-bottom: 0px;
  }

  .search-top-wrap > div {
    display: inline-block;
  }

  .search-top-wrap > div:not(:last-child) {
    margin-right: 5px;
  }

  .search-top-wrap > div:first-child {
    margin-right: 20px;
  }

  .container-top-wrap .search-bottom-wrap {
    margin-top: 30px;
  }

  .search-bottom-wrap .search-bottom-top-wrap {
    font-size: 0.95em;
  }

  .search-bottom-wrap .search-bottom-bottom-wrap {
    margin-top: 5px;
  }

  .search-bottom-bottom-wrap > div {
    display: inline-block;
  }

  .search-bottom-bottom-wrap .search-bottom-left-wrap > div {
    display: inline-block;
  }

  .search-bottom-bottom-wrap .search-bottom-left-wrap > div:not(:first-child) {
    margin-left: 5px;
  }

  .search-bottom-bottom-wrap .search-bottom-right-wrap {
    position: absolute;
    right: 0;
  }

  .mr-0 {
    margin-right: 0 !important;
  }

  .mr-10 {
    margin-right: 10px !important;
  }

  .mr-15 {
    margin-right: 15px !important;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .mr-20 {
    margin-right: 20px !important;
  }

  .mt-20 {
    margin-top: 20px !important;
  }

  .w150 {
    width: 150px;
  }

  .pointer {
    cursor: pointer;
  }

  .p-20 {
    padding: 20px;
  }

  .border-top-1px {
    border-top: 1px solid #ebebeb;
  }

  .flex {
    display: flex;
  }

  .dx-outline {
    border: 1px solid #dcdcdc !important;
    border-radius: 4px;
    background-color: #fbfbfb !important;
    color: #aaaaaa;
  }

  .top2 {
    position: relative;
    top: 2px;
  }

  .hidden {
    display: none;
  }
</style>
<style>
  .dx-tag-content {
    padding: 3px 30px 3px 8px !important;
  }

  #conditionGrid .dx-state-hover > .condition-row:hover {
    color: #6ab0b2 !important;
    cursor: pointer !important;
  }
</style>
