<template>
  <div class="page-sub-box">
    <div class="msg-error">
      <strong>{{ mainTitle }}</strong>
      <p>{{ subTitle }}</p>
    </div>
  </div>
</template>
<script>
  import store from '@/store';
  import enums from '@/configs/enums';
  import {isEmpty} from "@/plugins/common-lib";

  export default {
    data() {
      return {
        mainTitle: '메인이 설정되어 있지 않은 사용자입니다.',
        subTitle: '메인 설정은 "메인 설정" 메뉴를 이용하거나, 관리자에게 문의하세요.',
      };
    },
    methods: {
      goMain() {
        this.$router.push({ path: '/blank' });
      },
      setErrorMessage(userMainPage) {
        const pageType = userMainPage?.mainType;
        const pageId = userMainPage?.value;

        // FIXME : `/report/dashboard/list` 은 메인대시보드 페이지를 찾기 위한 하드코딩
        const dashBoardPageData = store.getters.getMenuList.find(menu => menu.pageUrl === '/report/dashboard/list');

        if (pageType === enums.common.mainPageType.DASHBOARD.value && isEmpty(dashBoardPageData)) {
          this.mainTitle = '메인페이지에 권한 설정되어 있지 않은 사용자 입니다.';
          this.subTitle = '메인페이지 권한 설정을 다시 확인해주세요.';
          return true;
        }

        const menuPageData = store.getters.getMenuList.find(menu => menu.id === pageId);

        if (pageType === enums.common.mainPageType.MENU.value && isEmpty(menuPageData)) {
          this.mainTitle = '메인페이지에 권한 설정되어 있지 않은 사용자 입니다.';
          this.subTitle = '메인페이지 권한 설정을 다시 확인해주세요.';
          return true;
        }
        return false;
      },
    },
    mounted() {
      const userMainPage = store.getters.getUserMainPage;
      const alongPageType = this.setErrorMessage(userMainPage);

      // 제대로 메인 설정이 되어 있는경우 다시 메인으로 이동
      if (!alongPageType) {
        this.goMain();
      }
    },
  };
</script>
<style scoped>
  .msg-error {
    padding-top: 300px;
    text-align: center;
  }
  .msg-error strong {
    font-size: 3em;
  }
  .msg-error p {
    font-size: 1.7em;
  }
</style>