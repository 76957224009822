import commonEnum from "@/configs/enums/common";
import { DxButton } from "devextreme-vue/button";

export const userColumns = () => {
    return [
        {
            key: 'USER',
            multiHeaderNm: '사용자',
            columns: [
                {
                    caption: 'selectUser',
                    dataField: 'selectUser',
                    width: 80,
                    height: 40,
                    alignment: 'center',
                    visible: true,
                    allowEditing: false,
                    sortOrder: 'none',
                    allowHeaderFiltering: false,
                    allowGrouping: false,
                    headerCellTemplate: (container) => {
                        const icon = document.createElement('i');
                        icon.className = 'dx-icon dx-icon-user';
                        container.append(icon);
                    },
                },
                {
                    caption: '사번',
                    dataField: 'userNo',
                    width: 110,
                    height: 40,
                    alignment: 'center',
                    visible: true,
                    allowEditing: false,
                    sortOrder: 'none',
                    allowHeaderFiltering: false,
                    allowGrouping: false,
                    requiredRule: {
                        message: '필수 항목입니다.'
                    },
                },
                {
                    caption: '이름',
                    dataField: 'userNm',
                    width: 110,
                    height: 40,
                    alignment: 'center',
                    visible: true,
                    allowEditing: false,
                    sortOrder: 'none',
                    allowHeaderFiltering: false,
                    allowGrouping: false,
                    requiredRule: {
                        message: '필수 항목입니다.'
                    },
                },
                {
                    caption: '부서 ID',
                    dataField: 'deptId',
                    height: 40,
                    alignment: 'center',
                    visible: false,
                    allowEditing: false,
                    sortOrder: 'none',
                    allowHeaderFiltering: false,
                    allowGrouping: false,
                    requiredRule: {
                        message: '필수 항목입니다.'
                    },
                },
                {
                    caption: '부서명',
                    dataField: 'deptNm',
                    width: 110,
                    height: 40,
                    alignment: 'center',
                    visible: true,
                    allowEditing: false,
                    sortOrder: 'none',
                    allowHeaderFiltering: false,
                    allowGrouping: false,
                    requiredRule: {
                        message: '필수 항목입니다.'
                    },
                },
            ]
        },
    ]
}
export const deptColumns = () => {
    return [
        {
            key: 'DEPT',
            multiHeaderNm: '부서',
            columns: [
                {
                    caption: '부서 ID',
                    dataField: 'deptId',
                    height: 40,
                    alignment: 'center',
                    visible: false,
                    allowEditing: false,
                    sortOrder: 'none',
                    allowHeaderFiltering: false,
                    allowGrouping: false,
                    requiredRule: {
                        message: '필수 항목입니다.'
                    },
                },
                {
                    caption: '부서명',
                    dataField: 'deptNm',
                    width: 110,
                    height: 40,
                    alignment: 'center',
                    visible: true,
                    allowEditing: false,
                    sortOrder: 'none',
                    allowHeaderFiltering: false,
                    allowGrouping: false,
                    requiredRule: {
                        message: '필수 항목입니다.'
                    },
                },
            ]
        }

    ]
}
export const defaultColumns = (vm) => {
    return [
        {
            caption: '메모',
            dataField: 'comment',
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
        },
        {
            caption: '모델',
            dataField: 'phoneModel',
            width: 130,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            lookup: {
                dataSource: vm.$_getCode('euc_phone_model'),
                displayExpr: 'codeNm',
                valueExpr: 'codeValue'
            },
        },
        {
            caption: '녹취',
            dataField: 'recordFl',
            width: 110,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            lookup: {
                dataSource: commonEnum.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value'
            },
        },
        {
            caption: '시작일',
            dataField: 'startDt',
            dataType: 'date',
            format: 'yyyy-MM-dd',
            width: 130,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
        },
        {
            caption: '종료일',
            dataField: 'endDt',
            dataType: 'date',
            format: 'yyyy-MM-dd',
            width: 130,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            editorOptions: {
                showClearButton: true,
            },
        },
        {
            caption: '연락번호',
            dataField: 'contactNumber',
            width: 120,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
        },
        {
            caption: '근무지',
            dataField: 'phoneLocation',
            width: 120,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
        },
        {
            caption: '내선번호',
            dataField: 'phoneNumber',
            width: 110,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
        },
        {
            caption: '착신번호',
            dataField: 'forwardNumber',
            width: 110,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
        },
    ]
}

export const workerColumns = (vm) => {
    return {
        key: 'WORKER',
        multiHeaderNm: '처리내용',
        columns: [
            {
                caption: '자동화 설정',
                i18n: 'UC.WORD.AUTOMATION_SETTING',
                dataField: '',
                width: 120,
                height: 40,
                alignment: 'center',
                visible: true,
                allowEditing: false,
                sortOrder: 'none',
                allowHeaderFiltering: false,
                allowGrouping: false,
                cellTemplate: async (container, options) => {
                    const useAutomation = vm.typeDetailList.find((detail) => detail.id === options.data.detailId)?.autoFl === 'Y';
                    if(useAutomation) {
                        options.title = '자동화 설정';
                        const button = new DxButton({
                            propsData: {
                                text: '열기',
                                elementAttr: { class: 'btn_XS white light_filled' },
                                width: 60,
                                height: 30,
                                value: options.data.id,
                                onClick: () => {
                                    vm.$emit('showAutomationModal', options);
                                },
                            },
                        });
                        button.$mount();
                        container.append(button.$el);
                    }
                },
            },
            {
                caption: '결과',
                dataField: 'status',
                minWidth: 150,
                height: 40,
                alignment: 'center',
                visible: true,
                allowEditing: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
                allowGrouping: false,
                lookup: {
                    dataSource: commonEnum.stringCompleteFlag.values,
                    displayExpr: 'label',
                    valueExpr: 'value'
                }
            },
            {
                caption: '내용',
                dataField: 'completeComment',
                minWidth: 150,
                height: 40,
                alignment: 'center',
                visible: true,
                allowEditing: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
                allowGrouping: false,
            },
        ]
    }
}

export default {
    userColumns,
    deptColumns,
    defaultColumns,
    workerColumns
}