<!--
  PACKAGE_NAME : src\pages\esp\user\password-change.vue
  FILE_NAME : password-change
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 패스워드 변경
-->
<template>
  <div ref="amorWrapper" class="amor_wrapper">
    <div id="viewport" class="viewport">
      <div class="visual-left">
        <img src="../../../assets/images/login/mask_group.png" class="visual-bg" />
        <div class="visual-text" :style="{ top: theme.topTextLoca, left: theme.leftTextLoca }">
          <span class="log_maintxt" :style="{ color: theme.topFontColor, fontSize: theme.topFontSize }">{{ theme.topText }}</span
          ><br />
          <span class="log_subtxt" :style="{ color: theme.btmFontColor, fontSize: theme.btmFontSize }">{{ theme.btmText }}</span>
        </div>
        <div class="visual-footer" :style="{ color: theme.cpRightFontColor }">{{ theme.cpRightText }}</div>
      </div>

      <div class="login-wrap">
        <div ref="loginGroup" class="login-group password-inner">
          <div ref="loginBoard" class="login-board">
            <header class="login-header">
              <h1 class="login-text">
                패스워드 설정
                <span v-if="memberState === enums.common.memberState.EXPIRED.value">
                  {{ pwdChgDay }}일 동안 패스워드를 변경하지 않았습니다. <br />
                  개인정보를 보호하기 위해 패스워드를 변경하세요.
                </span>
                <span v-if="memberState === enums.common.memberState.TEMPORARY.value">
                  패스워드 변경이 필요합니다. <br />
                  개인정보를 보호하기 위해 패스워드를 변경하세요.
                </span>
              </h1>
            </header>

            <div class="login-cont">
              <div class="login-form">
                <div class="input id id-box">
                  <label for="passwordInputBoxId">비밀번호</label>
                  <input type="password" id="passwordInputBoxId" placeholder="Password" v-model="loginPwd" @keyup="onPasswordKeyup" />
                  <i class="login-bak-pw"></i>
                </div>
                <div class="login-password-infobox">
                  <div :class="errMsg.password ? 'password-info-txt on' : 'password-info-txt'">{{ errMsg.password }}</div>
                </div>
                <div class="input pwd pwd-box">
                  <label for="passwordConfirmInputBoxId">비밀번호 확인</label>
                  <input
                    type="password"
                    id="passwordConfirmInputBoxId"
                    placeholder="Password Confirm"
                    v-model="loginPwdConfirm"
                    @keyup="onPasswordConfirmKeyup"
                    ref="passwordConfirmRef"
                  />
                  <i class="login-bak-pw"></i>
                </div>
                <div class="login-password-infobox">
                  <div :class="errMsg.passwordConfirm ? 'password-confirm-info-txt on' : 'password-confirm-info-txt'">
                    {{ errMsg.passwordConfirm }}
                  </div>
                </div>
                <div>
                  <div class="pw-adm" v-html="passwordDesc"></div>
                </div>
                <div class="pw-admin-btn-box">
                  <button type="button" class="btn-pw-cancel" @click="passwdChgCancel">취 소</button>
                  <button type="button" class="btn-pw-find" @click="passwdChgProcess">변경완료</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { espApi } from '@/api';
  import { encryptPassword, isSuccess } from '@/plugins/common-lib';
  import enums from '@/configs/enums';

  export default {
    computed: {
      enums() {
        return enums;
      },
    },
    data() {
      return {
        errMsg: {
          password: null,
          passwordConfirm: null,
        },
        loginPwd: '',
        loginPwdConfirm: '',
        loginId: '',
        loginNm: '',
        memberState: '',
        pwdChgDay: 0,
        passwordRules: [],
        passwordDesc: null,
        theme: {
          topText: '',
          topFontSize: '32px',
          topFontColor: '#fff',
          btmText: '',
          btmFontSize: '16px',
          btmFontColor: '#fff',
          topTextLoca: '50%',
          leftTextLoca: '50%',
          cpRightText: 'ⓒ 2023. ECS Telecom all right reserved',
          cpRightFontColor: '#fff',
        },
      };
    },

    methods: {
      /**
       * 테마 변경
       */
      setUiThemeList() {
        this.$store.dispatch('INIT_THEME_SETTING').then(() => {
          const uiThemeData = this.$store.getters.getThemeSetting;
          if (uiThemeData) {
            this.theme.topText = uiThemeData.topText;
            this.theme.topFontSize = uiThemeData.topFontSize + 'px';
            this.theme.topFontColor = uiThemeData.topFontColor;
            this.theme.btmText = uiThemeData.btmText;
            this.theme.btmFontSize = uiThemeData.btmFontSize + 'px';
            this.theme.btmFontColor = uiThemeData.btmFontColor;
            this.theme.topTextLoca = uiThemeData.topTextLoca + '%';
            this.theme.leftTextLoca = uiThemeData.leftTextLoca + '%';
            this.theme.cpRightText = uiThemeData.cpRightText;
            this.theme.cpRightFontColor = uiThemeData.cpRightFontColor;
          }
        });
      },
      onPasswordKeyup(e) {
        const validationResult = this.$_validPwd(this.loginPwd.trim(), this.loginId, this.loginNm);
        this.errMsg.password = typeof validationResult === 'string' ? validationResult : null;

        if (e.key === 'Enter' && !this.errMsg.password) this.$refs.passwordConfirmRef.focus();
      },
      onPasswordConfirmKeyup(e) {
        const validationResult = this.$_validPwd(this.loginPwd.trim(), this.loginId, this.loginNm);
        this.errMsg.passwordConfirm = typeof validationResult === 'string' ? validationResult : null;

        if (!this.errMsg.passwordConfirm && this.loginPwd.trim() !== this.loginPwdConfirm.trim())
          this.errMsg.passwordConfirm = this.$_msgContents('PASSWORD_MISMATCH', {
            defaultValue: '패스워드가 일치하지 않습니다. 다시 확인하세요.',
          });

        if (e.key === 'Enter' && !this.errMsg.passwordConfirm) this.passwdChgProcess();
      },
      /**
       * 패스워드 변경 취소
       */
      passwdChgCancel() {
        let payload = {
          params: {
            loginId: this.$store.getters.getLoginId,
          },
        };
        this.$store.dispatch('LOGOUT', payload);
      },
      /**
       * 패스워드 변경 처리
       *
       * @return {Promise<void>}
       * @constructor
       */
      async passwdChgProcess() {
        this.errMsg.password = null;
        for (const passwordRule of this.passwordRules) {
          const error = passwordRule(this.loginPwd.trim(), this.loginId, this.loginNm);
          if (error !== true) {
            this.errMsg.password = error;
            break;
          }
        }

        this.errMsg.passwordConfirm = null;
        for (const passwordRule of this.passwordRules) {
          const error = passwordRule(this.loginPwdConfirm.trim());
          if (error !== true) {
            this.errMsg.passwordConfirm = error;
            break;
          }
        }

        if (!this.errMsg.passwordConfirm && this.loginPwd.trim() !== this.loginPwdConfirm.trim()) {
          this.errMsg.passwordConfirm = this.$_msgContents('PASSWORD_MISMATCH', {
            defaultValue: '패스워드가 일치하지 않습니다. 다시 확인하세요.',
          });
        }

        if (!this.errMsg.password && !this.errMsg.passwordConfirm) {
          const hash = encryptPassword(this.loginId, this.loginPwd, this.$store.getters.getEncryptionType);

          const payload = {
            loginId: this.loginId,
            loginPwd: hash,
          };

          const res = await espApi.getPasswdChg(payload);
          if (isSuccess(res)) {
            await this.$router.push(this.$route.query ? this.$route.query.continue : undefined);
          } else {
            this.errMsg.passwordConfirm = res.data.header.resMsg;
          }
        }
      },

      setCreateData() {
        setTimeout(() => {
          this.$refs.amorWrapper.classList.add('loaded');
          this.$refs.amorWrapper.parentNode.style.height = '100%';
        }, 500);

        this.memberState = this.$store.getters.getMemberState;
        this.pwdChgDay = this.$_getSystemData('pwd_chg_day')?.configValue;
        this.loginId = this.$store.getters.getLoginId;
        this.loginNm = this.$store.getters.getLoginNm;

        const [consecutiveLength, includeLoginId, includeLoginNm, minLength, maxLength, minCategories, specialChars] =
          this.$_getPwdSystemData();

        const rules = [
          `대문자, 소문자, 숫자, 특수문자 중 ${minCategories}가지 이상 조합`,
          `최소 ${minLength}자 이상, 최대 ${maxLength}자 이하`,
          `연속된 문자나 숫자 ${consecutiveLength}자 이상 사용 불가`,
          includeLoginId ? '아이디 포함 불가' : '',
          includeLoginNm ? '이름 포함 불가' : '',
          `사용 가능 특수문자 ${specialChars}`,
          '예) Abc123!@#',
        ];

        this.passwordDesc = rules.filter(rule => rule !== '').join('<br/>');
      },
    },
    created() {
      this.setUiThemeList();
    },
    mounted() {
      if (typeof String.prototype.parseFunction != 'function') {
        String.prototype.parseFunction = function () {
          const funcReg = /function *\(([^()]*)\)[ \n\t]*{(.*)}/gim;
          const match = funcReg.exec(this.replace(/\n/g, ' '));
          if (match) {
            return new Function(match[1].split(','), match[2]);
          }
          return null;
        };
      }
      this.setCreateData();
    },
  };
</script>

<style scoped src="@/assets/css/login.css"></style>
