import CryptoJS from 'crypto-js';

let instance;

/**
 * AES 암호화 및 복호화를 위한 클래스
 */
class AesUtil {
    /**
     * AesUtil 인스턴스 생성
     * @param {string} iv - 초기화 벡터 (Base64 인코딩된 문자열)
     * @param {string} passPhrase - 비밀키 (UTF-8 문자열)
     */
    constructor(iv, passPhrase) {
        if (instance) return instance;
        this.iv = CryptoJS.enc.Base64.parse(iv);
        this.passPhrase = CryptoJS.enc.Utf8.parse(passPhrase);
        instance = this;
    }

    /**
     * 평문 문자열을 암호화
     * @param {string} plainText - 암호화할 평문 문자열
     * @return {string} 암호화된 문자열 (Hex 인코딩된 문자열)
     */
    encrypt = (plainText) => {
        const encrypted = CryptoJS.AES.encrypt(plainText, this.passPhrase, {
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const base64CipherText = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
        return CryptoJS.enc.Hex.stringify(CryptoJS.enc.Utf8.parse(base64CipherText));
    };

    /**
     * 암호화된 문자열을 복호화
     * @param {string} cipherText - 복호화할 문자열 (Hex 인코딩된 문자열)
     * @return {string} 복호화된 문자열
     */
    decrypt = (cipherText) => {
        const base64CipherText = CryptoJS.enc.Hex.parse(cipherText).toString(CryptoJS.enc.Utf8);
        const cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(base64CipherText)
        });
        const decrypted = CryptoJS.AES.decrypt(cipherParams, this.passPhrase, {
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    };
}

/**
 * @return {AesUtil} AesUtil 인스턴스
 */
export const getAesUtil = () => {
    const {
        VUE_APP_AES_IV: IV,
        VUE_APP_AES_PASSPHRASE: PASS_PHRASE,
    } = process.env;
    return new AesUtil(IV, PASS_PHRASE);
};
