<template>
	<div class="card-wrapper pt-2.5">
		<div class="card">
			<div class="h-9 relative">
				<div v-if="widget" class="card_content absolute w-12" :style="todayScoreLineStyle()">
          {{ scoreInCard(this.todayScore) }}
				</div>
				<div class="scoreCardBar pl-32">
					<div class="todayScore">
						<DxProgressBar
							id="todayScoreBar"
							:min="0"
							:max="maxValue"
							:value="progressValue"
							:showStatus="false"
							:width="this.todayWidth"
							:height="this.contentHeight * 0.5"
							@initialized="barColorByScore"
						/>
						<div class="percentage pl-28">{{ this.upDown + ' ' + this.percentage + '%' }}</div>
					</div>
				</div>
			</div>
			<div class="h-8 relative pt-px">
				<div v-if="widget" class="card_content absolute w-12" :style="notTodayContentStyle()">
					<div class="statusFormat">
            {{ scoreInCard(this.notTodayScore) }}
					</div>
				</div>
				<div class="scoreCardBar pl-32">
					<div class="notTodayScore">
						<DxProgressBar
							id="notTodayScoreBar"
							:min="0"
							:max="maxValue"
							:value="this.notTodayScore"
							:showStatus="false"
							:width="this.notTodayWidth"
							:height="this.contentHeight * 0.5"
						/>
					</div>
				</div>
			</div>
			<div class="text-sm">(비교: {{ this.subjectType }})</div>
		</div>
	</div>
</template>

<script>
import { DxProgressBar } from 'devextreme-vue/progress-bar';
import moment from "moment";
const maxValue = 10;
export default {
  components: {
        DxProgressBar,
  },
  computed: {
    progressValue() {
      return maxValue - this.seconds;
    },
  },
  props: {
    data : {
      type: Array,
    },
    widget: {
      type: Object,
    },
    size: {
      type: Object
    }
  },
  data() {
    return {
      maxValue,
      seconds: maxValue,
      inProgress: false,
      statusTxt: '',
      contentHeight: 0,
      headerHeight: 0,
      todayWidth: '70px',
      notTodayWidth: '70px',
      todayScore: 0,
      formatnotTodayScore: null,
      notTodayScore: 0,
      subjectTypeList: [
        { id: '1251', text: '어제' },
      ],
      // 스코어 카드 차트일 경우 설정 팝업에서 조회 기간 유형이 아닌 비교 대상이 나온다.
      // 비교 대상
      subject:{
        yesterday:'1251'
      },
      subjectType:null,
      percentage: '5.5',
      upDown: '▲',
      barColor: '#198CFF'
    };
  },
  methods: {
    /** @description : 위젯에서 표출되는 수치값 포맷팅 */
    scoreInCard(score) {
      return this.widget.columnList[0].includes('RATE')||this.widget.columnList[0].includes('SVL')
          ? score+'%'
          : this.widget.columnList[0].includes('TIME')
              ? this.secondsToHoursAndMinutes(score)
              : score.toLocaleString('ko-KR')
    },
    /** @description : 날짜 포맷 변경 메서드 */
    dateObjectFormating(dateString){
      let formattedDate = dateString.substring(0, 4) + '-' + dateString.substring(4, 6) + '-' + dateString.substring(6, 8);
      return formattedDate;
    },
    /** @description : 오늘 날짜에 해당하는 데이터 height 값 */
    todayScoreLineStyle(){
        return `line-height: 38px`
    },
    /** @description : 오늘 날짜에 해당하는 데이터 아래 비교대상에 해당하는 데이터 height 값 */
    notTodayContentStyle(){
        return `line-height: 12px`
    },
    /** @description : 오늘 날짜에 해당하는 바 색상 설정 */
    barColorByScore(e){
      this.$nextTick(() => {
        let element = e.element.getElementsByClassName('dx-progressbar-container').item(0);
        let percentageElement = e.element.nextSibling;
        if(this.todayScore>this.notTodayScore ){
          element.style.backgroundColor = '#d2504b';
          percentageElement.style.color = '#d2504b';
        }else{
          element.style.backgroundColor = '#4b6bbf';
          percentageElement.style.color = '#4b6bbf';
        }
      });
    },
    /** @description : 밀리세컨드를 시간과 분으로 변환 */
    secondsToHoursAndMinutes(milliseconds) {
      const hours = Math.floor(milliseconds / 3600);
      const minutes = Math.floor((milliseconds - (hours * 3600)) / 60);
      const seconds = milliseconds - (hours * 3600) - (minutes * 60);

      // 시간이 0인 경우 시간 표시를 생략
      if (hours < 1) {
        return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
      } else {
        return hours + ":" + (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
      }
    },
    /** @description : 카드 데이터 셋팅 */
    async setCardData(){
      // 수치 데이터(어제, 오늘) 계산
      await this.widgetDataSource();

      // 비교 대상값
      this.subjectType = this.subjectTypeList.find(item => item.id === this.widget.subjectType).text;

      // 바 길이 및 퍼센티지 계산
      const notTodayScore = this.notTodayScore
      const todayScore = this.todayScore
      const p = 0.7;
      if (notTodayScore > todayScore) {
        this.notTodayWidth = '70px';
        this.todayWidth = ((todayScore / notTodayScore) * 100) * p + 'px';
        this.percentage = (((todayScore / notTodayScore) - 1) * 100).toFixed(1);
        this.upDown = '▼';
      } else {
        this.todayWidth = '70px';
        this.notTodayWidth = ((notTodayScore / todayScore) * 100) * p + 'px';
        this.percentage = (((todayScore / notTodayScore) - 1) * 100).toFixed(1);
        this.upDown = '▲';
      }
    },
    /** @description : 수치 데이터(어제, 오늘) 계산 */
    async widgetDataSource() {
      // 날짜 재정의 및 포맷팅
      const date = new Date();
      let today = '';
      let yesterday = '';
      if (this.widget.subjectType === this.subject.yesterday) {
        today = moment(new Date()).format('YYYYMMDD');
        yesterday = moment(new Date(date.setDate(date.getDate() - 1))).format('YYYYMMDD');
      }
      // this.data를 필터링하여 어제와 오늘의 숫자를 구한다.
      const columnList = this.widget.columnList.join();
      const columnGroupList = this.widget.columnGroupList.join();

      let checkValue = 0;
      for (const item of this.data) {
        const itemDate = moment(new Date(this.dateObjectFormating(item[columnGroupList]))).format('YYYYMMDD');
        if(today === itemDate){
          this.todayScore = item[columnList];
          checkValue++;
        }
        if(yesterday === itemDate){
          this.notTodayScore = item[columnList];
          checkValue++;
        }

        if(checkValue === 2) break;
      }
    },
  },
  async mounted() {
    await this.setCardData();
  }
}
</script>
<style>
.scoreCardBar {
	display: inline-block;

	.dx-progressbar-container {
		height: 6px;
		border-radius: 10px;
	}

	.todayScore {
		height: 16px;
		padding: 0 10px 10px 0;

		.dx-progressbar-status {
			display: none;
		}

		.dx-progressbar-range {
			display: none;
		}
	}

	.notTodayScore {
		height: 9px;
		padding: 0px 10px 10px 0;
		display: inline;

		.dx-progressbar-container {
			background-color: #dddddd;
		}

		.dx-progressbar-status {
			width: 100px;
			padding: 10px 0 0 0;
			color: #545454;
		}

		.dx-progressbar-range {
			display: none;
		}
	}
}
</style>

<style scoped lang="scss">
.card {
	border-radius: 4px;
	box-shadow: none;
	position: relative;
	border: 1px solid var(--border-color);
	overflow: hidden;

	.card_header {
		padding: 8px 6px 3px 0;
		font-width: 400;
	}

	.card_content {
		margin-top: -13px;
		padding: 3px 50px 4px 0;
		width: 120px;
		display: inline;
	}

	.overflow-menu {
		position: absolute;
		right: 8px;
		left: auto;
		top: 10px;
	}

	:deep(.card_content) {
		font-size: 24px;
		font-weight: 400;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	:deep(.card_title) {
		font-size: 16px;
		padding: 0 5px 7px 0;
	}

	.statusFormat {
		padding: 13px 0 0 0;
		font-size: 12px;
		color: #545454;
	}
}
</style>