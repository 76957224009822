<!--
  PACKAGE_NAME : src\pages\esp\system\menu\favorite-menu-select.vue
  FILE_NAME : favorite-menu-select
  AUTHOR : sskim
  DATE : 2024-09-10
  DESCRIPTION : 즐겨찾기 메뉴 선택 컴포넌트
-->
<template>
	<transition>
		<div ref="contentsWrap" class="container contents-wrap" id="contentsWrap">
			<div class="contents-box page-sub-box clearfix ui-glid-box flex flex-wrap items-center">
				<DxDataGrid
					class="shrink-0"
					ref="menuSelect1"
					:data-source="defaultDatas.menu1" :show-borders="true" :show-column-headers="true"
					:show-column-lines="false" :show-row-lines="true" :row-alternation-enabled="false"
					:allow-column-reordering="true" :no-data-text="noDataTopDepth()" :width="defaultWidth" :height="defaultHeight"
					key-expr="id" :selected-row-keys="selectedIds.menu1"
					@selection-changed="selectionChangedDepth1">

					<DxColumn caption="id" data-field="id" :width="80" alignment="center" :visible="false" />
					<DxColumn caption="대메뉴" data-field="menuNm" :width="100" alignment="left"
						:visible="true" />

					<DxSelection mode="single" />
					<DxFilterRow :visible="true">
						<DxOperationDescriptions contains="포함" notContains="불포함" equal="=" not-equal="!="
							between="between" start-with="startWith" ends-with="endsWith" greater-than=">"
							greater-than-or-equal=">=" less-than="<" less-than-or-equal="<=" />
					</DxFilterRow>
					<DxScrolling mode="virtual" />
				</DxDataGrid>
				<div class="arrow-box"><span class="mdi mdi-chevron-right mdi-24px"></span></div>
				<DxDataGrid
					class="shrink-0"
					:data-source="selectedByPrevDatas.menu2" :show-borders="true"
					:show-column-headers="true" :show-column-lines="false" :show-row-lines="true"
					:row-alternation-enabled="false" :allow-column-reordering="true" :width="defaultWidth" :height="defaultHeight"
					:no-data-text="noDataText()" :selected-row-keys="selectedIds.menu2" key-expr="id"
					@selection-changed="selectionChangedDepth2" :disabled="disabledDepth2">
					<DxColumn caption="id" data-field="id" :width="80" alignment="center" :visible="false" />
					<DxColumn caption="중메뉴" data-field="menuNm" :width="100" alignment="left"
						:visible="true" />

					<DxSelection mode="single" show-check-boxes-mode="always" />
					<DxFilterRow :visible="true">
						<DxOperationDescriptions contains="포함" notContains="불포함" equal="=" not-equal="!="
							between="between" start-with="startWith" ends-with="endsWith" greater-than=">"
							greater-than-or-equal=">=" less-than="<" less-than-or-equal="<=" />
					</DxFilterRow>
					<DxScrolling mode="virtual" />
				</DxDataGrid>
				<div class="arrow-box"><span class="mdi mdi-chevron-right mdi-24px"></span></div>
				<!-- 3depth -->
				<DxDataGrid
					class="shrink-0"
					:data-source="selectedByPrevDatas.menu3" :show-borders="true"
					:show-column-headers="true" :show-column-lines="false" :show-row-lines="true"
					:row-alternation-enabled="false" :allow-column-reordering="true" :width="defaultWidth" :height="defaultHeight"
					:no-data-text="noDataText()" :selected-row-keys="selectedIds.menu3" key-expr="id"
					@selection-changed="selectionChangedDepth3" :disabled="disabledDepth2 || disabledDepth3">
					<DxColumn caption="id" data-field="id" :width="80" alignment="center" :visible="false" />
					<DxColumn caption="소메뉴" data-field="menuNm" :width="100" alignment="left"
						:visible="true" />

					<DxSelection mode="single" show-check-boxes-mode="always" />
					<DxFilterRow :visible="true">
						<DxOperationDescriptions contains="포함" notContains="불포함" equal="=" not-equal="!="
							between="between" start-with="startWith" ends-with="endsWith" greater-than=">"
							greater-than-or-equal=">=" less-than="<" less-than-or-equal="<=" />
					</DxFilterRow>
					<DxScrolling mode="virtual" />
				</DxDataGrid>
			</div>
		</div>
	</transition>
</template>

<script>
import { DxDataGrid, DxSelection, DxScrolling, DxColumn, DxFilterRow, DxOperationDescriptions } from 'devextreme-vue/data-grid';

export default {
	components: {
		DxDataGrid,
		DxSelection,
		DxScrolling,
		DxColumn,
		DxFilterRow,
		DxOperationDescriptions,
	},
	props: {
		'defaultWidth' : {
			type : Number,
			default : 170
		},
		'defaultHeight' : {
			type : Number,
			default : 265
		},
	},
	watch: {
	},
	data() {
		return {
			//메뉴 초기 default data
			defaultDatas: {
				menu1: [],
				menu2: [],
				menu3: [],
			},
			selectedByPrevDatas: {
				menu1: [],             //선택된 depth1에 해당하는 데이터
				menu2: [],             //선택된 depth2에 해당하는 데이터
				menu3: [],             //선택된 depth3에 해당하는 데이터
			},
			//선택된 메뉴 데이터
			selectedDatas: {
				menu1: [],
				menu2: [],
				menu3: [],
			},
			//선택된 메뉴 id를 담은 데이터(DB에 저장된 데이터를 출력하기 위해 사용)
			selectedIds: {
				menu1: [],
				menu2: [],
				menu3: [],
			}
		}
	},
	computed: {
		disabledDepth2() {
			return this.selectedByPrevDatas.menu2 === null;
		},
		disabledDepth3() {
			return this.selectedByPrevDatas.menu3 === null;
		},
	},
	methods: {
		/** @description: depth1 로우 선택시 이벤트 */
		selectionChangedDepth1(e) {
			this.selectedDatas.menu1 = e.selectedRowsData;
			let selectedIds = this.selectedDatas.menu1.map(d => d.id);
			let depthDatas = this.defaultDatas.menu2.filter(d => selectedIds.includes(d.parentId));
			this.selectedByPrevDatas.menu2 = depthDatas.length > 0 ? depthDatas : null;
		},
		/** @description: depth2 로우 선택시 이벤트 */
		selectionChangedDepth2(e) {
			this.selectedDatas.menu2 = e.selectedRowsData;
			let selectedIds = this.selectedDatas.menu2.map(d => d.id);
			let depthDatas = this.defaultDatas.menu3.filter(d => selectedIds.includes(d.parentId));
			this.selectedByPrevDatas.menu3 = depthDatas.length > 0 ? depthDatas : null;
		},
		/** @description: depth3 로우 선택시 이벤트 */
		selectionChangedDepth3(e) {
			this.selectedDatas.menu3 = e.selectedRowsData;
		},
		noDataTopDepth() {
			return `해당 데이터를 불러오는 중입니다.`;
		},
		noDataText() {
			return `하위 메뉴 없음`;
		},
		findMenuFromStore(id, depth = 3) {
			return this.$store.getters.getMenuList.find(d => d.id === id && d.menuDepth == depth);
		},
		setMenuList() {
			const menus = this.$store.getters.getMenuList.filter(d => d.menuDepth === 3 || d.menuDepth === 4).sort((a, b) => a.menuOrd - b.menuOrd);
			let setMenus = {
				menu1 : [],
				menu2 : [],
				menu3 : [],
			};

			for (const element of menus) {
				const menuDepth = element.menuDepth;
				const parentId = element.parentId ?? 0;

				let menu1, menu2, menu3;

				if (menuDepth === 3) {
					menu2 = this.findMenuFromStore(parentId, 2) ?? null;
					menu1 = this.findMenuFromStore(menu2?.parentId ?? 0, 1) ?? null;
				} else if (menuDepth === 4) {
					menu3 = this.findMenuFromStore(parentId, 3) ?? null;
					menu2 = this.findMenuFromStore(menu3?.parentId ?? 0, 2) ?? null;
					menu1 = this.findMenuFromStore(menu2?.parentId ?? 0, 1) ?? null;
				}

				if (menu1 === null || menu2 === null) continue;

				setMenus.menu1.push(menu1.id);
				setMenus.menu2.push(menu2.id);
				setMenus.menu3.push({ ...element });
			}

			const getUniqueMenus = (menuIds, depth) => {
				return [...new Set(menuIds)].filter(menuId => menuId != null).map(menuId => {
					return this.findMenuFromStore(menuId, depth) ?? null;
				});
			};

			setMenus.menu1 = getUniqueMenus(setMenus.menu1, 1).sort((a, b) => a.menuOrd - b.menuOrd);
			setMenus.menu2 = getUniqueMenus(setMenus.menu2, 2).sort((a, b) => a.menuOrd - b.menuOrd);

			this.defaultDatas = setMenus;
		},
		getSelectMenuInfo() {
			return this.selectedDatas;
		},
		/** @description: 선택항목 해제 */
		resetSelect() {
			this.selectedByPrevDatas.menu2 = [];
			this.selectedByPrevDatas.menu3 = [];
			this.selectedDatas.menu1 = [];
			this.selectedDatas.menu2 = [];
			this.selectedDatas.menu3 = [];
			this.$refs.menuSelect1?.instance?.clearSelection();
		},
	},
	created() {
	},
	mounted() {
		this.setMenuList();
	},
	destroyed() {
	},
}
</script>

<style scoped>
.contents-title-box {
	height: 40px;
	position: relative;
	border-bottom: 1px solid #CCC;
	line-height: 40px;
}

.contents-title-box .contents-title {
	height: 40px;
	padding-left: 5px;
	display: inline-block;
	font-size: 0.9em;
}

.contents-box {
	width: 100%;
	padding: 20px 10px;
	background-color: #FFF;
}

</style>
<style ></style>