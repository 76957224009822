<template>
  <div class="container border-top-1px" id="reportGridContainer">
    <DxDataGrid
      :ref="gridRefName"
      :data-source="reportDatas"
      :show-borders="false"
      :show-row-lines="true"
      :show-column-lines="true"
      :show-column-headers="true"
      :width="gridWidth"
      height="calc(100vh - 250px)"
      :rowAlternationEnabled="true"
      :allow-column-resizing="true"
      :allow-column-reordering="true"
      :column-auto-width="true"
      :column-resizing-mode="currentMode"
      @rowPrepared="onRowPrepared"
      @cellPrepared="onCellPrepared"
      @cellHoverChanged="onCellHoverChanged"
      @toolbar-preparing="onToolbarPreparing"
      class="report-grid-border"
      no-data-text="데이터가 존재하지 않습니다."
    >
      <DxPager
        :visible="dataGrid.pager.visible"
        :show-page-size-selector="dataGrid.pager.showPageSizeSelector"
        :allowed-page-sizes="dataGrid.pager.allowedPageSizes"
        :display-mode="dataGrid.pager.displayMode"
        :show-info="dataGrid.pager.showInfo"
        :show-navigation-buttons="dataGrid.pager.showNavigationButtons"
      />

      <DxPaging :enabled="dataGrid.paging.enabled" :page-size="dataGrid.paging.pageSize" :page-index="dataGrid.paging.pageIndex" />

      <DxRemoteOperations
        :filtering="dataGrid.remoteOperations.filtering"
        :sorting="dataGrid.remoteOperations.sorting"
        :grouping="dataGrid.remoteOperations.grouping"
        :paging="dataGrid.remoteOperations.paging"
      />

      <DxLoadPanel :enabled="false" />
      <!-- infinite, virtual -->
      <DxScrolling :mode="dataGrid.scrollMode" :column-rendering-mode="useVirtualByColumnSize" v-if="!useMenuInfoPaging()" />
      <DxColumnFixing :enabled="true" />
      <DxGroupPanel :visible="true" />
      <DxGrouping :auto-expand-all="false" />
      <template #filteringBox>
        <DxDropDownBox
          v-model="filter.selectedKeys"
          :height="30"
          :width="200"
          :defer-rendering="false"
          :show-clear-button="true"
          :data-source="filter.options"
          styling-mode="outlined"
          display-expr="name"
          value-expr="value"
          placeholder="보고서 필터"
          class="mr-10"
          @value-changed="onChangedDropDownFilter"
        >
          <template #content="{}">
            <DxDataGrid
              :data-source="filter.options"
              :hover-state-enabled="true"
              :selected-row-keys="filter.selectedRowsData"
              @selection-changed="onChangedGridFilter"
            >
              <DxSelection mode="multiple" />
              <DxPaging :enabled="false" :page-size="10" />
              <DxScrolling mode="virtual" />
              <DxColumn caption="보고서 필터" data-field="name" alignment="center" :visible="true" />
            </DxDataGrid>
          </template>
        </DxDropDownBox>
      </template>

      <template v-for="(item, i) in columns">
        <template v-if="item.length > 0 && [null, ''].indexOf(item[0].multiHeaderNm) === -1">
          <DxColumn :caption="item[0].multiHeaderNm" :key="i">
            <DxColumn
              v-for="(item2, i2) in item"
              alignment="center"
              :key="`${i}_${i2}`"
              :allow-sorting="false"
              :caption="item2.caption"
              :dataField="item2.dataField"
              css-class="grid-header-border"
              :cellTemplate="cellTemplateByColumnFmt(item2.format)"
            />
          </DxColumn>
        </template>
        <template v-else>
          <template v-if="item.grouping">
            <DxColumn
              :key="i"
              :allow-sorting="false"
              :caption="item.caption"
              :dataField="item.dataField"
              :alignment="item.align"
              :width="item.width"
              :visible="item.visible"
              :fixed="item.fixed"
              :group-index="i"
              :cellTemplate="cellTemplateByColumnFmt(item.format)"
            ></DxColumn>
          </template>
          <template v-else>
            <DxColumn
              :key="i"
              :allow-sorting="false"
              :caption="item.caption"
              :dataField="item.dataField"
              :alignment="item.align"
              :width="item.width"
              :visible="item.visible"
              :fixed="item.fixed"
              :cellTemplate="cellTemplateByColumnFmt(item.format)"
            ></DxColumn>
          </template>
        </template>
      </template>

      <DxSummary v-if="hasGrouping">
        <DxGroupItem
          v-for="item in groupItems"
          :key="item.dataField"
          :column="item.dataField"
          :show-in-group-footer="false"
          :align-by-column="true"
          summary-type="max"
          display-format="{0}"
        />
      </DxSummary>

      <template #totalCount>
        <div class="total-count-item">
          검색결과 <span class="tet-calr1">{{ fmtNumber(totalCount) }}</span> 개
        </div>
      </template>

      <template #dateTemplate="{ data }">
        {{ fmtDate(data.value) }}
      </template>

      <template #percentTemplate="{ data }">
        {{ data.value + '%' }}
      </template>

      <template #timeTemplate="{ data }">
        {{ fmtTime(data.value) }}
      </template>

      <template #numberTemplate="{ data }">
        {{ fmtNumber(data.value) }}
      </template>

      <template #defaultTemplate="{ data }">
        {{ data.value }}
      </template>

      <template #treeTemplate="{ data }">
        <span :style="styleLeftPadding(data.value)">{{ data.value }}</span>
      </template>

      <template #linkTemplate="{ data }">
        <a @click="handleClickToggleHistoryModal(data)">{{ data.value }}</a>
      </template>

      <template #iconTemplate="{ data }">
        <DxButton v-if="data.value" @click="openNewLinkPage(data)" class="btn_XS white light_filled return" :width="30" :height="30" />
      </template>
      <template #apiTemplate="{ data }">
        <a @click="openApiDataPopup(data)">{{ data.value }}</a>
      </template>
    </DxDataGrid>
    <DxPopover width="auto" position="top" :target="popover.target" :visible="popover.visible">
      <template
        ><p class="whitespace-pre-line">{{ popover.description.trim() }}</p></template
      >
    </DxPopover>
  </div>
</template>

<script>
  import { DxPopover } from 'devextreme-vue/popover';
  import { cloneObj, downloadFile, getResData, isSuccess } from '@/plugins/common-lib';
  import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLoadPanel,
    DxScrolling,
    DxPager,
    DxGrouping,
    DxGroupPanel,
    DxRemoteOperations,
    DxSelection,
    DxColumnFixing,
    DxGroupItem,
    DxSummary,
  } from 'devextreme-vue/data-grid';
  import { formatDate, isTrue } from '@/plugins/common-lib';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import { DxButton } from 'devextreme-vue/button';
  import { ReportManager } from '@/pages/report/report';
  import CustomStore from 'devextreme/data/custom_store';

  export default {
    components: {
      DxButton,
      DxSelection,
      DxDropDownBox,
      DxDataGrid,
      DxGrouping,
      DxGroupPanel,
      DxColumn,
      DxPaging,
      DxColumnFixing,
      DxLoadPanel,
      DxScrolling,
      DxPopover,
      DxPager,
      DxRemoteOperations,
      DxGroupItem,
      DxSummary,
    },
    props: {
      columns: Array,
      pageData: String,
      reportInfo: Object,
      columnsDesc: Array,
      pageLoaded: Boolean,
      getReportLocalStorage: Function,
      pageSize: {
        type: Number,
        default: 100,
      },
    },
    watch: {
      pageLoaded() {
        if (this.pageLoaded) this.init();
      },
    },
    data() {
      return {
        dataGrid: {
          scrollMode: 'virtual',
          paging: {
            enabled: true,
            pageSize: this.pageSize,
            pageIndex: 0,
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          rowColor: {},
        },
        gridRefName: 'grid',
        currentComponent: 'ReportSearchBox', //검색조건창의 컴포넌트 명
        currentMode: 'widget', //컬럼 리사이즈 nextColumn(넓이 내에서) / widget(화면 확장)
        popover: {
          visible: false,
          target: null,
          description: '',
        },
        gridWidth: '100%',
        reportDatas: [],
        reportId: this.$route.params.menuid, //보고서 메뉴ID
        // rowspan 정보 (컬럼명: rowspan size, ...)
        rowspanDatas: {},
        //toolbar
        toolbarItems: [],
        filter: {
          selectedKeys: ReportManager.getGridFilter().map(v => v.value),
          selectedRowsData: ReportManager.getGridFilter(),
          options: ReportManager.getGridFilter(),
        },
        totalCount: 0,
        chkDepthTarget: false,
        disabledDepthTarget: true,
      };
    },
    computed: {
      grid() {
        return this.$refs[this.gridRefName].instance;
      },
      useVirtualByColumnSize() {
        return this.columnsDesc.length > 50 ? 'virtual' : 'standard';
      },
      hasGrouping() {
        return this.columnsDesc.some(item => item.grouping);
      },
      groupItems() {
        if (this.hasGrouping) {
          return this.columnsDesc.filter(item => item.position !== 'start');
        }
        return [];
      },
      getHeaderSizeByColumns() {
        const fullSize = '100%';
        if (this.columnsDesc.length === 0) return fullSize;
        const size = this.columnsDesc.reduce((acc, v) => acc + Number(v.width), 0);
        return size > window.innerWidth - 200 ? fullSize : size;
      },
    },

    methods: {
      /**
       * 보고서 기본 데이터 세팅(필드 및 보고서 페이지 정보)
       * @returns {Promise<void>}
       */
      async init() {
        const usePaging = this.useMenuInfoPaging();
        this.updatePagingOption(usePaging);
        // 컴포넌트 생성 된 후 실행될 메서드

        await this.$nextTick();
        if (usePaging) this.movePagingBox();
        this.gridWidth = this.getHeaderSizeByColumns;
        this.filter.selectedKeys = this.getInitFilterKeys();
        this.setDataGridScrollMode();
        this.toggleHideMultiSheetBtn();
        this.toggleHideGroupSheetBtn();
      },
      updatePagingOption(usePaging = false) {
        this.dataGrid.pager.visible = usePaging;
        this.dataGrid.remoteOperations.paging = usePaging;
      },
      /** 보고서 다운로드 시 최대 행 설정 값 조회 */
      getAllowExcelRowSize() {
        return this.$_getSystemData('report_excel_background_cnt').configValue;
      },
      async asyncExcelBackground(paramsData) {
        let payload = {
          actionname: 'REPORT_RESULT_COUNT',
          data: { data: paramsData },
          loading: true,
          useErrorPopup: true,
        };

        let isContinue = true;
        if (paramsData.excel) {
          const allowSize = this.getAllowExcelRowSize();
          if (allowSize === undefined) {
            isContinue = false;
            console.warn(
              `엑셀 다운로드 건수에 대한 설정값이 없습니다.\n'report_excel_background_cnt' 값이 설정되어  있는지 확인부탁드립니다.`,
            );
          }

          if (isContinue) {
            const res = await this.CALL_REPORT_API(payload);
            if (isSuccess(res)) {
              const resData = getResData(res)[0];
              paramsData.totalCount = resData.totalCnt;
              if (resData.totalCnt > allowSize) {
                const confirmBackgroundMsg =
                  `현재 다운로드 받으려고 하는 엑셀의 데이터(${resData.totalCnt}건)가 너무 많아<br>` +
                  ` 오랜시간이 걸릴것으로 예상됩니다. <br> 백그라운드 형태로 다운로드를 진행하시겠습니까?`;
                if (await this.$_Confirm(confirmBackgroundMsg)) {
                  payload = {
                    actionname: 'REPORT_EXCEL_BACKGROUND_DOWNLOAD',
                    data: { data: paramsData },
                    loading: false,
                  };
                } else {
                  isContinue = false;
                }
              } else {
                payload = {
                  actionname: 'REPORT_EXCEL_DOWNLOAD',
                  data: { data: paramsData },
                  responseType: 'arraybuffer',
                  loading: true,
                };
              }
            } else {
              throw new Error('보고서 조회 중 문제가 발생하였습니다.');
            }
          } else {
            payload = {
              actionname: 'REPORT_RESULT_LIST',
              data: { data: paramsData },
              loading: true,
            };
          }
        }

        return { payload, isContinue };
      },
      /**
       * 보고서 조회 (엑셀 / 대용량 엑셀 / WEB)
       * @param params: 보고서 조회 파라미터
       * @returns {Promise<{data: *[], totalCount: number}|(function(): never)|*>}
       */
      async showReportResults(params) {
        if (this.reportInfo === undefined) return;
        const { paging, reportId } = this.reportInfo;
        // ** reportId 셋팅
        params.reportId = reportId;

        // ** 페이징처리 셋팅: Default False
        params.paging = false;
        params.pagesize = 0;
        params.currentpage = 0;
        if (isTrue(paging)) {
          params.paging = true;
          params.pagesize = this.dataGrid.paging.pageSize;
          params.currentpage = this.dataGrid.paging.pageIndex;
        }

        // ** 컬럼셋팅
        params.visibleColumn = this.columnsDesc.reduce((acc, v) => {
          if (v.visible) acc.push(v.dataField);
          return acc;
        }, []);

        // ** 데이터 초기화 & Payload 변경(Default Web)
        let payload = {
          actionname: params.actionname,
          data: { data: params },
          loading: true,
        };

        // ** 엑셀인 경우 payload 재설정
        const excelFl = params.excel;
        const workSection = this.$_getCode('work_section');
        if (excelFl === 0) {
          params.workSection = workSection.find(e => e.codeNm === '조회').codeValue;
        } else {
          params.workSection = workSection.find(e => e.codeNm === '엑셀다운').codeValue;
          payload.actionname = 'REPORT_EXCEL_BACKGROUND_DOWNLOAD';
          if (excelFl === 1) {
            params.excel_limit_cnt = this.$_getSystemData('report_excel_background_cnt').configValue;
            const excelBackground = await this.asyncExcelBackground(params);
            if (excelBackground.isContinue) {
              payload = excelBackground.payload;
            } else {
              // 지정한 엑셀 Row 카운트보다 낮다면 프로세스 STOP
              return;
            }
          } else if (excelFl === 2) {
            payload.actionname = 'REPORT_EXCEL_MULTI_SHEET_DOWNLOAD';
          } else if (excelFl === 3) {
            payload.actionname = 'REPORT_EXCEL_TARGET_SHEET_DOWNLOAD';
          }
        }

        // 필터
        params.filter = this.getFilterSumNumber();

        // actionName 별 분기처리
        const payloadActionNm = payload.actionname;
        if ('REPORT_EXCEL_DOWNLOAD' === payloadActionNm) {
          payload.data.data.column = this.columnsDesc;
          let res = await this.CALL_REPORT_API(payload);
          if (res.status == 200 && res.data) {
            downloadFile(res);
          } else {
            this.$_Msg('다운로드 실패');
          }
        } else if ('REPORT_EXCEL_BACKGROUND_DOWNLOAD' === payloadActionNm) {
          payload.data.data.column = this.columnsDesc;
          await this.$_Msg('백그라운드 모듈을 시작합니다.');
          this.CALL_REPORT_API(payload);
        } else if (['REPORT_RESULT_LIST', 'MASTER_QUERY_RESULT_LIST'].includes(payloadActionNm)) {
          this.reportDatas = []; // 초기화
          this.reportDatas = this.setGridCustomStore(payload);
        } else if ('REPORT_EXCEL_MULTI_SHEET_DOWNLOAD' === payloadActionNm) {
          if (await this.$_Confirm('엑셀 다운로드를 진행하시겠습니까?')) {
            payload.loading = false;
            payload.responseType = 'arraybuffer';
            payload.data.data.reportIds = this.getPageData('multiDownload');
            await this.CALL_REPORT_API(payload);
          }
        } else if ('REPORT_EXCEL_TARGET_SHEET_DOWNLOAD' === payloadActionNm) {
          if (params.obj.length > 255) {
            await this.$_Msg('시트 다운로드 시 대상은 255개 초과할 수 없습니다.');
            return false;
          }
          if (await this.$_Confirm('엑셀 다운로드를 진행하시겠습니까?')) {
            payload.loading = false;
            payload.data.data.column = this.columnsDesc;
            await this.$_Msg('백그라운드 모듈을 시작합니다.');
            await this.CALL_REPORT_API(payload);
          }
        }
      },
      // 데이터(1), 소계(2), 합계(4) 선택된 숫자를 더해서 리턴
      // 1(데이터), 2(소계), 4(합계), 3(데이터+소계), 5(데이터+합계), 6(소계 + 합계), 7(데이터 + 소계 + 합계)
      getFilterSumNumber() {
        if (this.filter.selectedKeys.length === 0) return 1; // 아무것도 존재하지 않을 시 "데이터"만
        return this.filter.selectedKeys.reduce((acc, v) => acc + Number(v), 0);
      },
      setGridCustomStore(payload) {
        const vm = this;
        return new CustomStore({
          async load(loadOptions) {
            // 페이징 정보 셋팅
            const dxGridParam = vm.$_getDxDataGridParam(loadOptions);
            payload.data.data.currentpage = dxGridParam.currentpage;
            payload.data.data.pagesize = dxGridParam.pagesize;
            payload.data.data.filter = vm.getFilterSumNumber();
            // 보고서 조회 API 호출
            // const res = await vm.CALL_API(payload);
            const res = await vm.CALL_REPORT_API(payload);
            if (isSuccess(res)) {
              const reportResult = getResData(res);
              vm.setRowSpanReportResultData(reportResult);
              vm.setTotalCount(reportResult);
              vm.$emit('setReportLocalStorage');
              return {
                data: reportResult,
                totalCount: vm.totalCount,
              };
            } else {
              return () => {
                throw '데이터 조회 중 문제가 발생하였습니다.';
              };
            }
          },
        });
      },
      /**
       * 보고서 출력 시 rowSpan 참고 데이터 셋팅
       * 특정 열 행 머지 시키는 메서드 (컬럼 중 1개만 가능하며, 해당 컬럼으로 ORDER BY 필요)
       * @param datas: 보고서 조회 결과 데이터
       */
      setRowSpanReportResultData(datas) {
        const mergeCol = this.columns.find(v => isTrue(v.rowMerge));
        if (mergeCol) {
          const targetField = mergeCol.dataField;
          this.rowspanDatas = datas.reduce((acc, v) => {
            if (!acc[v[targetField]]) acc[v[targetField]] = { spanCnt: 0, isUsed: false };
            acc[v[targetField]].spanCnt += 1;
            return acc;
          }, {});
        }
      },
      /** onCellHoverChanged
       *  desc : 컬럼 호버 시 컬럼 설명 툴팁 출력
       */
      onCellHoverChanged(e) {
        this.popover.visible = false;
        this.popover.target = e.cellElement;
        if (e.rowType === 'header' && e.eventType === 'mouseover') {
          const targetColumn = this.columnsDesc.find(d => d.dataField === e.column.dataField);
          if (targetColumn?.description) {
            this.popover.visible = true;
            this.popover.description = targetColumn.description;
          }
        }
      },
      /** <format> 태그 데이터 타입에 따른 Template 반환 */
      cellTemplateByColumnFmt(fmt) {
        switch (fmt) {
          case 'fmDate':
            return 'dateTemplate';
          case 'fmNumber':
            return 'numberTemplate';
          case 'fmPercent':
            return 'percentTemplate';
          case 'fmtime' || 'fmTime':
            return 'timeTemplate';
          case 'button':
            return 'buttonTemplate';
          case 'fmTree':
            return 'treeTemplate';
          case 'fmLink':
            return 'linkTemplate';
          case 'icon':
            return 'iconTemplate';
          case 'api':
            return 'apiTemplate';
          default:
            return null;
        }
      },
      /**
       * 쿼리 조회 결과에 GBN 컬럼이 명시되었다면, 해당 행 컬러 변경
       * @param e
       */
      onRowPrepared(e) {
        try {
          const GID = e.data.GID;
          const MAX_GID = e.data.MAX_GID;
          if (GID !== undefined && 0 < GID) {
            e.rowElement.classList.remove('dx-row-alt');
            e.rowElement.bgColor = GID === MAX_GID ? '#FFEFEF' : '#FFF8ED';
          }
        } catch (error) {}
      },
      onCellPrepared(e) {
        if (e.rowType !== 'data' || e.columnIndex > 0) {
          return;
        }

        // rowSpan 처리(첫번째 컬럼만 가능)
        const firstColValue = e.value;
        const rowspanInfo = this.rowspanDatas[firstColValue];
        if (rowspanInfo) {
          if (rowspanInfo.isUsed === false) {
            e.cellElement.rowSpan = rowspanInfo.spanCnt;
            e.cellElement.innerHTML = firstColValue;
            e.cellElement.style['border-right'] = '1px #e0e0e0 solid';
            this.rowspanDatas[firstColValue].isUsed = true;
          } else {
            e.cellElement.style.display = 'none';
          }
        }
      },
      useMenuInfoPaging() {
        if (this.reportInfo) {
          return isTrue(this.reportInfo.paging);
        }
        return false;
      },
      fmtDate(value) {
        return formatDate(value, 'YYYYMMDD', 'YYYY-MM-DD');
      },
      fmtNumber(value) {
        let rtn = parseInt(value);
        return rtn.toLocaleString('ko-KR');
      },
      fmtTime(seconds) {
        const hour = this.lPadZero(parseInt(seconds / 3600));
        const min = this.lPadZero(parseInt((seconds % 3600) / 60));
        const sec = this.lPadZero(parseInt(seconds % 60));
        return `${hour}:${min}:${sec}`;
      },
      lPadZero(num) {
        if (num < 10) return `0${num}`;
        return num;
      },
      styleLeftPadding(str) {
        if (!str) return false;
        const firstWord = str.trim().at(0);
        const position = str.indexOf(firstWord);
        return `padding-left: ${(position - 1) * 15}px;`;
      },
      onToolbarPreparing(e) {
        this.toolbarItems.push({
          widget: 'dxTemplate',
          location: 'before',
          template: 'filteringBox',
        });

        this.toolbarItems.push({
          widget: 'dxButton',
          options: {
            text: '엑셀',
            showText: 'always',
            elementAttr: { id: 'excelButton', class: 'btn_XS green light_filled excel' },
            width: 60,
            height: 30,
            onClick: () => {
              this.onClickExcelDownload();
            },
          },
          location: 'before',
        });

        this.toolbarItems.push({
          widget: 'dxButton',
          options: {
            text: '일괄',
            showText: 'always',
            elementAttr: { id: 'multiple-excel-btn', class: 'btn_XS white light_filled excel' },
            width: 60,
            height: 30,
            onClick: () => {
              this.onClickMultiSheetExcelDownload(2);
            },
          },
          location: 'before',
        });

        this.toolbarItems.push({
          widget: 'dxButton',
          options: {
            text: '대상',
            showText: 'group',
            elementAttr: { id: 'group-excel-btn', class: 'btn_XS white light_filled excel' },
            width: 60,
            height: 30,
            onClick: () => {
              this.onClickMultiSheetExcelDownload(3);
            },
          },
          location: 'before',
        });

        // 검색결과
        this.toolbarItems.push({
          widget: 'dxTemplate',
          location: 'after',
          template: 'totalCount',
        });

        e.toolbarOptions.items = this.toolbarItems;
      },
      movePagingBox() {
        const pager = this.$refs[this.gridRefName].instance.getView('pagerView').element().dxPager('instance').element();
        const toolbarAfterItem = this.$refs[this.gridRefName].instance.getView('headerPanel')._toolbar._$afterSection[0];
        const toolbarItem = document.createElement('div');
        toolbarItem.classList.add('dx-item');
        toolbarItem.classList.add('dx-toolbar-item');
        toolbarItem.classList.add('dx-toolbar-button');
        const toolbarItemContent = document.createElement('div');
        toolbarItemContent.classList.add('dx-item-content');
        toolbarItemContent.classList.add('dx-toolbar-item-content');
        toolbarItemContent.appendChild(pager);
        toolbarItem.appendChild(toolbarItemContent);
        toolbarAfterItem.appendChild(toolbarItem);
      },
      onChangedGridFilter(e) {
        this.filter.selectedKeys = e.selectedRowKeys.map(v => v.value);
        this.onFilterSearch();
      },
      onChangedDropDownFilter(e) {
        const keys = e.value;
        let result = [];
        if (![undefined, null].includes(keys)) {
          result = this.filter.options.filter(v => keys.includes(v.value));
        }
        this.filter.selectedRowsData = result;
      },
      onFilterSearch() {
        if (this.reportDatas.load) this.grid.refresh();
      },
      onClickExcelDownload() {
        this.$emit('onSearchReport', 1);
      },
      onClickMultiSheetExcelDownload(typeNumber) {
        this.$emit('onSearchReport', typeNumber);
      },
      /** 이력 팝업 여닫는 이벤트 */
      handleClickToggleHistoryModal(data) {
        const dataField = data.column.name;
        const column = this.columnsDesc.find(v => v.dataField === dataField);
        const rowData = cloneObj(data.data);
        this.$emit('onClickLinkInGrid', rowData, column.linkNm);
      },
      /** 컬럼 내 format: api 인 경우 클릭 이벤트 발생시 동작 */
      async openApiDataPopup(data) {
        const targetField = data.column.name;
        const column = this.columnsDesc.find(v => v.dataField === targetField);

        // format: api 인 경우 필수 태그
        const paramMap = column.param;
        const actionNm = column.actionNm;
        const payload = { actionname: actionNm, loading: true };
        try {
          payload.data = this.getParamByDataAndJson(data.data, JSON.parse(paramMap));
        } catch (e) {
          console.error('<param> 태그에 JSON 형태로 값을 넣어주시기 바랍니다.', paramMap);
          return this.$_Msg('요청 중 문제가 발생하였습니다.');
        }

        const res = await this.CALL_REPORT_API(payload);
        if (isSuccess(res)) {
          this.$emit('onClickApiDataInGrid', data.column.caption, cloneObj(data.data), getResData(res).at(0)?.log);
          return;
        }
        this.$_Msg('API 요청 중 문제가 발생하였습니다.');
      },

      /**
       * JSON 데이터 payload.data 에 사용할 파라미터 생성
       * @data: 보고서 결과 로우 데이터
       * @jsonData: <param> 태그 내 JSON 데이터
       * */
      getParamByDataAndJson(data, jsonData) {
        const params = {};
        const entries = Object.entries(jsonData);
        entries.forEach(([key, value]) => (params[key] = data[value]));
        return params;
      },
      getInitFilterKeys() {
        if (this.reportInfo && this.getReportLocalStorage) {
          const optionData = this.getReportLocalStorage();
          if (optionData) {
            return JSON.parse(optionData).filter;
          }
        }
        return this.filter.selectedRowsData.map(v => v.value);
      },
      setDataGridScrollMode() {
        this.dataGrid.scrollMode = this.getPageData('scrollMode') || 'virtual';
      },
      toggleHideMultiSheetBtn() {
        let toggleFunc = 'hide';
        const multiIds = this.getPageData('multiDownload');
        if (multiIds && multiIds.length > 0) toggleFunc = 'show';
        $('#multiple-excel-btn')[toggleFunc]();
      },
      toggleHideGroupSheetBtn() {
        let toggleFunc = 'hide';
        const useGroupDownload = this.getPageData('groupDownload');
        if (isTrue(useGroupDownload)) toggleFunc = 'show';
        $('#group-excel-btn')[toggleFunc]();
      },
      updateGridSize() {
        const fullSize = '100%';
        if (this.columnsDesc.length === 0) return fullSize;
        const size = this.columnsDesc.reduce((acc, v) => acc + Number(v.width), 0);
        this.gridWidth = size > window.innerWidth - 200 ? fullSize : size;
      },
      setTotalCount(reportResult) {
        if (reportResult.length > 0) {
          this.totalCount = reportResult[0].PAGING_TOT_COUNT || reportResult.length;
        } else {
          this.totalCount = 0;
        }
      },
      getPageData(inputKey) {
        try {
          const vm = this;
          const pageData = new Function(`return ${vm.pageData?.trim()}`)();
          return pageData?.[inputKey];
        } catch (e) {
          this.$_Msg(`XML 파일 내 <pagedata> 값을 확인해주시기 바랍니다.<br>${e}`);
        }
      },
      openNewLinkPage(data) {
        const dataField = data.column.name;
        let link = this.columnsDesc.find(v => v.dataField === dataField)?.link;
        if (link === undefined) return this.$_Msg('링크 설정을 확인해주시기 바랍니다.');
        Object.entries(data.data).forEach(([key, value]) => {
          const replaceKey = `#${key}#`;
          link = link.replace(replaceKey, value);
        });
        window.open(link.trim(), '', 'width=600, height=600');
      },
    },
    async mounted() {
      await this.init();
      this.updateGridSize();
      window.addEventListener('resize', this.updateGridSize);
    },
    updated() {},
    beforeDestroy() {
      window.removeEventListener('resize', this.updateGridSize);
    },
    destroyed() {
      // 보고서 변수 초기화
      this.reportDatas = null;
    },
  };
</script>
<style scoped>
  .mr-10 {
    margin-right: 10px;
  }
  .border-top-1px {
    border-top: 1px solid #ebebeb;
  }
  .report-grid-border {
    max-width: 100%;
  }
</style>

<style>
  .dx-header-row td {
    text-align: center !important;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    background-color: #f9f9f9;
  }

  .report-grid-border .dx-datagrid-headers {
    border-top: 1px solid #999;
    border-bottom: 1px solid #e0e0e0;
  }
  .report-grid-border .dx-datagrid-headers tr td {
    border-left: 1px solid #e0e0e0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .report-grid-border .dx-datagrid-headers tr td .dx-datagrid-text-content {
    font-size: 11px !important;
  }

  .report-grid-border .dx-datagrid-content tr td,
  .report-grid-border .dx-datagrid-content tr td a,
  .report-grid-border .dx-datagrid-content tr td span {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 11px !important;
  }

  .report-grid-border .dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
    background-color: #f9f9f9;
  }

  #excelButton .dx-button-text {
    color: white;
  }
  .dx-button.green.light_filled {
    background: #1d6b40;
  }
</style>
