import iconv from 'iconv-lite';
import moment from 'moment';
import SHA256 from 'crypto-js/sha256';
import {getAesUtil} from '@/plugins/aes-util';

/**
 * 패스워드 암호화 함수
 * @param id
 * @param pwd
 * @param type
 * @return {string}
 */
const encryptPassword = (id, pwd, type = 'SHA256') => {
  if (type === 'AES256') {
    const aesUtil = getAesUtil();
    return aesUtil.encrypt(pwd);
  } else {
    return SHA256(pwd + id).toString();
  }
}

/**
 * @description : 메뉴 리스트를 받아와서 TreeData 객체로 변환하는 함수
 *                사용 페이지 : /layouts/LeftMenu.vue
 * @param {*} items
 * @param {*} id
 * @param {*} link
 * @returns items
 */
const makeTreeData = (items, id = null, link = 'parentId') => {
  return items.filter(item => item[link] === id).map(item => ({...item, children: makeTreeData(items, item.id)}));
}

/**
 * @description: 부모 id 찾는 메서드
 *
 * @param {*} array
 * @param {*} parentId
 * @param {*} parentKey default parentId
 * @returns idArr
 */
const findParentId = (array, parentId, parentKey = 'parentId') => {
  let idArr = [];
  array.forEach(d => {
    if (d.id === parentId && d[parentKey]) {
      idArr.push(d[parentKey]);
      idArr = idArr.concat(findParentId(array, d[parentKey]));
    }
  });
  return idArr;
}

/** @description: treeList node에서 id로 하위 데이터 id array 찾기 */
const findChildrenIdsById = (arr, id, key = 'id') => {
  return findId(arr, id);

  function findId(arr, id) {
    let output = {};
    arr.forEach(o => {
      if (o.data[key] === id) {
        output[key] = o.data[key];
        output.childIds = (o.children && getArrayOfChildren(o.children, [], (key = 'id'))) || [];
      } else if (o.children && o.children.length) {
        output = findId(o.children, o.data[key]);
      }
    });

    return output;
  }

  function getArrayOfChildren(arr, existingChildren, key) {
    for (const o of arr) {
      existingChildren.push(o.data[key]);
      o.children && getArrayOfChildren(o.children, existingChildren);
    }

    return existingChildren;
  }
}

/**
 * @description: 트리 구조에서 마지막 뎁스(레벨)의 children 데이터들을 array로 담아 가져오는 메서드
 *
 * @param {*} treeDatas : children Object가 있는 트리 구조의 array
 *                         makeTreeData의 메서드를 이용하여 하위 children object가 있는 구조로 변경 가능
 * @param {*} childDatas : 마지막 뎁스(레벨)의 children 데이터를 담는 array
 */
const getAllLastChildrenDatas = (treeDatas, childDatas) => {
  treeDatas.forEach(d => {
    if (d.children.length > 0) {
      getAllLastChildrenDatas(d.children, childDatas);
    } else {
      childDatas.push(d);
    }
  });
  return childDatas;
}

/**
 * @description: Array Object key를 기준으로 sorting하는 함수
 *               ex) setSortingObj(objArr, { id: 'asc', name: 'desc' });
 * @param {*} objArr
 * @param {*} keys
 * @returns objArr
 */
const setSortingObjArr = (objArr, keys) => {
  keys = keys || {};
  // via
  // https://stackoverflow.com/questions/5223/length-of-javascript-object-ie-associative-array
  const obLen = obj => {
    let size = 0,
      key;
    for (key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        size++;
      }
    }
    return size;
  };

  // avoiding using Object.keys because I guess did it have IE8 issues?
  // else var obIx = function(obj, ix){ return Object.keys(obj)[ix]; } or
  // whatever
  const obIx = (obj, ix) => {
    let size = 0,
      key;
    //let size = 0;
    for (key in obj) {
      //Object.keys(obj).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (size === ix) {
          return key;
        }
        size++;
      }
    }

    return false;
  };

  const keySort = (a, b, d) => {
    d = d !== null ? d : 1;
    if (a === b) {
      return 0;
    }

    return a > b ? 1 * d : -1 * d;
  };

  const KL = obLen(keys);

  if (!KL) {
    return objArr.sort(keySort);
  }

  for (let k in keys) {
    // asc unless desc or skip
    keys[k] = keys[k] === 'desc' || keys[k] === -1 ? -1 : keys[k] === 'skip' || keys[k] === 0 ? 0 : 1;
  }

  objArr.sort((a, b) => {
    let sorted = 0,
      ix = 0;

    while (sorted === 0 && ix < KL) {
      let k = obIx(keys, ix);
      if (k) {
        let dir = keys[k];
        sorted = keySort(a[k], b[k], dir);
        ix++;
      }
    }

    return sorted;
  });

  return objArr;
}

/**
 * @description : axios 를 이용하여 파일을 다운도르할 때 사용하는 함수
 * @param {*} response
 */
const downloadFile = (response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  const contentDisposition = response.headers['content-disposition'];
  let fileName = 'unknown';
  if (contentDisposition) {
    const [fileNameMatch] = contentDisposition.split(';').filter(str => str.includes('filename'));
    if (fileNameMatch) [, fileName] = fileNameMatch.split('=');
  }
  const decodedFilename = iconv.decode(fileName, 'utf-8');
  link.href = url;
  link.setAttribute('download', `${decodedFilename}`);
  link.style.cssText = 'display:none';
  document.body.appendChild(link);
  link.click();
  link.remove();
}

/**
 * @description : Date 타입인 date를 fromFormat 형식에서 toFormat 형식으로 변환하는 함수
 * @param {*} date
 * @param {*} fromFormat
 * @param {*} toFormat
 * @returns formatDate
 */
const formatDate = (date, fromFormat, toFormat = null) => {
  let formatDate;
  if (toFormat !== null) {
    formatDate = moment(date, fromFormat).format(toFormat);
  } else {
    formatDate = moment(date, fromFormat); //.format(fromFormat);
  }
  return formatDate;
}

/**
 * @description : 날짜 포맷 변환 함수
 * @param date
 * @param toFormat
 * @return {moment.Moment|string}
 */
const convertDateFormat = (date, toFormat) => {
  if (!date || !toFormat) {
    return '';
  }
  return moment(date).format(toFormat);
}

/**
 * @description : 시작시간/종료시간을 배열로 받아 겹치는 시간이 있는지 체크하는 함수
 *                ex) parameter : [ ['12:00:00', '13:00:00'], ['13:00:00', '14:00:00'], ['14:00:00', '15:00:00'] ]
 * @param {*} timeSegments
 * @returns 시간이 겹치면 true, 아니면 false
 */
const checkTimeOverlap = (timeSegments) => {
  if (timeSegments.length === 1) return false;

  timeSegments.sort((timeSegment1, timeSegment2) => {
    return timeSegment1[0].localeCompare(timeSegment2[0]);
  });

  for (let i = 0; i < timeSegments.length; i++) {
    if (timeSegments[i][0] === timeSegments[i][1]) {
      return true;
    }

    if (i !== timeSegments.length - 1) {
      const currentEndTime = timeSegments[i][1];
      const nextStartTime = timeSegments[i + 1][0];
      if (currentEndTime > nextStartTime) {
        return true;
      }
    }
  }

  return false;
}

/**
 * @description : Object를 깊은 복사해주는 함수
 */
const cloneObj = (obj) => {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * @description : Map을 깊은 복사해주는 함수
 * @param map
 * @returns {any}
 */
const cloneMap = (map) => {
  //deep map
  return JSON.parse(JSON.stringify(Array.from(map)));
}

/**
 * @description : 공백 제거나 빈값을 null로 변환해주는 함수
 */
const setTrimOrEmptyToNull = (value) => {
  return !isEmpty(value) ? (value.toString().trim().length > 0 ? value.toString().trim() : null) : null;
}

/***
 @description : 빈 값인지 체크해주는 함수 : [ empty Object or Array, undefined, null, '', 0 ]
 @param {*} data
 @returns : 빈 값이면 true, 아니면 false 리턴
 ***/
const isEmpty = (data) => {
  if (!data) {
    return true;
  } else {
    if (data.constructor === Object && Object.keys(data).length === 0) {
      return true;
    }
    return data.constructor === Array && data.length === 0;
  }
}

/**
 * @description : promise를 순차적으로 처리해주는 함수
 * @param {*} promiseItems
 */
const setPromiseSequential  = async (promiseItems) => {
  //promise-sequential
  await promiseItems.reduce((prevPromise, item) => {
    return prevPromise.then(() => item);
  }, Promise.resolve());
}

/**
 * @description: 기존 DB 데이터와 변경된 데이터를 비교하여
 추가/수정/삭제 된 데이터를 insert/update/remove로 나누어 리턴해주는 함수
 (devExpress grid의 배치모드 e.changes와 최대한 맞춤)
 * @param {*} originData 기존 데이터
 * @param {*} changeData 변경된 데이터
 * @param {*} uniqueKey  pk 컬럼명이 id가 아닌 다른 이름일 수도 있기 때문에 추가
 * @returns changedData: [{ type: insert, ... }, { type: update, ... }, { type: remove, ... }]
 */
const checkChangedData = (originData, changeData, uniqueKey = 'id') => {
  let changedData = [];
  //type: update(기존 db 데이터와 비교하여 값이 변경됐으면 push)
  originData.forEach(d1 => {
    changeData.forEach(d2 => {
      if (d1[uniqueKey] === d2[uniqueKey]) {
        if (Object.entries(d1).toString() !== Object.entries(d2).toString()) {
          d2.type = 'update';
          changedData.push(d2);
        }
      }
    });
  });

  //type: insert(id 값을 체크하여 없으면 push)
  changeData.forEach(d1 => {
    if (typeof d1[uniqueKey] === 'undefined') {
      d1.type = 'insert';
      changedData.push(d1);
    }
  });

  //type: remove(기존 db 데이터와 비교하여 삭제된 데이터 push)
  let removeData = originData
    .filter(d1 => !changeData.some(d2 => d1[uniqueKey] === d2[uniqueKey]))
    .map(d => {
      d.type = 'remove';
      return {...d};
    });
  changedData = [...changedData, ...removeData];

  return changedData;
}

/**
 * @description : array 순서 변경 함수
 * @param {*} list
 * @param {*} fromIndex
 * @param {*} toIndex
 * @returns list
 */
const setChangeOrder = (list, fromIndex, toIndex) => {
  let minIndx = 0;
  let maxIndx = list.length - 1;

  if (maxIndx < toIndex) {
    return;
  }
  if (toIndex < minIndx) {
    return;
  }

  list.splice(toIndex, 0, list.splice(fromIndex, 1)[0]);
}

/**
 * @description : 날짜 계산(년,월,일 더하기/빼기) : setCalculateDate(day, '2021-10-28', 5)
 * @param {*} type
 * @param {*} date
 * @param {*} value
 * @returns date
 */
const setCalculateDate = (type, date, value) => {
  date = new Date(date);
  switch (type) {
    case 'year':
      date.setFullYear(date.getFullYear() + value);
      break;
    case 'month':
      date.setMonth(date.getMonth() + value);
      break;
    case 'day':
      date.setDate(date.getDate() + value);
      break;
  }
  return date;
}

/**
 * @description : 숫자만 허용하는 메서드
 * @param {*} str
 * @returns true / false
 */
const checkOnlyNumber = (str) => {
  const regType = getRegexPattern('checkNumber');
  return !regType.test(str);
}

/** @description: 정규식 패턴 관리 메서드
 *  @param {*} regexType
 * @returns 정규식 패턴 리턴
 */
const getRegexPattern = (regexType) => {
  let regex = '';
  switch (regexType) {
    case 'checkHangul': //한글 체크
      regex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
      break;
    case 'checkEngAndNumber': //영문 & 숫자 체크
      regex = /^[A-Za-z0-9+]*$/;
      break;
    case 'checkNumber': //숫자 체크
      regex = /[^0-9]/g;
      break;
    case 'checkEmpty': //빈 값 체크
      regex = /^\s+|\s+$/g;
      break;
    case 'checkComma': //콤마 체크
      regex = /,/g;
      break;
    default:
      break;
  }

  return regex;
}

/**
 * @description : API 결과 성공/실패 반환
 *                사용 페이지 : 전체
 * @param res
 * @returns true || false
 */
const isSuccess = (res) => {
  try {
    return res.status === 200 && res.data.header.resCode === 'success';
  } catch (e) {
    return false;
  }
}

/**
 * @description : 그리드 멀티 선택모드 일 경우 단일 선택 색상 설정
 */
const setGridSingleSelection = (e) => {
  /** 단일 선택 색상 설정 */
  let allSingleSelectionElement = [...e.element.getElementsByClassName('dx-single-selection')];
  //single-selection 초기화
  allSingleSelectionElement?.forEach(rowElement => {
    rowElement.classList.remove('dx-single-selection');
  });
  //단일선택 색상 설정 class 추가
  e.rowElement.classList.add('dx-single-selection');
}

/**
 * boolean true인지 체크
 * @param value
 * @returns {boolean}
 */
const isTrue = (value) => {
  if (typeof value === 'boolean') return value;
  if (typeof value === 'undefined') return false;
  return value.toLowerCase() === 'true';
}

/**
 * Object의 해당 key 삭제해서 리턴
 * @param object
 * @param deletedKey
 * @returns {{}}
 */
const deleteObjectKey = (object, deletedKey) => {
  return Object.keys(object)
    .filter(key => key !== deletedKey)
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
}

/**
 * @description 오늘 날짜 기준으로 이전 날짜 가져오는 함수
 * @param {number} number 뺄 숫자
 * @param {string} unit 'years'|'months'|'weeks'|'days'|'hours'|'minutes'|'seconds'|'milliseconds'
 * @param {string} format 포멧 형식 (기본값 : YYYYMMDD)
 * @returns {string}
 */
const getPastFromToday = (number, unit, format = 'YYYYMMDD') => {
  return moment()
    .subtract(number, unit)
    .format(format);
}

/**
 * @description : ObjectArray의 특정키 값을 체크하여 중복 값 제거 후 리턴하는 함수
 * @param objArray
 * @param {string} checkKey  체크 할 특정 키
 * @returns {array}
 */
const uniqueObjectDatas = (objArray, checkKey) => {
  return objArray.filter((item, index, self) => {
    return index === self.findIndex(t => t[checkKey] === item[checkKey]);
  });
}

/**
 * API 결과 데이터 가져오기
 * @param resData
 * @return {*}
 */
const getResData = (resData) => {
  return resData?.data?.data;
}

/**
 * @description : startTime, endTime 사이의 경과시간을 계산 하는 함수
 * @param {date} startTime 시작일시 (포맷 : YYYY-MM-DDTHH:mm:SS)
 * @param {date} endTime 종료일시 (포맷 : YYYY-MM-DDTHH:mm:SS)
 * @param {string} delimiter 경과시분초별 구분자
 * @param {number} formatDigitNum 시분초 포맷 (기본값: 00)
 * @returns {string or object{hours,minutes,seconds}}
 */
const calculateElapsedTime = (startTime, endTime, delimiter, formatDigitNum = 2) => {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  const timeDifference = endDate - startDate;

  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  const formatTwoDigits = (number) => String(number).padStart(formatDigitNum, '0');

  if (delimiter) {
    return `${formatTwoDigits(hours)}${delimiter}${formatTwoDigits(minutes)}${delimiter}${formatTwoDigits(seconds)}`;
  } else {
    return {
      hours: formatTwoDigits(hours),
      minutes: formatTwoDigits(minutes),
      seconds: formatTwoDigits(seconds)
    };
  }
}

/**
 * 숫자 두자리 수 생성(10 이하 정수 앞에 0 붙이기)
 * @param str
 * @param len
 * @returns {*|string}
 */
const lPadZero = (str, len) => {
  str = str + '';
  return str.length >= len ? str : new Array(len - str.length + 1).join('0') + str;
}

/**
 * 문자열 Y,N 을 boolean 으로 변환
 *
 * @param value
 * @return {boolean}
 */
const parseYnToBoolean = (value) => {
  return value === 'Y';
}

/**
 * 문자열을 CamelCase로 변환
 *
 * @param str
 * @return {string}
 */
const toCamelCase = (str) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export {
  calculateElapsedTime,
  checkChangedData,
  checkOnlyNumber,
  checkTimeOverlap,
  cloneMap,
  cloneObj,
  deleteObjectKey,
  downloadFile,
  encryptPassword,
  findChildrenIdsById,
  findParentId,
  formatDate,
  getAllLastChildrenDatas,
  getPastFromToday,
  getRegexPattern,
  getResData,
  isEmpty,
  isSuccess,
  isTrue,
  lPadZero,
  makeTreeData,
  moment,
  setCalculateDate,
  setChangeOrder,
  setGridSingleSelection,
  setPromiseSequential,
  setSortingObjArr,
  setTrimOrEmptyToNull,
  uniqueObjectDatas,
  parseYnToBoolean,
  toCamelCase,
  convertDateFormat
};
