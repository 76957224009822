<!--
  PACKAGE_NAME : src/components/euc
  FILE_NAME : modal-automation.vue
  AUTHOR : jhcho
  DATE : 24. 7. 5.
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" :tabType="2" @selectedIndex="tabSelectedIndex" v-show="automationList.length > 0">
      <Tab :title="item.automationCode" v-for="(item, index) in automationList" :key="index">
        <div v-show="tabIndex === 0">
          <ChangeType
              ref="changeTypeRef"
              :request-item="requestItem"
          />
        </div>
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import { isSuccess } from "@/plugins/common-lib";
import Tabs from "@/components/common/tabs.vue";
import Tab from "@/components/common/tab.vue";
import ChangeType from "@/components/euc/automation/dn-change-type.vue";

export default {
  components: {
    Tabs,
    Tab,
    ChangeType
  },
  props: {
    modalData: {
      type: Object,
      default: () => {
        return {
          data: {
            userNo: '',
          }
        }
      }
    }
  },
  data() {
    return {
      tabIndex: 0,
      automationList: [],
      originData: null,
      requestItem: {}
    }
  },
  methods: {
    getModalData() {
      return this.$refs.changeTypeRef.changeData;
    },
    tabSelectedIndex(index) {
      this.tabIndex = index;
    },
    async selectAutomationList() {
      const payload = {
        actionname: 'EUC_REQUEST_AUTOMATION_SELECT',
        data: {
          detailId: this.modalData.data.detailId,
          sort: '+automationOrd'
        },
        loading: false
      }
      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        this.automationList = res.data.data;
      }
    },
  },
  mounted() {
    this.requestItem = this.modalData.data;
  },
  created() {
    this.selectAutomationList();
  }
}
</script>
<style scoped>

</style>