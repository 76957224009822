<!--
  PACKAGE_NAME : src\components\devextreme
  FILE_NAME : esp-dx-modal-popup
  AUTHOR : devyoon91
  DATE : 2024-09-20
  DESCRIPTION :
-->
<template>
	<DxPopup
		:visible="isOpen"
		:show-title="true"
		:title="propOption.title"
		:min-width="propOption.minWidth"
		:width="propOption.width"
		:min-height="propOption.minHeight"
		:height="propOption.height"
		:resize-enabled="true"
		:drag-enabled="true"
		:show-close-button="true"
		:close-on-outside-click="false"
		@hiding="closeModal"
	>
		<DxToolbarItem
			widget="dxButton"
			toolbar="bottom"
			location="center"
			:visible="propOption.useSaveBtn"
			:options="{
				elementAttr: {
					class: 'default filled txt_S medium',
				},
				text: propOption.saveBtnTxt,
				width: '120',
				height: '40',
				useSubmitBehavior: true,
				onClick: () => saveModal(),
			}"
		/>
		<DxToolbarItem
			widget="dxButton"
			toolbar="bottom"
			location="center"
			:visible="propOption.useCancelBtn"
			:options="{
				elementAttr: {
					class: 'white filled txt_XS medium',
				},
				text: propOption.cancelBtnTxt,
				width: '120',
				height: '40',
				onClick: () => closeModal(),
			}"
		/>

		<!-- SLOT -->
		<slot name="content"></slot>
	</DxPopup>
</template>
<script>
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';

export default {
	components: {
		DxPopup,
		DxToolbarItem,
	},
	props: {
		option: {
			default: () => this.defaultOption,
			type: Object,
		},
		isOpen: {
			default: false,
			type: Boolean,
		},
	},
	watch: {
		isOpen: {
			handler: function (isOpen) {
				if (isOpen) this.propOption = { ...this.defaultOption, ...this.option };
			},
			deep: true,
		},
	},
	data() {
		return {
			propOption: this.option,
			defaultOption: {
				title: '팝업 타이틀',
				width: '80%',
				height: '85%',
				minWidth: null,
				minHeight: null,
				useSaveBtn: true,
				useCancelBtn: true,
				saveBtnTxt: '저장',
				cancelBtnTxt: '취소',
			},
		};
	},
	computed: {},
	methods: {
		saveModal(e) {
			this.$emit('saveModal');
		},
		closeModal() {
			this.$emit('closeModal');
		},
	},
	created() {
		this.propOption = { ...this.defaultOption, ...this.option };
	},
	mounted() {},
};
</script>
