import Vue from 'vue'
import VueI18n from 'vue-i18n'

import config from '../configs'

const { locale, availableLocales, fallbackLocale } = config.locales

Vue.use(VueI18n)

const messages = {}

availableLocales.forEach((l) => { messages[l.code] = l.messages })

export const i18n = new VueI18n({
  locale,
  fallbackLocale,
  messages
})

i18n.locales = availableLocales

// Vue 프로토타입에 i18n 추가
Vue.prototype.$_i18n = i18n;

export default i18n
