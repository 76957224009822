var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"fl per50"},[_c('table',{staticClass:"table_form line-bin"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('tbody',[_c('tr',[_vm._m(3),_c('td',[_vm._v(" "+_vm._s(_vm.originData.userNo)+" ")])]),_c('tr',[_vm._m(4),_c('td',[_vm._v(" "+_vm._s(_vm.originData.userNm)+" ")])]),_c('tr',[_vm._m(5),_c('td',[_vm._v(" "+_vm._s(_vm.originData.gradeNm)+" ")])]),_c('tr',[_vm._m(6),_c('td',[_vm._v(" "+_vm._s(_vm.originData.deptNm)+" ")])]),_c('tr',[_vm._m(7),_c('td',[_vm._v(" "+_vm._s(_vm.originData.abbrNm)+" ")])]),_c('tr',[_vm._m(8),_c('td',[_vm._v(" "+_vm._s(_vm.originData.dn)+" ")])])])])]),_c('div',{staticClass:"fr per50"},[_c('table',{staticClass:"table_form line-bin"},[_vm._m(9),_vm._m(10),_vm._m(11),_c('tbody',[_c('tr',[_vm._m(12),_c('td',[_vm._v(" "+_vm._s(_vm.originData.userNo)+" ")])]),_c('tr',[_vm._m(13),_c('td',[_c('DxTextBox',{staticClass:"mar_ri10",attrs:{"placeholder":"이름","styling-mode":_vm.stylingMode},model:{value:(_vm.changeData.userNm),callback:function ($$v) {_vm.$set(_vm.changeData, "userNm", $$v)},expression:"changeData.userNm"}})],1)]),_c('tr',[_vm._m(14),_c('td',[_vm._v(" "+_vm._s(_vm.originData.gradeNm)+" ")])]),_c('tr',[_vm._m(15),_c('td',[_vm._v(" "+_vm._s(_vm.originData.deptNm)+" ")])]),_c('tr',[_vm._m(16),_c('td',[_vm._v(" "+_vm._s(_vm.originData.abbrNm)+" ")])]),_c('tr',[_vm._m(17),_c('td',[_c('DxTextBox',{staticClass:"mar_ri10",attrs:{"placeholder":"내선번호","styling-mode":_vm.stylingMode},model:{value:(_vm.changeData.phoneNumber),callback:function ($$v) {_vm.$set(_vm.changeData, "phoneNumber", $$v)},expression:"changeData.phoneNumber"}})],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('caption',[_c('strong',[_vm._v("변경 전")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{staticStyle:{"width":"100px"}}),_c('col',{staticStyle:{"width":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"sub_title_txt"},[_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('h2',[_vm._v("변경 전")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label1"}},[_vm._v("사번")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label1"}},[_vm._v("이름")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label1"}},[_vm._v("직위")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label1"}},[_vm._v("부서")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label1"}},[_vm._v("부서약어")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label2"}},[_vm._v("내선번호")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('caption',[_c('strong',[_vm._v("변경 후")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{staticStyle:{"width":"100px"}}),_c('col',{staticStyle:{"width":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"sub_title_txt"},[_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('h2',[_vm._v("변경 후")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label1"}},[_vm._v("사번")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label1"}},[_vm._v("이름")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label1"}},[_vm._v("직위")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label1"}},[_vm._v("부서")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label1"}},[_vm._v("부서약어")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label2"}},[_vm._v("내선번호")])])
}]

export { render, staticRenderFns }