<!--
  PACKAGE_NAME : src/components/euc
  FILE_NAME : label-template.vue
  AUTHOR : jhcho
  DATE : 2024-05-14
  DESCRIPTION :
-->
<template>
  <div>
    <div>
      <i v-if="icon" :class="`dx-icon dx-icon-${icon}`"/>{{ data.text }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        text: '',
      })
    },
    icon: {
      type: String,
    }
  },
}
</script>
<style scoped>

</style>