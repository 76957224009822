/**
 * EnumMap
 * @type {{group_label: StringConstructor, label: StringConstructor, value: StringConstructor, key: number, group: StringConstructor}}
 */
export const EnumMap = {
  key: Number | String, value: String, label: String, group: String, group_label: String,
}

/**
 * EnumEntry
 */
export class EnumEntry {
  values = [];
}

export default {
  EnumMap,
  EnumEntry,
}