import mixin from './mixin';
import * as commonLib from './common-lib';
import { EventBus } from '@/event-bus';
import enums from '@/configs/enums';
import theme from '@/configs/theme';
import EspDxFileUploader from '@/components/devextreme/esp-dx-file-uploader.vue';

export default {
    // 전역설정 변경 후 재기동 필요
    install(Vue) {
      // 인스턴스가 이미 설치되어 있는지 확인
      if (this.installed) {
        return;
      }

      this.installed = true;

      Vue.prototype.$_commonlib = commonLib; // commonLib 추가
      Vue.prototype.$_eventbus = EventBus; // EventBus 추가
      Vue.prototype.$_enums = enums; // enums 추가
      Vue.prototype.$_theme = theme; // theme 추가
      Vue.mixin(mixin); // mixin 추가
      Vue.prototype.$_UploaderClass = Vue.extend(EspDxFileUploader); // ESP 파일 업로더 전역 컴포넌트 등록
    }
}