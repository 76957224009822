import { createAxiosInstance, createCallApi } from '@/api/creator';

// baseURL 전달 받아서 서버 인스턴스 생성
const serverInstance = createAxiosInstance(process.env?.VUE_APP_EUC_SERVER_URL ? process.env.VUE_APP_EUC_SERVER_URL : '');

/**
 * config 생성
 * @param payload
 * @return {{}|{headers, responseType}}
 */
const makeConfig = payload => {
	if (payload.method !== 'POST') {
		return {};
	}

	return {
		responseType: payload.responseType,
		headers: payload.headers,
	};
};

export const ucApi = {
	serverInstance,
	callApi: payload => createCallApi(payload, serverInstance, makeConfig(payload)),
};
