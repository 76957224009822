var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-search"},[_c('DxPopover',{attrs:{"visible":_vm.condition.isShow,"show-event":"click","target":"#popoverSearchConditionBtn","width":350,"position":{ my: 'bottom', offset: '-125 -22' },"onShowing":_vm.onShowingSearchCondition},on:{"update:visible":function($event){return _vm.$set(_vm.condition, "isShow", $event)}}},[_c('div',{staticClass:"p-8"},[_c('DxButton',{staticClass:"btn_XS default filled add",staticStyle:{"margin":"0","display":"block !important"},attrs:{"text":"검색조건 추가"},on:{"click":function($event){return _vm.toggleAddModal(true)}}})],1),_c('div',{staticStyle:{"height":"300px"}},[_c('DxDataGrid',{attrs:{"id":"search-condition-grid","key-expr":"id","height":300,"data-source":_vm.condition.list,"show-borders":false,"show-column-lines":false,"show-column-headers":false,"show-row-lines":true,"column-auto-width":true,"hover-state-enabled":true,"no-data-text":"검색 조건이 존재하지 않습니다."},on:{"cell-prepared":_vm.onCellPreparedStylingGrid,"cell-click":_vm.onClickSearchCondition},scopedSlots:_vm._u([{key:"deleteTemplate",fn:function({ data }){return [_c('button',{staticClass:"btn-icon close",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleClickSearchConditionDelete(data)}}})]}}])},[_c('DxScrolling',{attrs:{"mode":"virtual"}}),_c('DxColumn',{attrs:{"data-field":"title"}}),_c('DxColumn',{attrs:{"width":50,"cell-template":"deleteTemplate"}})],1)],1)]),_c('DxPopup',{attrs:{"option":{
      title: '검색조건 추가',
      width: '480',
      height: '260',
      minWidth: null,
      minHeight: null,
    },"isOpen":_vm.addModal.isShow},on:{"closeModal":function($event){return _vm.toggleAddModal(false)},"saveModal":_vm.saveSearchCondition},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ModalContent',{ref:"add-modal-content"})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }