<template>
	<div :class="['tabs', tabType === 1 ? 'ecs-large-tab' : 'ecs-sub-tab']">
		<ul class="ecs-tab-box">
			<li
          v-for="(tab, index) in tabs"
          :class="['tb_tp', { on: index === selectedIndex }]"
          :key="tab.title"
          @click="selectTab(index)"
      >
				{{ tab.title }}
			</li>
		</ul>
		<div :class="[tabCssClass]">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		tabType: {
			type: Number,
			default: 1, // 1: 언더바 타입의 탭, 2: 라운드 탭
		},
    tabCssClass: {
      type: String,
      default: 'page-sub-box', // 탭 내용의 css class
    }
	},
	data() {
		return {
			selectedIndex: 0,
			tabs: [],
		};
	},
	methods: {
		selectTab(i) {
			this.selectedIndex = i;

			this.tabs.forEach((tab, index) => {
				tab.isActive = index === i;
			});

			this.$emit('selectedIndex', this.selectedIndex);
		},
	},
	created() {
		this.tabs = this.$children;
	},
	mounted() {},
};
</script>
