<!--
  PACKAGE_NAME : src/pages/euc/phone/request
  FILE_NAME : index.vue
  AUTHOR : jhcho
  DATE : 2024-05-13
  DESCRIPTION :
-->
<template>
  <div class="container">
    <div class="pt-6">
      <div class="flex justify-center items-center w-full p-4">
        <ConfirmUserBox
            :title="$_msgContents('UC.WORD.REQUESTER', { defaultValue: '신청자' })"
            :user-nm="requestData.userNm"
            :grade-nm="requestData.gradeNm"
            :dept-nm="requestData.deptNm"
            status="COMPLETE"
            process="REQUEST"
            :approver-user-no="requestData.approverUserNo"
        />
        <i class="dx-icon-arrowright justify-self-center content-center w-40 text-center" />
        <ConfirmUserBox
            :title="$_msgContents('UC.WORD.APPROVER', { defaultValue: '승인자' })"
            :user-nm="requestData.approverUserNm"
            :grade-nm="requestData.approverGradeNm"
            :dept-nm="requestData.approverDeptNm"
            :status="requestData.approverFl === 'Y' ? 'COMPLETE' : 'READY'"
            process="APPROVER"
            :approver-user-no="requestData.approverUserNo"
        />
        <template v-for="(item, index) in requestData.requestItems">
          <template>
            <i class="dx-icon-arrowright justify-self-center content-center w-40 text-center" :key="`icon_${index}`" />
            <ConfirmUserBox
                :key="`confirmUserBox_${index}`"
                :title="`${$_msgContents('UC.WORD.WORKER', { defaultValue: '작업자' })}`"
                :user-nm="item.workerUserNm"
                :grade-nm="item.workerGradeNm"
                :dept-nm="item.workerDeptNm"
                :worker-user-no="item.workerUserNo"
                :status="
                requestData.approverFl === 'Y' ?
                  hasNotWorkCompleted === false ? 'COMPLETE' : 'READY'
                  : 'HOLD'"
                process="WORK"
                :approver-user-no="requestData.approverUserNo"
            />
          </template>
        </template>
        <i class="dx-icon-arrowright justify-self-center content-center w-40 text-center" />
        <ConfirmUserBox
            :title="$_msgContents('UC.WORD.REQUESTER', { defaultValue: '신청자' })"
            :user-nm="requestData.userNm"
            :grade-nm="requestData.gradeNm"
            :dept-nm="requestData.deptNm"
            :status="requestData.approverFl === 'Y' && hasNotWorkCompleted === false ? 'COMPLETE' : 'HOLD'"
            process="REQUEST"
            :approver-user-no="requestData.approverUserNo"
        />
      </div>
    </div>
    <div class="p-6">
      <DxForm :form-data="requestData" >
        <DxGroupItem
            :caption="$_msgContents('UC.WORD.REQUESTER_INFO', { defaultValue: '신청자 정보' })"
            :col-count="4">
          <DxSimpleItem
              v-for="(item, index) in requestDataForm"
              :key="`${item.text}${index}`"
              :data-field="item.key"
              :col-span="item.colSpan"
              :editor-options="item.options"
              :template="item.template"
              :read-only="item.readOnly || false"
              css-class="shadow-none"
          >
            <DxLabel
                :template="`${item.key}Label`"
                :text="$_msgContents(item.i18n, { defaultValue: item.text })"
            />
            <DxRequiredRule
                v-if="item.required"
                :message="$_msgContents('COMMON.REQUIRED_VALUE', { defaultValue: '필수값입니다.' })"
            />
          </DxSimpleItem>
        </DxGroupItem>

        <!-- icon 생성을 위한 template -->
        <template #titleLabel="{ data }">
          <LabelTemplate :data="data" icon="edit" />
        </template>
        <template #dueDtLabel="{ data }">
          <LabelTemplate :data="data" icon="event" />
        </template>
        <template #userNoLabel="{ data }">
          <LabelTemplate :data="data" icon="card" />
        </template>
        <template #userNmLabel="{ data }">
          <LabelTemplate :data="data" icon="user" />
        </template>
        <template #tenantNmLabel="{ data }">
          <LabelTemplate :data="data" icon="product" />
        </template>
        <template #deptNmLabel="{ data }">
          <LabelTemplate :data="data" icon="group" />
        </template>
        <template #memoLabel="{ data }">
          <LabelTemplate :data="data" icon="comment" />
        </template>
        <template #approverUserLabel="{ data }">
          <LabelTemplate :data="data" icon="user" />
        </template>

        <!-- 결재자 선택 Modal -->
        <template #approverUserTemplate="{ data }">
          <div>
            <DxTextBox
                :placeholder="data.editorOptions.placeholder"
                :read-only="data.editorOptions.readOnly"
                :value="requestData.approverUser"
                :input-attr="{ 'aria-label': 'selectApproverUser', 'class': 'cursor-not-allowed' }"
            >
              <DxTextBoxButton
                  name="selectApproverUser"
                  :options="{ icon: 'plus', stylingMode: 'text' }"
                  location="before"
              />
            </DxTextBox>
          </div>
        </template>
      </DxForm>
    </div>
    <RequestItems
        v-if="requestData.requestItems.length > 0"
        ref="requestItemsRef"
        @showUserModal="showUserModal"
        :requestData="requestData"
        :is-edit-mode="false"
    />
    <section class="terms bottom-btn-box">
      <div class="page-sub-box">
        <div class="bottom-btn-wrap">
          <DxButton
              :text="$_msgContents('COMPONENTS.APPROVAL', { defaultValue: '승인' })"
              :width="120"
              :height="40"
              class="default filled txt_S medium"
              :use-submit-behavior="true"
              :onClick="() => onApprove()"
          />
          <DxButton
              :text="$_msgContents('COMPONENTS.CANCEL', { defaultValue: '취소' })"
              :width="120"
              :height="40"
              class="white filled txt_S medium"
              :onClick="() => goListPage()"
          />
        </div>
      </div>
    </section>

    <DxPopup
        v-model="modal.isOpened"
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :width="modal.initData ? modal.initData.width : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
              ref="modalRef"
              v-if="modal.sendData"
              :is="modal.currentComponent"
              :modalData="modal.sendData"
              :isModal="modal.isModal"
          />
        </div>
      </template>

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: $_msgContents('COMPONENTS.SELECT', { defaultValue: '선택' }),
						width: '120',
						height: '40',
						onClick: () => onSelectedUser()
					}"
      />

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: $_msgContents('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
						width: '120',
						height: '40',
						onClick: () => isOpenModal(false)
					}"
      />
    </DxPopup>
  </div>
</template>
<script>
import {
  DxForm,
  DxGroupItem,
  DxSimpleItem,
  DxLabel,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxButton as DxTextBoxButton } from 'devextreme-vue/number-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxButton } from 'devextreme-vue/button';
import LabelTemplate from '@/components/euc/label-template.vue';
import RequestItems from '@/pages/euc/phone/request/items/index.vue';
import ModalUserSelect from '@/components/euc/modal-user-select.vue';
import ConfirmUserBox from "@/components/euc/confirm-user-box.vue";
import { isSuccess } from '@/plugins/common-lib';

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxLabel,
    DxRequiredRule,
    DxPopup,
    DxToolbarItem,
    DxTextBox,
    DxTextBoxButton,
    DxSelectBox,
    DxButton,
    LabelTemplate,
    ModalUserSelect,
    RequestItems,
    ConfirmUserBox
  },
  data() {
    return {
      hasNotWorkCompleted: true,
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      requestDataForm: [
        {
          key: 'title',
          text: '제목',
          i18n: 'COMPONENTS.TITLE',
          colSpan: 3,
          required: true,
          options: {
            readOnly: false,
          }
        },
        {
          key: 'dueDt',
          text: '완료희망일',
          i18n: 'UC.WORD.DUE_DATE',
          colSpan: 1,
          required: true,
          options: {
            stylingMode: 'outlined',
            readOnly: false,
          }
        },
        {
          key: 'userNo',
          text: '사번',
          i18n: 'UC.WORD.USER_NO',
          colSpan: 1,
          required: true,
          options: {
            readOnly: true,
          },
        },
        {
          key: 'userNm',
          text: '이름',
          i18n: 'COMPONENTS.NAME',
          colSpan: 1,
          required: true,
          options: {
            readOnly: true,
          },
        },
        {
          key: 'tenantNm',
          text: '그룹',
          i18n: 'COMPONENTS.GROUP',
          colSpan: 1,
          required: true,
          options: {
            readOnly: true,
          },
        },
        {
          key: 'deptNm',
          text: '부서',
          i18n: 'COMPONENTS.DEPT',
          colSpan: 1,
          required: true,
          options: {
            readOnly: true,
          },
        },
        {
          key: 'approverUser',
          text: '승인자',
          i18n: 'UC.WORD.APPROVER',
          colSpan: 4,
          required: true,
          options: {
            placeholder: '승인자를 선택해주세요.',
            readOnly: true,
          },
          template: 'approverUserTemplate'
        }
      ],
      requestData: {
        tenantId: null,
        tenantNm: '',
        userNo: '',
        userNm: '',
        gradeId: null,
        gradeNm: '',
        deptId: null,
        deptNm: '',
        title: '',
        dueDt: null,
        approverUser: '',
        approverUserNo: null,
        approverUserNm: '',
        approverGradeId: null,
        approverGradeNm: '',
        approverDeptId: null,
        approverDeptNm: '',
        workerUserNm: '',
        workerGradeNm: '',
        workerDeptNm: '',
      },
    }
  },
  watch: {
    'requestData.approverUserNo': { //data with key and list to send to parent component
      handler(val) {
        const filterData = val;
        if(filterData) {
          this.requestData.approverUser = `${this.requestData?.approverUserNm || ''} ${this.requestData?.approverGradeNm || ''} / ${this.requestData?.approverDeptNm || ''}`;
        }
      },
      deep: true,
    },
  },
  methods: {
    async onApprove() {
      const payload = {
        actionname: 'EUC_PHONE_REQUEST_UPDATE',
        data: [{
          id: this.requestData.id,
          approverFl: 'Y'
        }],
        loading: true
      }
      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        await this.selectData();
      } else {
        if(res.data.header?.resMsg) {
          this.$_Msg(res.data.header.resMsg, { icon: 'error' });
        } else {
          this.$_Msg(this.$_msgContents('COMMON.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }), { icon: 'error' });
        }
      }
    },
    onSelectedUser() {
      const user = this.$refs.modalRef.getSelectUser();
      if(!user?.[0] || !user[0].isUser) {
        this.$_Msg(this.$_msgContents('COMMON.MESSAGE.REQUIRED_SELECT_USER', { defaultValue: '사용자를 선택해주세요.' }), { icon: 'info' });
        return;
      }
      this.modal.sendData.onSelectedUser(user[0]);

      this.isOpenModal(false);
    },
    goListPage() {
      if(this.$store.getters?.getPreviousPath) {
        this.$_goPrePage();
      } else {
        this.$router.push('/euc/phone/approval/list');
      }
    },
    async selectUser(userNo) {
      const params = { userNo }
      const payload = {
        actionname: 'USER_LIST_ALL_VIEW',
        data: { params },
        loading: false,
      };

      const res = await this.CALL_API(payload);
      if(isSuccess(res)) {
        return res.data.data[0];
      }
    },
    /**
     * @description: 신청한 사용자 정보
     * @param {string} userNo
     * */
    async makeRequestUser(userNo) {
      userNo = userNo || this.$store.getters.getLoginId;

      let user = await this.selectUser(userNo);
      if(user?.id) {
        delete user.id;
      }

      const deptNm = user.nameTree.split('|');
      this.requestData.tenantNm = deptNm[0];
      this.requestData.deptNm = deptNm[1];
      this.requestData = { ...this.requestData, ...user };
    },
    showUserModal(options){
      this.onOpenModal(
          'ModalUserSelect',
          {
            title: options.title,
            width: '50vw',
            height: 'calc(100vh - 100px)',
            buttons:{
              save : { text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' }) },
              cancel : { text: this.$_msgContents('COMPONENTS.CLOSE', { defaultValue: '닫기' }) },
            },
          },
          options
      );
    },
    onOpenModal(componentNm, componentInitData, sendData){
      this.modal.currentComponent = componentNm;  //set dynamic component name in modal body slot
      this.modal.initData = componentInitData;  //set init modal templet
      this.modal.sendData = sendData;
      this.isOpenModal(true);
    },
    isOpenModal(bool) {
      this.modal.isOpened = bool;
    },
    async selectRequestData(id) {
      const payload = {
        actionname: 'EUC_PHONE_REQUEST_SELECT',
        data: { id }
      }
      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        const dueDt = new Date(res.data.data[0]?.dueDt);
        if(dueDt) {
          res.data.data[0].dueDt = `${dueDt.getFullYear()}. ${dueDt.getMonth() + 1}. ${dueDt.getDate()}.`;
        }
        return res.data.data[0];
      }
    },
    async selectData() {
      if(this.$route.query?.requestId) {
        this.requestData = await this.selectRequestData(this.$route.query.requestId);
        this.hasNotWorkCompleted = this.requestData.requestItems.some(item => item.status !== 'COMPLETE');
      } else {
        // @todo this.$route.query?.requestId 없을 경우 list 로 보내야할지?
      }

      this.requestDataForm.find((item) => item.key === 'title').options.readOnly = true;
      this.requestDataForm.find((item) => item.key === 'dueDt').options.readOnly = true;
      await this.makeRequestUser(this.requestData?.userNo);
    }
  },
  created() {
    this.selectData();
  },
  destroyed() {
    this.requestData = {};
    this.$store.commit('setDetailParams', null);
  },
}
</script>
<style scoped>
:global(.dx-texteditor.dx-editor-outlined.dx-state-readonly) {
  box-shadow: none !important;
}
.dx-icon-arrowright {
  font-size: 50px;
}
</style>