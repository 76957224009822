<template>
	<div class="widget-box txt-click-disable" ref="widgetBox">
		<!-- 위젯 상단 start -->
		<div class="widget-head-title" :style="mode === 'edit' ? 'cursor:pointer' : ''">
			<div class="font-light text-xl pt-1">{{ widget.title }}</div>
			<div class="widget-head-right">
				<div v-if="widget.chartType !== 'scoreguard'" :id="`date${widget.id}`">
					<i class="icon-calendar">달력</i>
					<div style="display: inline-flex; margin-top: 5px">{{ getWidgetDate }}</div>
				</div>
				<button class="btn-icon more" type="button" @click.stop="toggleSettingOn"></button>
				<div class="setting cursor-pointer" ref="setting" :class="{ on: isSettingOn }">
          <button v-if="widget.chartType !== 'scoreguard'" @click="onExportChartImage">이미지저장</button>
					<button
						@click="
							onOpenEnlargeModal(
  'ModalChartEnlarge',
								{
									name: 'chartEnlarge',
									title: '확대보기',
									buttons: {
										close: { text: '닫기' },
									},
									width: '80%',
									height: '80%',
								},
                widget,
								data,
							)
						"
					>
						확대보기
					</button>
					<br />
					<button
						v-if="mainFlag === false"
						@click="
							onOpenSettingModal(
								'ModalWidgetSetting',
								{
									name: 'setting',
									title: '설정',
									buttons: {
										save: { text: '확인' },
										cancel: { text: '취소' },
									},
									width: '1000',
									height: '800',
								},
								widget
							)
						"
					>
						설정
					</button>
				</div>
				<button
					class="btn-icon close"
					type="button"
					v-if="this.mode === 'edit'"
					@click="$emit('onRemoveWidgetLayout', widget.id)"
				/>
			</div>
		</div>
		<!-- 위젯 상단 end -->
		<!-- 위젯 chart || table start -->
		<template v-if="!widget.rotated && (widget.chartType === 'bar' || widget.chartType === 'stackedbar' || widget.chartType === 'fullstackedbar')">
			<Bar ref="chart" :widget="widget" :data="data" />
		</template>
		<template v-if="widget.rotated">
			<BarRotated ref="chart" :widget="widget" :data="data" />
		</template>
		<template v-if="widget.chartType === 'pie'">
			<Pie ref="chart" :widget="widget" :data="data" />
		</template>
		<template v-if="widget.chartType === 'line'">
			<LineChart ref="chart" :widget="widget" :data="data"></LineChart>
		</template>
		<template v-if="widget.chartType === 'scoreguard'">
			<div ref="card">
				<ScoreCard
          ref="chart"
          :id="`chart${widget.id}`"
					:v-if="widget.isCalculationComplete"
					:widget="widget"
					:data="data"
					:size="chart"
				>
					<div class="total">
<!--						{{ this.data === null ? 0 : this.widget.dataList.length > 0 ? this.widget.dataList[0].TOTAL : 0 }}-->
					</div>
				</ScoreCard>
			</div>
		</template>
		<template v-if="widget.chartType === 'table'">
			<Table ref="chart" :widget="widget" :data="data"></Table>
		</template>
		<!-- 위젯 chart || table end -->
		<!-- 위젯 팝업 start -->
		<DxPopup
			:show-title="true"
			title="확대보기"
			:min-width="modal.enlargeMode.initData ? modal.enlargeMode.initData.width : null"
			:width="modal.enlargeMode.initData ? modal.enlargeMode.initData.width : null"
			:min-height="modal.enlargeMode.initData ? modal.enlargeMode.initData.height : null"
			:height="modal.enlargeMode.initData ? modal.enlargeMode.initData.height : null"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:close-on-outside-click="false"
			:visible="modal.enlargeMode.isOpened"
			@hiding="isOpenEnlargeModal(false)"
		>
			<template #content>
				<div>
					<component :is="modal.enlargeMode.currentComponent" :widgetProperty="modal.enlargeMode.widgetProperty" :data = "modal.enlargeMode.data"></component>
				</div>
			</template>
      <DxToolbarItem
        v-if="this.mode !== 'enlarge'"
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.enlargeMode.initData.hasOwnProperty('buttons')?
          modal.enlargeMode.initData.buttons.hasOwnProperty('save')? modal.enlargeMode.initData.buttons.hasOwnProperty('save'): !modal.enlargeMode.initData.buttons.hasOwnProperty('cancel')
                                                                                                                                                                      : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.enlargeMode.initData.hasOwnProperty('buttons')?
          modal.enlargeMode.initData.buttons.hasOwnProperty('close')? modal.enlargeMode.initData.buttons.close.text : ''
                                                                                                  : '',
          width: '120',
          height: '40',
          useSubmitBehavior: true,
          onClick: e => {
            closeModal(e);
          },
        }"
      />
		</DxPopup>
    <DxPopup
        :show-title="true"
        title="설정"
        :min-width="modal.settingMode.initData ? modal.settingMode.initData.width : null"
        :width="modal.settingMode.initData ? modal.settingMode.initData.width : null"
        :min-height="modal.settingMode.initData ? modal.settingMode.initData.height : null"
        :height="modal.settingMode.initData ? modal.settingMode.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.settingMode.isOpened"
        @hiding="isOpenSettingModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.settingMode.currentComponent" :widgetProperty="modal.settingMode.widgetProperty" :data = "modal.settingMode.data"></component>
        </div>
      </template>
      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
          modal.settingMode.initData.hasOwnProperty('buttons')?
          modal.settingMode.initData.buttons.hasOwnProperty('save')? modal.settingMode.initData.buttons.hasOwnProperty('save'): !modal.settingMode.initData.buttons.hasOwnProperty('cancel')
                                                                                                                                                                      : false
        "
          :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.settingMode.initData.hasOwnProperty('buttons')?
          modal.settingMode.initData.buttons.hasOwnProperty('save')? modal.settingMode.initData.buttons.save.text : ''
                                                                                                  : ''
          ,
          width: '120',
          height: '40',
          useSubmitBehavior: true,
          onClick: e => {
            onConfirmModal(e);
          },
        }"
      />
      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
          modal.settingMode.initData.hasOwnProperty('buttons')?
          modal.settingMode.initData.buttons.hasOwnProperty('cancel')? modal.settingMode.initData.buttons.hasOwnProperty('cancel'): !modal.settingMode.initData.buttons.hasOwnProperty('cancel')
                                                                                                                                                                      : false
        "
          :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text:
            modal.settingMode.initData.hasOwnProperty('buttons')?
            modal.settingMode.initData.buttons.hasOwnProperty('cancel')? modal.settingMode.initData.buttons.cancel.text: ''
                                                                                                      : ''
          ,
          width: '120',
          height: '40',
          onClick: () => {
            isOpenSettingModal(false);
          },
        }"
      />
    </DxPopup>

    <!-- 위젯 팝업 end -->
	</div>
</template>
<script>
import ScoreCard from './widget/type/scoreCard.vue';
import { DxButton } from 'devextreme-vue/button';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import ModalWidgetSetting from '@/pages/report/dashboard/modal-widget-setting';
import ModalChartEnlarge from '@/pages/report/dashboard/modal-chart-enlarge';
import Bar from './widget/type/bar.vue';
import BarRotated from './widget/type/barRotated.vue';
import Pie from './widget/type/pie.vue';
import LineChart from './widget/type/line.vue';
import Table from './widget/type/table.vue';
import moment from 'moment';
import { DxChart } from 'devextreme-vue/chart';
import {isSuccess} from "@/plugins/common-lib";
export default {
	components: {
		DxChart,
		DxButton,
		DxPopup,
		DxToolbarItem,
		ModalWidgetSetting,
		ModalChartEnlarge,
		Bar,
		BarRotated,
		Pie,
		LineChart,
		ScoreCard,
		Table,
	},
	props: {
		widget: {
			type: Object,
		},
		mode: {
			type: String,
		},
		data: {
			type: Array,
		},
	},
	data() {
		return {
			isSettingOn: false,
			interval: null,
			modal: {
        settingMode: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          widgetProperty: null,
        },
        enlargeMode: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          widgetProperty: null,
        }
      },
			ro: null,
			chart: {
				type: 'bar',
				series: [],
				width: 0,
				height: 0,
			},
			duration: {
				recent: '1130',
			},
			dateFormat: {
				daily: 210,
				hour: 213,
			},
      mainFlag: false,
		};
	},
	computed: {
    /** @description : 위젯 우측 상단 날짜 출력 */
		getWidgetDate() {
			let dayStart;
			let dayEnd;
			if (this.widget.durationType === this.duration.recent) {
				dayStart = this.dayStartChangeWhenRecent();
				dayEnd = moment().format('YYYY.MM.DD');
      }
			return `${dayStart}~${dayEnd}`;
		},
	},
	methods: {
    /** @description : 최근 ( )일 조회일 경우 날짜 재정의 */
		dayStartChangeWhenRecent() {
			//최근 ( )일
			const recent = this.widget.recent;
			let date = new Date();
			const dateGroupCode = this.widget.dateGroupCode;
			let dayStart = '';
			if (dateGroupCode === this.dateFormat.daily || dateGroupCode === this.dateFormat.hour) {
				const daysAgo = date.getDate() - (recent-1);
        //date = date.setDate(daysAgo); 이렇게 설정하는 순간 타임스탬프 형식으로 변경됨
				date.setDate(daysAgo);
				dayStart = moment(date).format('YYYY.MM.DD');
			}
			return dayStart;
		},
    /** @description : 날짜 포맷 변경 */
		dateObjectFormating(dateString) {
			let formattedDate = dateString.substring(0, 4) + '.' + dateString.substring(4, 6) + '.' + dateString.substring(6, 8);
			return formattedDate;
		},
    /** @description : 확대보기 모달 닫기 버튼 클릭시 동작 */
    closeModal(){
      this.isOpenEnlargeModal(false);
    },
    /** @description : 설정 모달 저장 버튼 클릭시 동작 */
		async onConfirmModal() {
			let widget = this.modal.settingMode.widgetProperty; // TODO : obj clone관련 이슈 찾기

			if (Array.isArray(widget.modalColumnList)) {
				widget.columnList = widget.modalColumnList.join(',');
				widget.columnNameList = widget.modalColumnNameList.join(',');
			} else {
				widget.columnList = widget.modalColumnList;
				widget.columnNameList = widget.modalColumnNameList;
			}

			//년월일시간 정렬 선택에 따른 dayStart, dayEnd 값 변경
			const dateGroupCode = widget.dateGroupCode;
			widget.dateGroupCode = dateGroupCode;

      // 모달에서 수정한 데이터
			widget.title = widget.modalTitle;
			widget.dayStart = widget.modalDayStart;
			widget.dayEnd = widget.modalDayEnd;
			widget.durationType = widget.modalDurationType;
			widget.sortType = widget.modalSortType;
			widget.recent = widget.modalRecent;

      // TODO : 변경할 대상 변수명 - period
			widget.period = widget.dayStart + '~' + widget.dayEnd;

			this.updateWidget(widget);
			this.isOpenSettingModal(false);
		},
    /** @description : 설정 팝업 모달 열 경우 사용 */
    onOpenSettingModal(componentNm, componentInitData, widget, data) {
      this.modal.settingMode.currentComponent = componentNm;
      this.modal.settingMode.initData = componentInitData;
      this.modal.settingMode.widgetProperty = widget;
      this.modal.settingMode.data = data;// 해당 컴포넌트가 가지고 있는 widget
      this.isOpenSettingModal(true);
    },
    /** @description : 확대보기 팝업 모달 열 경우 사용 */
    onOpenEnlargeModal(componentNm, componentInitData, widget, data) {
      this.modal.enlargeMode.currentComponent = componentNm;
      this.modal.enlargeMode.initData = componentInitData;
      this.modal.enlargeMode.widgetProperty = widget;
      this.modal.enlargeMode.data = data;// 해당 컴포넌트가 가지고 있는 widget
      this.isOpenEnlargeModal(true);
    },
    /** @description : 설정 팝업 모달 열고 닫을 경우 사용 */
    isOpenSettingModal(flag) {
      this.modal.settingMode.isOpened = flag;
      this.isSettingOn = false;
      if (!flag) {
        this.modal.settingMode.currentComponent = null;
        this.modal.settingMode.initData = {};
      }
    },
    /** @description : 확대보기 팝업 모달 열고 닫을 경우 사용 */
    isOpenEnlargeModal(flag) {
      this.modal.enlargeMode.isOpened = flag;
      this.isSettingOn = false;
      if (!flag) {
        this.modal.enlargeMode.currentComponent = null;
        this.modal.enlargeMode.initData = {};
      }
    },
    /** @description : 위젯 이미지 저장 */
		onExportChartImage() {
      this.$refs.chart.$children[0].instance.exportTo(this.widget.title, 'png');
		},
    /** @description : 차트 Y축 컬럼 및 속성값(컬러, 변수)셋팅 */
		async setChatDataSeries() {
			this.chart.series = [];
			//TODO : 조회항목이 없는 경우 setChatDataSeries 메서드 종료시켜야함, 로직 수정 필요
			if ((await this.widget.columnList) === null) {
				return;
			}
      //FIXME: props 로 넘어온 변수를 let, const로 쓸 경우 use strict 모드에서 읽기 속성값이라는 오류 뿜기 때문에 var로 설정했으나 use strict 제거 작업 필요
			('use strict');
			var ayIds = Object.freeze(this.widget.columnList);
			var ayNms = Object.freeze(this.widget.columnNameList);
			var arrayColumnIds = [];
			var arrayColumnNms = [];

			if (typeof ayIds === 'undefined' || typeof ayNms === 'undefined') {
				return;
			}

			//템플릿에 따른 재료
			arrayColumnIds = Array.isArray(ayIds) ? ayIds : ayIds?.split(',');
			arrayColumnNms = Array.isArray(ayNms) ? ayNms : ayNms?.split(',');

			let columnList;

			if (typeof this.widget.columnList === 'undefined') {
				return;
			}

      // split 예외처리
			if (!Array.isArray(this.widget.columnList)) {
				columnList = this.widget.columnList?.split(',');
			} else {
				columnList = this.widget.columnList;
			}

			const arraySeriesColors = this.widget.widgetTp.seriesColors ? this.widget.widgetTp.seriesColors.split(',') : null;
			var series = this.chart.series;

			if (series.length === 0) {
				for (let i = 0; i < columnList.length; i++) {
					let data = {};

					let columnIdx = '';
					arrayColumnIds.find((e, idx) => {
						if (e === columnList[i]) {
							columnIdx = idx;
							data['value'] = columnList[i];
						}
					});

					data['name'] = arrayColumnNms[columnIdx];

					if (arraySeriesColors) {
						data['color'] = arraySeriesColors[columnIdx];
					}
					this.chart.series.push(data);
				}
			}
		},
    /** @description : 차트 리스트 초기 세팅 */
		async initChart() {
			this.setChatDataSeries();
			this.chart.type = this.widget.chartType;
		},
    /** @description : 특정 위젯 설정 개인화 팝업 저장시 동작 */
		async updateWidget(widget) {
			let data = [];
			data.push(widget);
			let payload = {
				actionname: 'WIDGET_LIST_UPDATE',
				data: data,
				loading: true,
			};
			const res = await this.CALL_REPORT_API(payload);

			if (isSuccess(res)) {
        this.$emit('initDashboard');
        //this.handleForceUpdate();
        /*
        if (this.widget.chartType === 'scoreguard' || this.widget.chartType === 'pivot') {
          this.widget.isCalculationComplete = false;

          // new Promise(resolve => {
          // 	this.$emit('onWidgetUpdate', widget, () => resolve());
          // }).then(() => {
            this.handleForceUpdate();
            if (this.widget.chartType === 'pivot') this.pivotRun();
          // });
        } else {
          this.$emit('initDashboard');
        }
        */
        await this.$_Toast(await this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
			} else {
				this.$_Toast(await this.$_msgContents('CMN_ERROR'));
			}
		},
    /** @description : 위젯 크기 조정 */
		async onResize() {
      await this.$nextTick();
			if (this.$refs.widgetBox) {
				this.chart.width = this.$refs.widgetBox.offsetWidth;
				this.chart.height = this.$refs.widgetBox.offsetHeight;

        let chartInstance =  this.$refs.chart.$children[0].instance;
        if(this.widget.chartType === 'table'){
          chartInstance.option('height', this.chart.height - 120);
          chartInstance.option('width', this.chart.width - 50);
        }else{
          chartInstance.render({ force: true });
          const size = {
            width: this.chart.width - 50,
            height: this.chart.height - 120,
          };
          chartInstance.option('size', size);
        }
			}
		},
    /** @description : 위젯 크기에 따른 날짜 숨김/숨김해제 */
    dateHidingAsWidth() {
      // 추가적인 코드들
      if (this.$refs.widgetBox.offsetWidth < 365) {
        let div = document.querySelector(`#date${this.widget.id}`);
        div.style.cssText = 'display: none;background-color:blue';
      } else {
        let div = document.querySelector(`#date${this.widget.id}`);
        div.style.cssText = 'display: inline-block;';
      }
    },
    /** @description : 설정 버튼 관제 이벤트 */
    toggleSettingOn() {
      this.isSettingOn = !this.isSettingOn;
    },
    /** @description : 버튼 외 위젯 영역에 클릭시 버튼 말풍선 off 이벤트 */
    handleClickOutside(event) {
      if (this.$refs.widgetBox.contains(event.target)) {
        this.isSettingOn = false;
      }
    },
		async mountedData() {
			this.initChart();
			this.widget.hasOwnProperty('dateGroupCode')
				? (this.widget.dateGroupCode = this.widget.dateGroupCode)
				: (this.widget.dateGroupCode = this.widget.widgetTp.dateGroupCode);
			this.ro = await new ResizeObserver(this.onResize).observe(this.$refs.widgetBox);
			this.dateHidingAsWidth();
		},
	},
	mounted() {
    this.mainFlag = this.$route.query.mainFlag;
		this.mountedData();
		window.addEventListener('resize', this.onResize);
    document.addEventListener('click', this.handleClickOutside);
	},
	updated() {
		clearInterval(this.interval);
	},
	beforeDestroy() {
		clearInterval(this.interval);
		window.removeEventListener('resize', this.onResize);
    document.removeEventListener('click', this.handleClickOutside);
	},
};
</script>
<style>
/* DxChart 에서 쓰이는 tooltip 관련 전역 설정
 - 전역으로 해야 효과
 - DxChart 사용하는 페이지 대시보드 외 없음
*/
.dxtm-tooltip {
	z-index: 2000 !important;
}

.dxc-tooltip {
	z-index: 2000 !important;
}
</style>
<style scoped>
.wrap {
	padding: 200px;
	text-align: center;
	background: #e0e0e0;
}

.txt-click-disable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.widget-box {
	width: 100%;
	height: 100%;
	background: #fff;
	border-radius: 8px;
	margin-bottom: 30px;
	padding: 20px 30px;
	box-shadow: 0 4px 8px rgba(51, 51, 51, 0.1);
}

.widget-head-title {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	font-size: 16px;
	color: #545454;
	font-weight: 500;
}

.setting {
	position: absolute;
	display: none;
	top: 24px;
	right: -30px;
	z-index: 20;
	width: 100px;
	background: #fff;
	border-radius: 5px;
	box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 10%);
	text-align: center;
	padding-top: 13px;
	padding-bottom: 13px;
}

.setting.on {
	display: block;
}

.setting button:hover {
	width: 100%;
	background: #f7f7f7;
	right: 2px;
}

.widget-head-right {
	display: inherit;
}

.widget-head-right button {
	margin-right: 0;
	margin-left: 2px;
}

.icon-calendar {
	display: inline-block;
	width: 20px;
	height: 17px;
	margin: 5px 3px 0px 0px;
	background: url('../../../assets/images/icon_calendar.png') no-repeat;
	vertical-align: top;
	text-indent: -9999px;
}
</style>