import en from './translations/en'
import ko from './translations/ko'

const supported = ['en', 'ko']
let locale = 'en'
let forceLocale = 'ko' // 강제로 로케일 설정, null 로 설정하면 브라우저 언어로 설정

try {
  // 브라우저 기본 언어 가져오기
  // FIXME : 브라우저 언어보다 사용자가 직접 변경 가능하게끔 변경 필요
  const { 0: browserLang } = navigator.language.split('-')

  // 강제로 locale 설정
  if (forceLocale) {
    locale = forceLocale
  } else if (supported.includes(browserLang)) { // 브라우저 언어가 지원되는 경우 해당 언어로 설정
    locale = browserLang
  }
} catch (e) {
  console.log(e)
}

export default {
  // current locale
  locale,

  // 번역을 사용할 수 없는 경우 해당 로케일로 대체
  fallbackLocale: 'ko',

  // 사용자 선택에 사용할 수 있는 로케일
  availableLocales: [{
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en
  }, {
    code: 'ko',
    flag: 'kr',
    label: '한국어',
    messages: ko
  }]
}
