import Vue from 'vue';
import VueRouter from 'vue-router';
import Bottom from '../layouts/bottom';
import LeftMenu from '../layouts/left-menu';
import Top from '../layouts/top';
import store from '../store';
import NotFoundError from '../pages/esp/error/404.vue';
import InternalServerError from '../pages/esp/error/500.vue';
import Breadcrumbs from '@/layouts/breadcrumbs.vue';
import { deleteObjectKey, isSuccess, isTrue } from '@/plugins/common-lib';
import Main from '../pages/main';
import Maintenance from '../pages/esp/error/maintenance.vue';
import EmptyMainPage from '../pages/esp/error/noneMain.vue';
import Blank from '../layouts/blank.vue';
import enums from '@/configs/enums';

// routes import
import AuthRoutes from "@/router/auth.routes";
import ReportRoutes from "@/router/report.routes";
import EucRoutes from "@/router/euc.routes";

Vue.use(VueRouter); // Vue Router 설정

// 라우터 설정
const staticRoutes = [
  {
    path: '/',
    name: 'home',
    components: { default: Main, top: Top, bottom: Bottom, left: LeftMenu, breadcrumbs: '' },
    meta: { requiresAuth: true, historyType: 0, title: null },
  },
  {
    path: '*',
    redirect: '/error/404',
  },
  ...EucRoutes,
  ...ReportRoutes,
  ...AuthRoutes,
  {
    path: '/error/404',
    name: 'Error404',
    components: { all: NotFoundError, top: '', bottom: '', left: '' },
    meta: { requiresAuth: false, historyType: 0, isAllPage: true },
  },
  {
    path: '/error/500',
    name: 'Error500',
    components: { all: InternalServerError, top: '', bottom: '', left: '' },
    meta: { requiresAuth: false, historyType: 0, isAllPage: true },
  },
  {
    path: '/error/maintenance',
    name: 'maintenance',
    components: { all: Maintenance, top: '', bottom: '', left: '' },
    meta: { requiresAuth: false, historyType: 0, isAllPage: true },
  },
  {
    path: '/blank',
    name: 'Blank',
    components: { default: Blank, top: Top, bottom: Bottom, left: LeftMenu, breadcrumbs: Breadcrumbs },
    meta: { requiresAuth: true, historyType: 2 },
  },
  {
    path: '/empty-main-page',
    name: 'EmptyMainPage',
    components: { default: EmptyMainPage, top: Top, bottom: Bottom, left: LeftMenu, breadcrumbs: Breadcrumbs },
    meta: { requiresAuth: true, historyType: 2 },
  },
];

/**
 * 동적 라우트를 sessionStorage에서 가져와 반환, 새로고침시 동적 라우트를 유지하기 위함
 * @returns {Array}
 */
const getDynamicRoutesFromSession = () => {
  const dynamicRoutes = [];
  const menuList = sessionStorage.getItem('menuList');

  if (menuList) {
    const parsedMenuList = JSON.parse(menuList);

    parsedMenuList.forEach(element => {
      const defaultComponent = () => import(/* webpackChunkName: "[request]" */ `../pages${element.pageUrl}`);

      dynamicRoutes.push({
        path: element.pageUrl,
        name: element.id,
        components: { default: defaultComponent, top: Top, bottom: Bottom, left: LeftMenu, breadcrumbs: Breadcrumbs },
        meta: { requiresAuth: true, historyType: 1 },
        props: { default: true },
      });
    });
  }

  return dynamicRoutes;
};

const dynamicRoutes = getDynamicRoutesFromSession(); // 동적 라우트 설정

// 중복 네비게이션 error 처리
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  mode: 'history',
  routes: [...staticRoutes, ...dynamicRoutes],
  duplicateNavigationPolicy: 'reload',
});

/**
 * @description: 이전페이지 path 저장
 * @param previousData 이전 path 정보
 * @param currentData 현재 path 정보
 */
const setPreviousPath = (previousData, currentData) => {
  if (previousData.name !== null) {
    //이전페이지 path로 설정
    store.commit('setPreviousPath', previousData.path);
  }
};

/**
 * @description: 목록 캐시 유지 설정
 *               1. 상세페이지 -> 목록으로 이동시
 * @param previousData
 * @param currentData
 */
const setListCacheFl = (previousData, currentData) => {
  let previousPath;
  if (previousData.name !== null) {
    previousPath = previousData.path;
  } else {
    previousPath = currentData.path;
  }
  const previousParentPath = previousPath.substring(0, previousPath.lastIndexOf('/'));
  const currentPath = currentData.path;
  const currentParentPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
  Vue.$log.debug('previousPath >> ', previousPath);
  Vue.$log.debug('currentPath >> ', currentPath);

  //상세페이지 -> 리스트로 이동시
  if (previousParentPath === currentParentPath && currentPath.includes('/list')) {
    store.commit('setListCacheFl', true);
  } else {
    //다른 페이지로 이동시
    store.commit('setListCacheFl', false);
  }

  //다른 리스트로 이동시 검색 이력 삭제
  if (previousParentPath !== currentParentPath) {
    let searchCacheFl = store.getters.getSystemList
      ? store.getters.getSystemList.find(val => val.configKey === 'search_cache_fl').configValue
      : false;
    searchCacheFl = isTrue(searchCacheFl.toString());
    if (!searchCacheFl && store.getters.getSearchHists) {
      //검색 캐시 저장이 false일 경우
      //해당 페이지 검색 캐시 삭제
      let searchHists = deleteObjectKey(store.getters.getSearchHists, previousPath);
      store.commit('setSearchHists', searchHists);
    }
  }
};

router.beforeEach(async (to, from, next) => {
  try {
    // 점검 모드 확인
    checkMaintenance(to, next);

    // Direct login 처리
    const isLoggedIn = await handleDirectLogin(to, next);
    if (isLoggedIn) return;

    // 이전 페이지 path 저장
    setPreviousPath(from, to);
    // 목록 캐시 유지 설정(상세페이지 -> 목록으로 이동시)
    setListCacheFl(from, to);

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const historyType = to.meta.historyType;

    // API URL 목록이 없는 경우 API URL 목록을 불러옵니다.
    if (store.getters.getRoutingInfos.length === 0) {
      await store.dispatch('SET_ROUTING_INFO', {});
    }

    if (!requiresAuth) {
      Vue.$log.debug('No authentication required, proceeding...');
      next();
      return;
    }

    const accessToken = store.getters.getPersistedAccessToken;

    if (!accessToken) {
      Vue.$log.debug('No accessToken, redirecting to /login');
      next('/login');
      return;
    }

    const authRes = await store.dispatch('LOGIN_ANOTHER_CHECK', { accessToken });

    if (!isSuccess(authRes)) {
      next('/login');
      return;
    }

    if (!isSuccess(authRes)) {
      handleUnauthorized(to);
      return;
    } else {
      store.commit('setAuthId', authRes?.data?.data?.authId);
    }

    // 메뉴 목록이 없는 경우 메뉴 목록을 불러옵니다.
    if (store.getters.getMenuList.length === 0) {
      if ((await initializeMenu(router)) === 0) {
        Vue.$log.debug('Menu list is empty, redirecting to /login');
        next({ path: '/login', query: { routerError: '메뉴 권한이 설정되지 않은 계정입니다. 관리자에 문의 하세요.' } });
        return;
      }
      next({ ...to, replace: true }); // 라우터 이동을 다시 시도, 중간에 addRoute로 추가된 라우트가 반영되도록 함
      return;
    }

    await initializeStore(); // 스토어 데이터 초기화
    await updateMenuHistory(historyType, to, store); // 메뉴 히스토리 업데이트
    await checkPasswordChange(to, authRes, router); // 비밀번호 변경 체크 및 라우터 이동 처리
    await checkUserMainPage(to); // 사용자 메인 페이지 체크 및 라우터 이동 처리

    Vue.nextTick(() => {
      next(); // 모든 처리가 완료된 후 next 호출
    });
  } catch (error) {
    Vue.$log.debug(error);
    await store.dispatch('LOGOUT', { loginId: store.getters.getLoginId });
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    // TODO: 페이지 이동 후, 웹 브라우저 타이틀 변경
    document.title = store.getters.getThemeSetting?.customerNm || 'ESP';
  });
});

/**
 * @description 메뉴 목록을 동적으로 Vue Router에 추가
 * @param {Object} router - Vue Router 인스턴스.
 * @return {Promise<number>} - 추가된 라우트 수
 */
const loadMenuRoutes = async router => {
  const menuList = store.getters.getMenuList.filter(d => d.pageUrl !== null);
  let routeCount = 0;
  for (const element of menuList) {
    try {
      // 페이지 URL을 기반으로 컴포넌트를 동적으로 로드합니다.
      const defaultComponent = () => import(/* webpackChunkName: "[request]" */ '../pages' + element.pageUrl);

      // 외부 경로로의 리디렉션 설정
      let pageUrl = element?.pageUrl;

      // 라우트가 중복되지 않도록 이미 존재하는지 확인합니다.
      if (pageUrl.startsWith('http') || pageUrl.startsWith('https')) {
        // 외부 경로로 리디렉션
        router.addRoute({
          path: '/' + element.id, // 외부 경로를 위한 유니크한 경로 설정
          beforeEnter() {
            window.location.href = pageUrl;
          },
        });
      } else {
        // 내부 경로 처리
        if (!router.options.routes.some(route => route.path === pageUrl)) {
          if (!pageUrl.startsWith('/')) {
            pageUrl = '/' + pageUrl;
          }

          router.addRoute({
            path: pageUrl,
            name: element.id,
            components: {
              default: defaultComponent,
              top: Top,
              bottom: Bottom,
              left: LeftMenu,
              breadcrumbs: Breadcrumbs,
            },
          });
        }
      }
      routeCount++;
    } catch (error) {
      console.error(`Failed to add route for ${element.pageUrl}:`, error);
    }
  }
  sessionStorage.setItem('menuList', JSON.stringify(menuList));
  return routeCount;
};

/**
 * @description: 메뉴 히스토리 업데이트
 * @param {number} historyType - 라우터 히스토리 타입. (0: 기본, 1: 추가, 2: 갱신)
 * @param {Object} to - 라우터 이동 대상 route 객체.
 * @param {Object} store - Vuex 스토어 인스턴스.
 */
const updateMenuHistory = async (historyType, to, store) => {
  if (historyType !== 0) {
    const menuData = [
      {
        menuId: historyType === 2 ? to.params.menuid : to.name,
      },
    ];

    const menuPayload = {
      actionname: 'MENU_HISTORY_SAVE',
      data: menuData,
    };

    await store.dispatch('CALL_API', menuPayload);
  }
};

/**
 * @description: 비밀번호 변경 여부 확인 및 라우터 이동
 * @param {Object} to - 라우터 이동 대상 route 객체.
 * @param {Object} authRes - 인증 결과로부터 받은 응답 데이터.
 * @param {Object} router - Vue Router 인스턴스.
 */
const checkPasswordChange = async (to, authRes, router) => {
  const isPasswordChange =
    authRes?.data?.data?.memberState === enums.common.memberState.TEMPORARY.value ||
    authRes?.data?.data?.memberState === enums.common.memberState.EXPIRED.value;

  if (to.path !== '/password-change' && isPasswordChange) {
    await router.push({
      name: 'PasswordChange',
      query: {
        continue: to.fullPath,
      },
    });
  }
};

/**
 * 인증 실패 시 처리 함수
 * @description: 401 Unauthorized 처리 함수
 * @param {Object} to - 라우터 이동 대상 route 객체.
 * */
const handleUnauthorized = to => {
  redirectToLogin(to);
};

/**
 * @description: 로그인 페이지로 리다이렉트
 * @param {Object} to - 라우터 이동 대상 route 객체.
 * */
const redirectToLogin = to => {
  router.push({
    name: 'Login',
    query: {
      continue: to.fullPath,
    },
  });
};

/**
 * @description: 점검 모드 확인
 * @param to
 * @param next
 */
const checkMaintenance = (to, next) => {
  const isMaintenance = false; // FIXME : 점검 모드 확인 구현 필요

  // 점검 모드인 경우
  if (isMaintenance && to.name !== 'maintenance') {
    // 조건이 true이고, 목적지가 Maintenance 페이지가 아니면 강제로 Maintenance 페이지로 이동
    next({ name: 'maintenance' });
  }
};

/**
 * 메뉴 데이터 초기화
 *
 * @param router
 * @return {Promise<number>}
 */
const initializeMenu = async router => {
  if (store.getters.getMenuList.length === 0) {
    const apiPayload = {
      actionname: 'MENU_LIST_ALL',
      data: {
        filterByAuthMenu: true,
        authId: store.getters.getAuthId,
        viewFl: enums.common.stringViewFlag.YES.value,
        isCompact: true, // 간략 조회 여부
      },
    };
    let routeCount = 0;
    try {
      const res = await store.dispatch('CALL_API', apiPayload);

      if (isSuccess(res)) {
        store.commit('setMenuList', res.data.data);
        routeCount = await loadMenuRoutes(router);
      } else {
        Vue.$log.debug(`initializeMenu call failed: ${apiPayload.actionname}`, res);
      }
    } catch (error) {
      console.error(`initializeMenu call error for ${apiPayload.actionname}: `, error);
    }
    return routeCount;
  }
};

/**
 * 스토어 데이터 초기화
 * @return {Promise<void>}
 */
const initializeStore = async () => {
  const createApiPayload = (actionname, paramsData, loading = false) => ({
    actionname,
    data: paramsData,
    loading,
  });

  let apiList = [];

  if (store.getters.getCodeList.length === 0) {
    apiList.push({
      api: createApiPayload('CODE_LIST_ALL', { delFl: 'N' }),
      callback: data => {
        store.commit('setCodeList', data);
      },
    });
  }

  if (store.getters.getSystemList.length === 0) {
    apiList.push({
      api: createApiPayload('SYSTEM_SETTING_LIST', {}),
      callback: data => {
        store.commit('setSystemList', data);
      },
    });
  }

  if (store.getters.getSiteList.length === 0) {
    apiList.push({
      api: createApiPayload('SITE_LIST_ALL', { loginId: store.getters.getLoginId }),
      callback: data => {
        store.commit('setSiteList', data);
      },
    });
  }

  if (store.getters.getTenantList.length === 0) {
    apiList.push({
      api: createApiPayload('TENANT_LIST_ALL', { loginId: store.getters.getLoginId }),
      callback: data => {
        store.commit('setTenantList', data);
      },
    });
  }

  if (store.getters.getHolidayList.length === 0) {
    apiList.push({
      api: createApiPayload('HOLIDAY_LIST_ALL', {}),
      callback: data => {
        store.commit('setHolidayList', data);
      },
    });
  }

  if (!store.getters.getThemeSetting) {
    apiList.push({
      api: createApiPayload('THEME_SETTINGS_LIST', { includeBase64: true }),
      callback: data => {
        store.commit('setThemeSetting', data);
      },
    });
  }

  if (store.getters.getPermissionList.length === 0) {
    apiList.push({
      api: createApiPayload('AUTH_MENU_PERMISSION_LIST_ALL', {}),
      callback: data => {
        store.commit('setPermissionList', data);
      },
    });
  }

  if (store.getters.getFavorite.length === 0) {
    apiList.push({
      api: createApiPayload('MENU_FAVORITE_LIST', {}),
      callback: data => {
        store.commit('setFavorite', data);
      },
    });
  }

  if (store.getters.getIsAdminUser == null) {
    apiList.push({
      api: createApiPayload('MEMBER_IS_ADMIN_USER', { loginId: store.getters.getLoginId }),
      callback: data => {
        store.commit('setIsAdminUser', data != null);
      },
    });
  }

  if (store.getters.getUserMainPage == null) {
    apiList.push({
      api: createApiPayload('MENU_MAIN_PAGE_USER', {}),
      callback: data => {
        store.commit('setUserMainPage', data);
      },
    });
  }

  const apiCalls = apiList.map(({ api, callback }) => async () => {
    const startTime = new Date().toISOString();
    Vue.$log.debug(`initializeStore call started: ${api.actionname} at ${startTime}`);

    try {
      const res = await store.dispatch('CALL_API', api);

      const endTime = new Date().toISOString();
      Vue.$log.debug(
        `initializeStore call successful: ${api.actionname} at ${endTime} with duration ${Date.parse(endTime) - Date.parse(startTime)}ms`,
      );

      if (isSuccess(res)) {
        await callback(res.data.data); // 비동기 콜백 호출
      } else {
        Vue.$log.debug(`initializeStore call failed: ${api.actionname}`, res);
      }
    } catch (error) {
      console.error(`initializeStore call error for ${api.actionname}: `, error);
    }
  });

  // 모든 API 호출이 완료될 때까지 대기
  await Promise.all(apiCalls.map(fn => fn()));
};

/**
 * 다이렉트 로그인 처리
 *
 * @param to
 * @param next
 * @return {Promise<boolean>}
 */
const handleDirectLogin = async (to, next) => {
  if (to.query?.loginId) {
    const loginId = to.query.loginId;
    const res = await store.dispatch('LOGIN_DIRECT', { loginId });
    if (isSuccess(res)) {
      const url = new URL(to.fullPath, window.location.origin);
      url.searchParams.delete('loginId');
      const newPath = url.pathname + url.search;
      next(newPath || '/');
      return true;
    }
  }
  return false;
};

/**
 * 사용자 메인 페이지 체크 및 라우터 이동 처리
 *
 * @param to
 * @returns {Promise<void>}
 */
const checkUserMainPage = async to => {
  if (to.path === '/blank' && store.getters.getUserMainPage) {
    const userMainPage = store.getters.getUserMainPage;
    const pageType = userMainPage?.mainType;
    const pageId = userMainPage?.value;

    // 사용자 메인 페이지 타입이 대시보드인 경우
    if (pageType === enums.common.mainPageType.DASHBOARD.value) {
      // FIXME : `/report/dashboard/list` 은 메인대시보드 페이지를 찾기 위한 하드코딩, breadcrumb menuid 안넘겼을때 처리 필요
      const pageData = store.getters.getMenuList.find(menu => menu.pageUrl === '/report/dashboard/list');
      const route = pageData
        ? {
          name: 'ReportDashboard',
          query: {
            dashboardTemplateId: pageId,
            mainFlag: true,
          },
          params: { menuid: pageData.id },
        }
        : { name: 'EmptyMainPage' };
      await router.push(route);
    } else if (pageType === enums.common.mainPageType.MENU.value) {
      // 사용자 메인 페이지 타입이 메뉴인 경우
      const pageData = store.getters.getMenuList.find(menu => menu.id === pageId);
      const pageUrl = setReportPageUrl(pageData, pageId);

      // 메뉴 타입이 Report 인 경우 DefaultReport로 이동
      const route = pageUrl === '' ? { name: 'EmptyMainPage' } : { path: pageUrl, params: { menuid: pageId } };
      await router.push(route);
    } else {
      // 사용자 메인 페이지 타입이 없는 경우
      await router.push({ name: 'EmptyMainPage' });
    }
  }
};

/**
 * 페이지 URL 설정 <br>
 * - 메뉴 타입이 REPORT 인경우
 *
 * @param pageData
 * @param pageId
 * @return {*|number|string}
 */
const setReportPageUrl = (pageData, pageId) => {
  if (pageData === undefined) {
    return '';
  }

  const pageUrl = (pageData?.pageUrl || pageData.pageUrl.trim()) === '' ? '' : pageData.pageUrl;
  if (pageData?.menuTypeCd === enums.common.menuType.REPORT.value) {
    return (pageUrl === '' ? '/data-report' : pageUrl) + '/' + pageId;
  }
  return pageUrl === '' ? pageUrl : pageData.pageUrl;
};

export default router;
