import { SET_ROUTING_INFO, CALL_API, INIT_SYSTEM_SETTING, INIT_THEME_SETTING, REFRESH_TOKEN } from './esp';
import { CALL_EWM_API } from './ewm';
import { CALL_EUC_API } from './uc';
import { CALL_REPORT_API } from './report';
import { CALL_CC_API } from './cc';
import { CALL_LLM_API } from './llm';
import { CALL_FORECAST_API } from './forecast';
import { LOGOUT, LOGIN, LOGIN_ANOTHER_CHECK, LOGIN_DIRECT } from './auth';

export const actions = {
	SET_ROUTING_INFO,
	REFRESH_TOKEN,
	CALL_API,
	CALL_EWM_API,
	CALL_EUC_API,
	CALL_REPORT_API,
	CALL_CC_API,
	CALL_LLM_API,
	CALL_FORECAST_API,
	LOGOUT,
	LOGIN,
	LOGIN_ANOTHER_CHECK,
	LOGIN_DIRECT,
	INIT_SYSTEM_SETTING,
	INIT_THEME_SETTING
};
