import DxDateBox from 'devextreme/ui/date_box';
import TextBox from 'devextreme/ui/text_box';
import {loadMessages, locale} from 'devextreme/localization';
import koMessages from "@/configs/devextreme/ko.json"; // DevExpress 관련 설정
import '@/assets/css/dx.material.emerald.light.compact.css';  //퍼블리싱 디자인에 맞게 테마 추가

const DevExtremePlugin = {
  install(Vue, {store}) {

    // 한국어 메시지를 DevExtreme에 로드(기본적으로 한국어가 없으므로 커스텀한 json 로드해야함)
    loadMessages(koMessages);

    // TextBox 기본 옵션 설정
    TextBox.defaultOptions({
      options: {
        stylingMode: 'outlined',
      },
    });

    // DxDateBox 기본 옵션 설정
    DxDateBox.defaultOptions({
      options: {
        calendarOptions: {
          cellTemplate: (container) => {
            let span = document.createElement('span');
            span.innerText = container.text;

            // 사용자 정의 CSS 클래스 추가
            const cssClass = (() => {
              let cssClass = '';
              // 동적으로 store의 상태를 참조
              const holidays = store.state.holidayList ? makeHoliday(store.state.holidayList) : [];
              const date = container.date;
              const view = container.view;

              if (view === 'month') {
                const formatDate = date.toISOString().split('T')[0];
                const today = new Date().toISOString().split('T')[0];

                if (formatDate === today) {
                  cssClass = 'today';
                }
                if (date.getDay() === 6) {
                  cssClass = 'weekend';
                }
                if (date.getDay() === 0) {
                  cssClass = 'holiday';
                }

                holidays.forEach(item => {
                  // 고정휴일
                  if (item.length === 2) {
                    if (date.getMonth() === item[0] && date.getDate() === item[1]) {
                      cssClass = 'holiday';
                    }
                  } else {
                    // 변동휴일
                    if (date.getFullYear() === item[0] && date.getMonth() === item[1] && date.getDate() === item[2]) {
                      cssClass = 'holiday';
                    }
                  }
                });
              }
              return cssClass;
            })();

            if (cssClass) {
              span.classList.add(cssClass);
            }

            return span;
          },
        },
      },
    });

    // DevExtreme 구성 요소를 전역으로 등록
    Vue.component('DxTextBox', TextBox);
    Vue.component('DxDateBox', DxDateBox);

    // 브라우저의 언어 설정에 기반한 로케일 설정
    // TODO : 브라우저 언어 설정 기반 대신 사용자 설정 기반으로 변경되도록 수정필요.
    const browserLanguage = navigator.language.split('-')[0]; // 예: 'ko-KR' -> 'ko'
    console.log('browserLanguage:::', browserLanguage);
    locale(browserLanguage); // DevExtreme 관련 로케일 설정
  },
};

/**
 * 휴일 데이터를 배열로 만들어주는 함수
 *
 * @param holidayList
 * @return {*[]}
 */
const makeHoliday = (holidayList) => {
  let holidays = [];
  holidayList.forEach(holiday => {
    //고정휴일 : 월(index : 0), 일(index: 1) 데이터 배열로 넣기 ex) [month, day] : [ [0, 1], [6, 4], [11, 25] ]
    if (holiday.holidayTypeCd === 'FIXED') {
      let month = Number(holiday.date.substr(0, 2)) - 1;
      let day = Number(holiday.date.substr(2, 2));
      holidays.push([month, day]);
    } else if (holiday.holidayTypeCd === 'FLOATING') {
      //변동휴일 : 년, 월(index : 0), 일(index: 1) 데이터 배열로 넣기 ex) [ [2022, 8, 14 ], [2022, 5, 1], [2022, 10, 26] ]
      let month = Number(holiday.date.substr(4, 2)) - 1;
      let day = Number(holiday.date.substr(6, 2));
      holidays.push([Number(holiday.year), month, day]);
    }
  });
  return holidays;
}

export default DevExtremePlugin;