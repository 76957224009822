<!--
  PACKAGE_NAME : src/pages/euc/contact
  FILE_NAME : index.vue.vue
  AUTHOR : jhcho
  DATE : 2024-06-10
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <Tab title="개인주소록">
        <Contact v-if="tabIndex === 0" />
      </Tab>
      <Tab title="그룹관리">
        <Group v-if="tabIndex === 1" />
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import Tab from '@/components/common/tab.vue';
import Tabs from '@/components/common/tabs.vue';
import Contact from './contact.vue';
import Group from './group.vue';

export default {
  components: {
    Tabs,
    Tab,
    Contact,
    Group
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  methods: {
    async tabSelectedIndex(index) {
      this.tabIndex = index;
    },
  },
}
</script>
<style scoped>

</style>