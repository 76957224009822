import { EventBus } from '@/event-bus';
import { llmApi } from '@/api';

/**
 * routing 정보 조회
 * @param state - store state
 * @param payload
 * @return {T}
 */
const getRoutingInfo = ({ state }, payload) => {
  const routingInfo = state.routingInfos.find(d => d.actionNm === payload.actionname);

  // actionname이 stor routingInfos 에 없는 경우( 예) 미표시된 API URL )
  if (payload.actionname !== null && payload.actionname !== undefined && routingInfo === undefined) {
    const message = `
					캐시 새로고침 또는 로그아웃 후 재시도 하세요. </br>
					(오류 재발시 관리자에게 문의하세요.) </br>
					not found action name -> "${payload.actionname}"
					`;
    EventBus.$emit('app:errMsgOn', message);
  }
  return routingInfo;
};

/**
 * LLM API 호출
 * @param state
 * @param payload
 * @return {Promise<*|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|e.Response<any, Record<string, any>>|AuthenticatorResponse>}
 * @constructor
 */
export const CALL_LLM_API = async ({ state }, payload) => {
  let res;
  try {
    const routingInfo = getRoutingInfo({ state }, payload);
    let requestPath = routingInfo.path;
    if (payload?.path) {
      if (payload.path.startsWith('/')) {
        requestPath += payload.path;
      } else {
        requestPath += '/' + payload.path;
      }
    }
    // API 호출 시 필요한 정보
    const requestParam = {
      host: routingInfo.host,
      path: requestPath,
      timeout: payload.timeout || routingInfo.timeout,
      method: routingInfo.method,
      actionname: payload.actionname,
      data: payload.data,
      responseType: payload.responseType,
      headers: payload.headers,
    };

    payload.loading && EventBus.$emit('app:progress', true);
    res = await llmApi.callApi(requestParam);
    payload.loading && EventBus.$emit('app:progress', false);
  } catch (error) {
    if (payload.loading) EventBus.$emit('app:progress', false);
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
  return res;
};
