<template>
  <div :class="$route.path === '/' ? 'bgstyle-02' : ''" style="height: 100%">
    <div id="wrap" ref="wrapRef" class="wrap" v-if="!$route.meta.isAllPage" :class="{ mobile_wrap: isMobile, ms_style01: isMobile }">
      <!-- 모바일 접속 시 클래스 추가 -->
      <!-- Header -->
      <router-view name="top" />

      <!-- Side Pannel -->
      <!--
            왼쪽메뉴 이름 구분
            dashboards >> 대시보드
            operationmng >> 운영관리
            report >> 보고서
            monitoring >> 모니터링
            preferences >> 환경설정
            -->
      <!-- side pannel icon -->
      <router-view name="left" />

      <!-- Main contents -->
      <main :class="{ 'main-dash': $route.path === '/', 'height-over': true }">
        <div class="content">
          <div class="themes-content-wrap">
            <div class="themes-content-wrap-inner">
              <router-view name="breadcrumbs" />
              <div v-if="changedColor()" class="themes-header-txt-bg"></div>
              <!--각 테마별 상단 배경바-->
              <router-view />
            </div>
            <!--e 각 테마별 상단 배경바 아래 컨텐츠 그룹 wrap 생성-->
          </div>
        </div>
      </main>
      <!-- 모바일 팝업 시 배경흐림 적용 -->
      <div id="popLayer" class="popDim" v-if="$store.getters.getIsMobile">
        <div class="dim"></div>
      </div>
    </div>
    <div id="popLayer" class="popDim"></div>
    <router-view name="all" />
    <DxLoadPanel
      :position="position"
      :visible="loadingVisible"
      :show-indicator="showIndicator"
      :show-pane="showPane"
      :shading="shading"
      :close-on-outside-click="closeOnOutsideClick"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>

<script>
  import { EventBus } from '@/event-bus';
  import { DxLoadPanel } from 'devextreme-vue/load-panel';

  const $ = window.$;

  export default {
    name: 'App',
    components: {
      DxLoadPanel,
    },
    data() {
      return {
        loadingVisible: false,
        position: { of: '#content' },
        showIndicator: true,
        shading: true,
        showPane: true,
        closeOnOutsideClick: false,
      };
    },
    computed: {
      // 스토어에서 모바일 여부 확인
      isMobile() {
        return this.$store.getters.getIsMobile;
      },
    },
    methods: {
      /**
       * 하위 컴포넌트에서 ref 를 사용하기 위함
       * */
      getWrapElement() {
        return this.$refs.wrapRef;
      },
      // 로딩 중
      /**
       * 로딩바를 보여준다.
       */
      loading() {
        $.loading();
      },
      /**
       * 로딩바를 보여주거나 숨긴다.
       * @param isShow
       */
      showLoadPanel(isShow) {
        if (isShow) {
          $.loading();
        } else {
          $.removeLoading();
        }
        // 기존 로딩바
        // this.loadingVisible = isShow;
      },
      /**
       * app:errMsgOn 이벤트를 통해 에러 메시지를 띄워준다.
       * @param msg
       */
      showErrMsg(msg) {
        this.$_Msg(msg);
      },
      /**
       * 테마 색상 변경
       * @return {string}
       */
      changedColor() {
        // 가상 클래스 요소 얻기
        let root = document.querySelector(':root');
        // window.getComputedStyle 메서드를 이용하면, 해당 요소에 전역적으로 적용된 모든 형태의 style을 반환
        let variables = getComputedStyle(root);

        let themeColor;
        // 테마 색상이 있을 경우
        if (this.$store.getters.getThemeSetting?.themeColor) {
          themeColor = this.$store.getters.getThemeSetting?.themeColor;
        } else {
          themeColor = variables.getPropertyValue('--themeColor');
        }
        document.documentElement.style.setProperty('--themeColor', themeColor);

        let testHex = themeColor;
        this.convertHexToRGBA(testHex, 0.2);
        return themeColor;
      },
      /**
       * 테마 색상에 투명도를 적용한다.
       * @param hexCode
       * @param opacity
       * @return {string}
       */
      applyOpacity(hexCode, opacity) {
        let hex = hexCode.replace('#', '');

        if (hex.length === 3) {
          hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
        }

        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        /* Backward compatibility for whole number based opacity values. */
        if (opacity > 1 && opacity <= 100) {
          opacity = opacity / 100;
        }

        const hexToRgb = `${r},${g},${b},${opacity}`;
        return 'rgba(' + hexToRgb + ')';
      },
      /**
       * 테마 색상을 rgba로 변환한다.
       * @param hexCode
       * @param opacity
       */
      convertHexToRGBA(hexCode, opacity) {
        document.documentElement.style.setProperty('--opacityThemeColor', this.applyOpacity(hexCode, opacity));
      },
      /**
       * favicon 설정
       */
      setFavicon() {
        const faviconImg = this.$store.getters.getThemeSetting?.faviconImg;

        let faviconPath = '';
        if (faviconImg) {
          faviconPath = this.$_getAttachFileURL(null, faviconImg);
        } else {
          faviconPath = require('@/assets/images/ecss_ico1.png');
          this.applyFavicon(faviconPath);
          // 이미지가 없을 경우, early return
          return;
        }

        const testImage = new Image();
        testImage.onload = () => {
          // 이미지 로드 성공 시
          this.applyFavicon(faviconPath);
        };

        testImage.onerror = () => {
          // 이미지 로드 실패 시
          this.applyFavicon(require('@/assets/images/ecss_ico1.png'));
        };
        testImage.src = faviconPath;
      },
      /**
       * favicon 적용
       *
       * @param path favicon 경로
       */
      applyFavicon(path) {
        const link = document.getElementById('faviconId');
        if (link) {
          link.href = path;
        } else {
          const newLink = document.createElement('link');
          newLink.id = 'faviconId';
          newLink.rel = 'icon';
          newLink.href = path;
          document.head.appendChild(newLink);
        }
      },
      /**
       * 초기 설정
       */
      async init() {
        await this.$store.dispatch('INIT_SYSTEM_SETTING'); // 시스템 설정 초기화
        await this.$store.dispatch('INIT_THEME_SETTING'); // 테마 설정 초기화
        await this.$store.dispatch('SET_ROUTING_INFO'); // 라우팅 정보 초기화

        this.setFavicon(); // favicon 설정
        const encryptionTypeConfig = this.$store.getters.getSystemList.find(system => system.configKey === 'encryption_type');
        this.$store.commit('setEncryptionType', encryptionTypeConfig?.configValue || 'SHA256'); // 로그인 암호화 타입 설정
      },
    },
    created() {
      this.init(); // 초기 설정
      EventBus.$on('app:progress', this.showLoadPanel);
      EventBus.$on('app:errMsgOn', this.showErrMsg);

      //테마색 리턴해주는 EventBus
      EventBus.$on('app:getColorFromThemeWithOpacity', ({ opacity = 1 }, callback) => {
        callback(this.applyOpacity(this.changedColor(), opacity));
      });

      this.$log.debug('process.env', process.env);
      this.$log.debug('check i18n ::', this.$_i18n);
    },
    mounted() {},
    destroyed() {
      EventBus.$off('app:progress', this.showLoadPanel);
      EventBus.$off('app:errMsgOn', this.showErrMsg);
    },
  };
</script>

<style>
  .dx-loadpanel-wrapper {
    z-index: 2000 !important;
  }

  :root {
    --themeColor: #164371;
    --opacityThemeColor: 42, 59, 171, 0.2;
  }
</style>

<style lang="scss">
  .notification.n-light {
    margin: 10px;
    margin-bottom: 0;
    border-radius: 3px;
    font-size: 13px;
    padding: 10px 20px;
    color: #495061;
    background: #eaf4fe;
    border: 1px solid #d4e8fd;

    .notification-title {
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 10px;
      color: #2589f3;
    }
  }

  .custom-template {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 5px;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;

    &,
    & > div {
      box-sizing: border-box;
    }

    .custom-template-icon {
      flex: 0 1 auto;
      color: #15c371;
      font-size: 32px;
      padding: 0 10px;
    }

    .custom-template-close {
      flex: 0 1 auto;
      padding: 0 20px;
      font-size: 16px;
      opacity: 0.2;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .custom-template-content {
      padding: 10px;
      flex: 1 0 auto;

      .custom-template-title {
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }

  .v-fade-left-enter-active,
  .v-fade-left-leave-active,
  .v-fade-left-move {
    transition: all 0.5s;
  }

  .v-fade-left-enter,
  .v-fade-left-leave-to {
    opacity: 0;
    transform: translateX(-500px) scale(0.2);
  }

  .v-fade-right-enter-active,
  .v-fade-right-leave-active,
  .v-fade-right-move {
    transition: all 0.5s;
  }

  .v-fade-right-enter,
  .v-fade-right-leave-to {
    opacity: 0;
    transform: translateX(500px) scale(0.2);
  }
</style>
