<template>
    <transition>
        <div ref="contentsWrap" class="container contents-wrap" id="contentsWrap">
            <div class="contents-box page-sub-box clearfix ui-glid-box ">
                <table class="table_form line-bin">
                    <caption>
                        <strong>부서 선택</strong>
                    </caption>
                    <colgroup>
                        <col style="width:130px;">
                        <col style="width:auto;">
                    </colgroup>

                    <thead class="sub_title_txt">
                        <tr>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <!-- 1depth -->
                                <DxDataGrid 
                                    :data-source="formDatas.defaultDatas.deptDepth1"
                                    :show-borders="true"
                                    :show-column-headers="true"         
                                    :show-column-lines="false"
                                    :show-row-lines="true"
                                    :row-alternation-enabled="false"
                                    :allow-column-reordering="true"
                                    :no-data-text="noDataTopDepth()"
                                    :width="160"
                                    :height="300"
                                    key-expr="id"
                                    :selected-row-keys="formDatas.selectedIds.deptDepth1"
                                    @selection-changed="selectionChangedDepth1"
                                >
                                    <DxColumn
                                        caption="id"
                                        data-field="id"
                                        :width="80"
                                        alignment="center"
                                        :visible="false"
                                    />
                                    <DxColumn
                                        :caption="deptTitleByDepth('depth1')"
                                        data-field="deptNm"
                                        :width="100"
                                        alignment="left"
                                        :visible="true"
                                    />

                                    <DxSelection mode="single" />
                                    <DxFilterRow :visible="true" >
                                        <DxOperationDescriptions
                                            contains="포함"
                                            notContains="불포함"
                                            equal="="
                                            not-equal="!="
                                            between="between"
                                            start-with="startWith"
                                            ends-with="endsWith"
                                            greater-than=">"
                                            greater-than-or-equal=">="
                                            less-than="<"
                                            less-than-or-equal="<="
                                        />
                                    </DxFilterRow>
                                    <DxScrolling mode="virtual"/>
                                </DxDataGrid>

                                <div class="arrow-box"><span class="mdi mdi-chevron-right mdi-24px"></span></div>

                                <!-- 2depth -->
                                <DxDataGrid 
                                    :data-source="formDatas.selectedByPrevDatas.deptDepth2"
                                    :show-borders="true"
                                    :show-column-headers="true"         
                                    :show-column-lines="false"
                                    :show-row-lines="true"
                                    :row-alternation-enabled="false"
                                    :allow-column-reordering="true"
                                    :width="160"
                                    :height="300"
                                    :no-data-text="noDataText()"
                                    :selected-row-keys="formDatas.selectedIds.deptDepth2"
                                    key-expr="id"
                                    @selection-changed="selectionChangedDepth2"
                                    :disabled="disabledDepth2"
                                >
                                    <DxColumn
                                        caption="id"
                                        data-field="id"
                                        :width="80"
                                        alignment="center"
                                        :visible="false"
                                    />
                                    <DxColumn
                                        :caption="deptTitleByDepth('depth2')"
                                        data-field="deptNm"
                                        :width="100"
                                        alignment="left"
                                        :visible="true"
                                    />

                                    <DxSelection mode="single" show-check-boxes-mode="always" />
                                    <DxFilterRow :visible="true" >
                                        <DxOperationDescriptions
                                            contains="포함"
                                            notContains="불포함"
                                            equal="="
                                            not-equal="!="
                                            between="between"
                                            start-with="startWith"
                                            ends-with="endsWith"
                                            greater-than=">"
                                            greater-than-or-equal=">="
                                            less-than="<"
                                            less-than-or-equal="<="
                                        />
                                    </DxFilterRow>
                                    <DxScrolling mode="virtual"/>
                                </DxDataGrid>

                                <div class="arrow-box"><span class="mdi mdi-chevron-right mdi-24px"></span></div>

                                <!-- 3depth -->
                                <DxDataGrid 
                                    :data-source="formDatas.selectedByPrevDatas.deptDepth3"
                                    :show-borders="true"
                                    :show-column-headers="true"         
                                    :show-column-lines="false"
                                    :show-row-lines="true"
                                    :row-alternation-enabled="false"
                                    :allow-column-reordering="true"
                                    :width="160"
                                    :height="300"
                                    :no-data-text="noDataText()"
                                    :selected-row-keys="formDatas.selectedIds.deptDepth3"
                                    key-expr="id"
                                    @selection-changed="selectionChangedDepth3"
                                    :disabled="disabledDepth2 || disabledDepth3"
                                >
                                    <DxColumn
                                        caption="id"
                                        data-field="id"
                                        :width="80"
                                        alignment="center"
                                        :visible="false"
                                    />
                                    <DxColumn
                                        :caption="deptTitleByDepth('depth3')"
                                        data-field="deptNm"
                                        :width="100"
                                        alignment="left"
                                        :visible="true"
                                    />

                                    <DxSelection mode="single" show-check-boxes-mode="always" />
                                    <DxFilterRow :visible="true" >
                                        <DxOperationDescriptions
                                            contains="포함"
                                            notContains="불포함"
                                            equal="="
                                            not-equal="!="
                                            between="between"
                                            start-with="startWith"
                                            ends-with="endsWith"
                                            greater-than=">"
                                            greater-than-or-equal=">="
                                            less-than="<"
                                            less-than-or-equal="<="
                                        />
                                    </DxFilterRow>
                                    <DxScrolling mode="virtual"/>
                                </DxDataGrid>

                                <!-- <div class="empty-box"></div> -->
                                <div class="arrow-box"><span class="mdi mdi-chevron-right mdi-24px"></span></div>

                                <!-- 4depth -->
                                <DxDataGrid 
                                    :data-source="formDatas.selectedByPrevDatas.deptDepth4"
                                    :show-borders="true"
                                    :show-column-headers="true"         
                                    :show-column-lines="false"
                                    :show-row-lines="true"
                                    :row-alternation-enabled="false"
                                    :allow-column-reordering="true"
                                    :width="160"
                                    :height="300"
                                    :no-data-text="noDataText()"
                                    :selected-row-keys="formDatas.selectedIds.deptDepth4"
                                    key-expr="id"
                                    @selection-changed="selectionChangedDepth4"
                                    :disabled="disabledDepth2 || disabledDepth3 || disabledDepth4"
                                >
                                    <DxColumn
                                        caption="id"
                                        data-field="id"
                                        :width="80"
                                        alignment="center"
                                        :visible="false"
                                    />
                                    <DxColumn
                                        :caption="deptTitleByDepth('depth4')"
                                        data-field="deptNm"
                                        :width="100"
                                        alignment="left"
                                        :visible="true"
                                    />

                                    <DxSelection mode="single" show-check-boxes-mode="always" />
                                    <DxFilterRow :visible="true" >
                                        <DxOperationDescriptions
                                            contains="포함"
                                            notContains="불포함"
                                            equal="="
                                            not-equal="!="
                                            between="between"
                                            start-with="startWith"
                                            ends-with="endsWith"
                                            greater-than=">"
                                            greater-than-or-equal=">="
                                            less-than="<"
                                            less-than-or-equal="<="
                                        />
                                    </DxFilterRow>
                                    <DxScrolling mode="virtual"/>
                                </DxDataGrid>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
        </div>
    </transition>
</template>

<script>
    import {
      DxDataGrid,
      DxSelection,
      DxScrolling,
      DxColumn,
      DxFilterRow,
      DxOperationDescriptions
    } from 'devextreme-vue/data-grid';
    import { isSuccess, cloneObj } from "@/plugins/common-lib";

    export default {
        components: {
            DxDataGrid,
            DxSelection,
            DxScrolling,
            DxColumn,
            DxFilterRow,
            DxOperationDescriptions,
        },
        props:{
            contentData: Object,
            iconData: Array,
        },
        watch: {
        },
        data() {
            return {
                formDatas: {
                    deptDatas: [],
                    //부서 초기 default data
                    defaultDatas: {            
                        deptDepth1: [],
                        deptDepth2: [],
                        deptDepth3: [],
                        deptDepth4: [],
                    },
                    selectedCurrentDepth: null,    //현재 선택한  depth
                    //이전 단계의 선택한 부서에 해당하는 데이터 리스트
                    selectedByPrevDatas: {
                        deptDepth1: [],             //선택된 depth1에 해당하는 데이터
                        deptDepth2: [],             //선택된 depth2에 해당하는 데이터
                        deptDepth3: [],             //선택된 depth3에 해당하는 데이터
                        deptDepth4: [],             //선택된 depth4에 해당하는 데이터
                    },
                    //선택된 부서 데이터
                    selectedDatas: {            
                        deptDepth1: [],
                        deptDepth2: [],
                        deptDepth3: [],
                        deptDepth4: [],
                    },
                    //선택된 부서 id를 담은 데이터(DB에 저장된 데이터를 출력하기 위해 사용)
                    selectedIds: {             
                        deptDepth1: [],
                        deptDepth2: [],
                        deptDepth3: [],
                        deptDepth4: [],
                    }
                },
            }
        },
        computed: {
            /** @description: props 리스트에서 선택한 데이터 */
            getApiActionNm(){
                return this.contentData.apiActionNm;
            },
            /** @description: 부서 타이틀 가져오기 */
            deptTitles(){
                return this.$_getCode('euc_dept_title').filter(d => d.delFl === 'N');
            },
            disabledDepth2() {
                return this.formDatas.selectedByPrevDatas.deptDepth2 === null;
            },
            disabledDepth3() {
                return this.formDatas.selectedByPrevDatas.deptDepth3 === null;
            },
            disabledDepth4() {
                return this.formDatas.selectedByPrevDatas.deptDepth4 === null;
            },
        },
        methods: {
            /** @description: 부서 리스트 조회 */
            async selectDeptList(){
                let payload;
                let res;

                let paramData = { viewFl : 'Y', pagesize: 100000 };
                payload = {
                    actionname: 'DEPT_LIST_ALL_VIEW',
                    data: { params: paramData },
                    loading: true,
                }

                res = await this.CALL_API(payload);
                if( isSuccess(res) ) {
                    let data = res.data.data.map(d => { d.id = d.deptId; return { ...d } });
                    this.formDatas.deptDatas = cloneObj(data);
                    this.formDatas.defaultDatas.deptDepth1 = this.formDatas.deptDatas.filter(d => d.depth === 1);
                    this.formDatas.defaultDatas.deptDepth2 = this.formDatas.deptDatas.filter(d => d.depth === 2);
                    this.formDatas.defaultDatas.deptDepth3 = this.formDatas.deptDatas.filter(d => d.depth === 3);
                    this.formDatas.defaultDatas.deptDepth4 = this.formDatas.deptDatas.filter(d => d.depth === 4);
                }
            },
            /** @description: 뎁스별 부서 타이틀 */
            deptTitleByDepth(depth){
                return this.deptTitles.find(d => d.codeValue === depth ).codeNm;
            },
            /** @description: depth1 로우 선택시 이벤트 */
            selectionChangedDepth1(e){
                this.formDatas.selectedCurrentDepth = 1;
                this.formDatas.selectedDatas.deptDepth1 = e.selectedRowsData;
                let selectedIds = this.formDatas.selectedDatas.deptDepth1.map(d => d.id);
                let depthDatas = this.formDatas.defaultDatas.deptDepth2.filter(d => selectedIds.includes(d.parentId));
                this.formDatas.selectedByPrevDatas.deptDepth2 = depthDatas.length > 0 ? depthDatas : null;
            },
            /** @description: depth2 로우 선택시 이벤트 */
            selectionChangedDepth2(e){
                this.formDatas.selectedCurrentDepth = 2;
                this.formDatas.selectedDatas.deptDepth2 = e.selectedRowsData;
                let selectedIds = this.formDatas.selectedDatas.deptDepth2.map(d => d.id);
                let depthDatas = this.formDatas.defaultDatas.deptDepth3.filter(d => selectedIds.includes(d.parentId));
                this.formDatas.selectedByPrevDatas.deptDepth3 = depthDatas.length > 0 ? depthDatas : null;
            },
            /** @description: depth3 로우 선택시 이벤트 */
            selectionChangedDepth3(e){
                this.formDatas.selectedCurrentDepth = 3;
                this.formDatas.selectedDatas.deptDepth3 = e.selectedRowsData;
                let selectedIds = this.formDatas.selectedDatas.deptDepth3.map(d => d.id);
                let depthDatas = this.formDatas.defaultDatas.deptDepth4.filter(d => selectedIds.includes(d.parentId));
                this.formDatas.selectedByPrevDatas.deptDepth4 = depthDatas.length > 0 ? depthDatas : null;
            },
            /** @description: depth4 로우 선택시 이벤트 */
            selectionChangedDepth4(e){
                this.formDatas.selectedCurrentDepth = 4;
                this.formDatas.selectedDatas.deptDepth4 = e.selectedRowsData;
            },
            /** @description: 최상단 데이터가 없을 경우 출력 */
            noDataTopDepth(){
                return `해당 데이터를 불러오는 중입니다.`;
            },
            /** @description: 데이터가 없을 경우 출력 */
            noDataText(){
                return `상위 조직을 선택하세요.`;
            },
            /** @description : 라이프사이클 creaed시 호출되는 메서드 */
            async createdData(){

                await this.selectDeptList();  //부서 정보 조회

                this.formDatas = {...this.formDatas, ...this.contentData};
            },
            getSelectedDept() {
              const deptData = this.formDatas.selectedDatas[`deptDepth${this.formDatas.selectedCurrentDepth}`][0];
              return { siteId: deptData.siteId, tenantId: deptData.tenantId, deptId: deptData.id };
            },
            /** @description : 라이프사이클 mounted시 호출되는 메서드 */
            mountedData(){
                this.$_eventbus.$on('ModalSelectDept:onSaveData', async(e, resolve) => {
                    let deptData = this.formDatas.selectedDatas[`deptDepth${this.formDatas.selectedCurrentDepth}`][0];

                    resolve({ status: 200, rst: { deptDatas: this.formDatas.selectedDatas, siteId: deptData.siteId, tenantId: deptData.tenantId, deptId: deptData.id } });
                    
                });
            },
            /** @description : 라이프사이클 destroyed시 호출되는 메서드 */
            destroyedData(){
                this.$_eventbus.$off('ModalSelectDept:onSaveData');
            },
        },
        created() {
            this.createdData();
        },
        mounted() {
            this.mountedData();
        },
        destroyed() {
            this.destroyedData();
        },
    }
</script>

<style scoped>
    .contents-title-box { height: 40px; position: relative; border-bottom: 1px solid #CCC; line-height: 40px; }
    .contents-title-box .contents-title { height: 40px; padding-left: 5px; display: inline-block; font-size: 0.9em; }
    
    .contents-box { width: 100%; padding: 20px 10px; background-color: #FFF; }

    .table_form td > div { display: inline-block; vertical-align: middle; }

</style>
<style >
    

</style>