export default {
  COMPONENTS: {
    ADD: '추가',
    CANCEL: '취소',
    CLOSE: '닫기',
    COPY: '복사',
    DELETE: '삭제',
    DELETE_SELECTED: '선택 삭제',
    DETAIL: '상세보기',
    DOWNLOAD: '다운로드',
    DUPLICATE_SELECTED: '선택 복사',
    EDIT: '수정',
    FIRST_PAGE: '첫페이지',
    LAST_PAGE: '마지막페이지',
    MOVE: '이동',
    NEXT: '다음',
    NEXT_PAGE: '다음페이지',
    PREV: '이전',
    PREV_PAGE: '이전페이지',
    REFRESH: '새로고침',
    SAVE: '저장',
    APPROVAL: '승인',
    COMPLETE: '완료',
    SEARCH: '검색',
    SEARCH_RESULT: '검색 결과',
    SELECT: '선택',
    SELECT_ALL: '전체선택',
    UNSELECT_ALL: '전체해제',
    UPDATE: '변경',
    UPLOAD: '업로드',
    TITLE: '제목',
    NAME: '이름',
    DATE: '날짜',
    DEPT: '부서',
    GROUP: '그룹',
    TYPE: '구분',
    DESCRIPTION: '설명',
    ALL: '전체',
    USE_STATUS: '사용여부',
    ORDER: '순서',
    REGISTRANT: '등록자',
    REGISTRATION_DATE: '등록일',
    REGISTRATION_DATE_TIME: '등록일시',
    MODIFIER: '수정자',
    MODIFY_DATE: '수정일',
    MODIFY_DATE_TIME: '수정일시',
    TARGET: '대상',
    NON_TARGET: '비대상',
    OTHERS: '기타',
    GROUP_NAME: '그룹명',
    INDIVIDUAL: '개인',
    CONTAINS: '포함',
    BETWEEN: '사이',
    ENDS_WITH: '으로 끝남',
    EQUAL: '동일',
    GREATER_THAN: '보다 큼',
    GREATER_THAN_OR_EQUAL: '보다 크거나 같음',
    LESS_THAN: '보다 작음',
    LESS_THAN_OR_EQUAL: '보다 작거나 같음',
    NOT_CONTAINS: '포함하지 않음',
    NOT_EQUAL: '동일하지 않음',
    STARTS_WITH: '으로 시작함',
    IS_BLANK: '비어있음',
    IS_NOT_BLANK: '비어있지 않음',
    AND: '그리고',
    OR: '또는',
    NOT_AND: '그리고 아님',
    NOT_OR: '또는 아님',
    EXPAND_LIST: '목록 펼치기',
    COLLAPSE_LIST: '목록 접기',
    DOWNLOAD_REASON: '다운로드 사유',
    SEARCH_PERIOD: '검색기간',
    SEARCH_DATE: '조회 기간',
    SEARCH_TARGET: '조회 대상',
    FILE: '파일',
    FILE_SELECT: '파일 선택',
    METHOD: '메서드',
    RESULT: '결과',
    PHONE_NUMBER: '전화번호',
    GRADE: '직위',
    PERSONAL_INFORMATION: '인사정보',
    PERSONAL_MANAGEMENT: '인사관리',
    PATH: '경로',
    USER_STATE: '계정 상태',
    CATEGORY: '카테고리',
    MOVE_TO_TOP: '맨 위로 이동',
    MOVE_UP: '위로 이동',
    MOVE_DOWN: '아래로 이동',
    MOVE_TO_BOTTOM: '맨 아래로 이동',
    SAVE_ORDER: '순서 저장',
    SETTING: '설정',
    NOT_SETTING: '미설정',
    ADD_SETTING: '설정 추가',
    REMOVE_SETTING: '설정 해제',
    RESET: '초기화',
    DROP_FILE: '또는 여기에 파일을 드롭하세요',
    PRE_LISTENING: '미리듣기',
    LISTENING: '듣기',
    LOGIN: '로그인',
    VIEW_STATUS: '표시여부',
    SHOW_VIEW_FL: '사용중인 {value}만 보기',
    ITEM: '항목',
    STEP: '단계',
    EXCEL_DOWNLOAD: '엑셀 다운로드',
    ALL_DOWNLOAD: '전체 다운로드',
    SELECTED_DATA_DOWNLOAD: '선택한 데이터 다운로드',
    SUM: '합계',
    AVG: '평균',
    COUNT: '개수',
    MIN: '최소',
    MAX: '최대',
    ADD_ROW: '행 추가',
    COPY_ROW: '행 복사',
    CELL_CLIPBOARD: '열 클립보드',
    ROW_CLIPBOARD: '행 클립보드',
    LOAD: '조회',
  },
  COMMON: {
    MESSAGE: {
      SYSTEM_ERROR: '시스템 오류 입니다. 관리자에게 문의하세요.',
      CHECK_DEPT_DEPTH: '부서 추가는 4단계까지만 가능합니다.',
      CHECK_MENU_DEPTH: '메뉴추가는 4단계까지만 가능합니다.',
      CHANGED_ACTIVE: '선택한 계정을 활성 상태로 변경되었습니다.',
      CMN_CFM_DELETE: '삭제하시겠습니까?',
      CMN_CFM_DELETE_SELECTED: '선택한 데이터를 삭제하시겠습니까?',
      CMN_CFM_DUPLICATE_SELECTED: '선택한 데이터를 복사하시겠습니까?',
      CMN_CFM_SAVE: '저장하시겠습니까?',
      CMN_ERROR: '데이터 처리 중 오류가 발생하였습니다.',
      CMN_ERROR_INTERNAL_SERVER: '서버 오류 입니다.',
      CMN_ERROR_TIMEOUT: '설정된 요청 시간({timeout})ms 보다 초과되었습니다.',
      CMN_NO_ADD: '데이터를 더이상 추가할 수 없습니다.',
      CMN_NO_CHANGED: '변경된 데이터가 없습니다.',
      CMN_NO_DATA: '데이터가 없습니다.',
      CMN_NOT_SELECTED: '대상이 선택되어 있지 않습니다.',
      CMN_ONE_SELECT: '하나의 대상만 선택해주세요.',
      CMN_PREPARING: '준비중입니다.',
      CMN_SUC_DELETE: '정상적으로 삭제되었습니다.',
      CMN_SUC_SAVE: '정상적으로 저장되었습니다.',
      CMN_SUC_UPDATE: '정상적으로 변경되었습니다.',
      CMN_SUC_UPLOAD: '정상적으로 업로드되었습니다.',
      CMN_SUCCESS: '정상적으로 처리되었습니다.',
      DISALLOWED_FILE: '허용하지 않은 파일 형식입니다.',
      EXIST_ACCOUNT: '선택한 대상에 이미 계정이 존재합니다.',
      EXIST_PERSONAL: '선택한 재직상태에 해당하는 인사정보 대상이 존재합니다.',
      EXIST_UNCHANGED_DATA: '변경되지 않은 데이터가 있습니다.',
      CANNOT_BE_DEL_ACCOUNT_AUTH: '사용자에게 부여된 권한이 있어 삭제가 불가능합니다.',
      OVER_SIZE_FILE: '파일이 허용된 크키({size})를 초과하여 업로드 할 수 없습니다.',
      OVER_LICENSE_COUNT: '등록 가능한 라이센스 수를 초과하여<br/>계정을 더 이상 등록할 수 없습니다.',
      PWD_INVALID: '유효하지 않는 비밀번호 입니다.',
      PWD_NON_EMPTY: '비밀번호는 공백 없이 입력해주세요.',
      PWD_NON_KR: '비밀번호에 한글을 사용 할 수 없습니다.',
      REQUIRED_VALUE: '필수값 입니다.',
      REQUIRED_VALUE_IS: '[{value}] 은/는 필수값 입니다.',
      REQUIRED_FOR_CHILD_CODE: '하위 코드 값을 입력하셔야 합니다.',
      REQUIRED_IF_CHILD_CODE_PRESENT: '하위 코드 값을 입력하셨다면,<br/>하위 코드명 데이터를 추가하셔야 합니다.',
      REQUIRED_DEPT_SEARCH: '검색대상노출의 부서 선택은 필수입니다.',
      REQUIRED_DEPT_ADMIN: '사용자유형이 "부서관리자"인 경우<br/>부서 선택은 필수입니다.',
      REQUIRED_FILE_ADDITION: '업로드할 파일을 추가해주세요.',
      REQUIRED_PARENT_CODE: '상위 코드명 데이터를 추가하셔야 합니다.',
      REQUIRED_PARENT_DEPT: '하위부서를 추가할 상위부서를 선택하세요.',
      REQUIRED_PARENT_MENU: '하위메뉴를 추가할 상위메뉴를 선택하세요.',
      REQUIRED_SELECT_USER: '사용자를 선택해주세요.',
      REQUIRED_SELECT_DEPT: '부서를 먼저 선택해주세요.',
      REQUIRED_DATE: '날짜를 선택하세요.',
      SEARCH_DATE_RANGE_ERROR: '종료일은 시작일보다 크거나 같아야 합니다.',
      REQUIRED_DOWNLOAD_REASON: '다운로드 사유를 입력하세요.',
      INVALID_DATE_FORMAT: '날짜 형식이 올바르지 않습니다.',
      PLEASE_SELECT_DATE: '날짜를 선택해주세요.',
      SEARCH_TIME_MAX_RANGE_ERROR: '시작시간은 종료시간보다 작거나 같아야 합니다.',
      SEARCH_TIME_MIN_RANGE_ERROR: '종료시간은 시작시간보다 크거나 같아야 합니다.',
      REQUIRED_VALUE_VALIDATION_ERROR: '필수값을 입력해주세요.',
      PLEASE_SAVE_THE_CHANGES: '변경 사항을 저장하세요.',
      EMPLOYEE_NUMBER_ALREADY_IN_USE: '이미 사용중인 사번입니다.',
      ERROR_AUTH_DATA_FETCH: '권한 데이터 조회 오류',
      NUMBER_ENTER: '숫자만 입력하세요.',
      PLEASE_SELECT_TARGET: '대상을 선택해주세요.',
      PLEASE_SELECT_TO: '{target}을/를 선택해주세요.',
      PLEASE_SELECT_SETTING: '설정을 선택해주세요.',
      CALL_API_FAIL: '데이터 불러오기 실패. 원인 : [{message}]',
      ACTIVATE_SELECTED_ACCOUNT: '선택한 계정을 활성 상태로 전환하시겠습니까?',
      PASSWORD_MISMATCH: '패스워드가 일치하지 않습니다. 다시 확인하세요.',
      NOT_DUPLICATE_VALUE_IS: '[{value}] 값은 중복될 수 없습니다.',
      FORGOT_PASSWORD: '패스워드를 분실한 경우에는 관리자에게 문의하세요.',
      ENTER_ID: '아이디를 입력하세요.',
      ENTER_PASSWORD: '비밀번호를 입력하세요.',
      CONFIRM_DELETE_WITH_SUB_PERMISSIONS: '삭제 시 하위권한까지 일괄 삭제됩니다. <br>삭제하시겠습니까?',
      WARNING_FORCE_LOGOUT: '잠시후에 자동 로그아웃됩니다. 로그인을 계속 유지하시겠습니까?',
      FORCE_LOGOUT: '일정한 시간동안 미사용하여 로그아웃 되었습니다.',
    },
    WORD: {
      ADD_MAIN_SETTING: '메인설정 추가',
      SETTING_TARGET: '설정 대상',
      MAIN_PAGE_TYPE: '메인 페이지 타입',
      MAIN_PAGE_ID: '메인 페이지 ID',
      AUTH: '권한',
      LOGIN_ID: '아이디',
      SITE: '사이트',
      SITE_NAME: '사이트명',
      TENANT: '센터',
      TENANT_NAME: '센터명',
      CONTENT: '내용',
      LOGIN: '로그인',
      LOGOUT: '로그아웃',
      PASSWORD: '비밀번호',
      SAVE_ID: '아이디 저장',
      TYPE: '유형',
      HOLIDAY_NAME: '공휴일명',
      HOLIDAY: '공휴일',
      LOAD_HOLIDAY: '공휴일 불러오기',
      COMMON: '공통',
    },
  },
  UC: {
    MESSAGE: {
      APPROVED: '승인 처리되었습니다.',
      UNSAVED: '저장되지 않은 데이터가 있습니다.',
      CHECK_PICKUP_DEPT: '그룹명은 `PG-D_`로 시작되야 합니다.',
      CHECK_PICKUP_ETC: '그룹명은 `PG-P_`로 시작되야 합니다.',
      CHECK_ADD_COUNT: '항목 추가는 10개까지 가능합니다.',
      CHECK_EXCHANGER_TYPE: '교환기는 한종류만 입력가능합니다.',
      START_SYNC: 'DB데이터를 동기화 하시겠습니까?',
      CHANGE_LIST_ORDER: '헌트리스트 순서도 같이 변경됩니다.',
      REQUIRED_SELECT_HUNTPILOT: '헌트파일럿을 먼저 선택해주세요.',
      REQUIRED_SELECT_HUNTLIST: '헌트리스트를 먼저 선택해주세요.',
      REQUIRED_SELECT_LINEGROUP: '회선그룹을 먼저 선택해주세요.',
      PILOT_LIST_ONE_SELECT: '파일럿 / 리스트는 한건씩만 선택해주세요.',
      PILOT_LIST_MISS_MATCH: '해당 헌트리스트는 파일럿에 속하지않습니다. ',
      DESCRIPTION_ENTER: '설명을 입력하세요.',
      NUMBER_ENTER: '숫자만 입력하세요.',
      CHANGED_DATA: '변경된 값이 있습니다.',
      REQUIRED_EXCHANGER_CHECK: '교환기 체크를 먼저 진행해주세요.',
      ENCRYPTED_FILE_UPLOAD_NOT_ALLOWED: '암호화된 파일은 업로드 할 수 없습니다.',
      DN_ENTER: '내선번호를 입력하세요.',
      DEVICE_NAME_ENTER: '장치 이름을 입력하세요.',
      CHECK_MAC_ADDR: '동일한 장치이름이 존재합니다.',
      NO_PERSON_INFO: '로그인한 유저의 인사정보가 없습니다.',
      REQUIRED_TITLE: '제목을 입력해주세요.',
      REQUIRED_APPROVER: '승인자를 선택해주세요.',
      REQUIRED_REQUEST_ITEMS: '신청 항목을 입력해주세요.',
      PLACEHOLDER_EXISTENCE_CONFIRMATION_QUERY: '존재 확인 SQL을 입력해 주세요.',
      PLACEHOLDER_DELETE_QUERY: '삭제 SQL을 입력해 주세요.',
      PLACEHOLDER_FILE_DELETE_DEFAULT_PATH: '파일 삭제시 기본 패스( /home/ecss/api/file 이후, Ex : /report/excel )',
      MAIN_SETTING_MENU_TYPE_ERROR: '메뉴타입이 올바르지 않습니다. (일반페이지, 보고서만 가능합니다.)',
    },
    WORD: {
      DUE_DATE: '완료 희망일',
      USER_NO: '사번',
      REQUESTER: '신청자',
      APPROVER: '승인자',
      APPROVAL_DATE: '승인일',
      APPROVAL_FL: '승인여부',
      WORKER: '작업자',
      REQUESTER_INFO: '신청자 정보',
      USER_SELECT: '사용자 선택',
      APPROVAL_SELECT: '승인자 선택',
      WORKER_SELECT: '작업자 선택',
      APPLY_TARGET: '적용대상',
      TYPE_MANAGEMENT: '유형 관리',
      DETAILED_TYPE: '상세 유형',
      DETAILED_TYPE_MANAGEMENT: '상세 유형 관리',
      DETAILED_TYPE_COUNT: '상세 유형의 수',
      TYPE: '유형',
      TYPE_NAME: '유형명',
      REQUEST_TYPE: '신청 유형',
      AFTER_LINE: '후선',
      DEPT_ATTRIBUTES: '부서 속성',
      BLOCKING_POLICY: '차단 정책',
      EXCEPTION_POLICY: '예외 정책',
      EXCEPTION_SETTING: '예외 설정',
      ALLOW: '허용',
      BLOCK: '차단',
      EXCHANGER: '교환기',
      DEPT_NAME: '부서명',
      DEPT_CODE: '부서코드',
      PATTERN: '패턴',
      PARTITION: '파티션',
      DEPT_NAME_SEARCH: '부서명 검색',
      UNUSED: '사용 중지',
      VIEW_DEPT_USE: '사용중 부서만 보기',
      CREATE_DEPT_PICKUP: '부서별 당겨받기 생성',
      DEPT_PICKUP_INFO: '부서별 당겨받기 정보',
      CREATE_ETC_PICKUP: '개별 당겨받기 생성',
      CREATE_DEPT: '부서별 생성',
      PICKUP_MANAGE: '당겨받기 관리',
      REQUESTER_SELECT: '요청자 선택',
      SENDER: '발신자',
      SENDER_NUMBER: '발신자 번호',
      SENDER_DEPT: '발신자 부서',
      RECEIVER: '수신자',
      RECEIVER_NUMBER: '수신자 번호',
      RECEIVER_DEPT: '수신자 부서',
      BLOCK_DATE: '차단일',
      BLOCK_END_DATE: '차단 종료일',
      BLACK_LIST: '블랙리스트',
      BLACK_LIST_HISTORY: '블랙리스트 이력',
      INDEFINITE: '무기한',
      PROGRESS_STATUS: '진행 상태',
      DISTRIBUTIONALGORITHM: '배포 알고리즘',
      RNAREVERSIONTIMEOUT: 'RNA 복귀시간 초과',
      HUNTGROUP_SYNC: '헌트그룹 동기화',
      DEVICE_SYNC: '장치 동기화',
      LINE_SYNC: '내선 동기화',
      LINEGROUPDN_SET: '회선번호 설정',
      PILOT_LIST_SET: '파일럿/리스트 추가',
      LIST_LINEGROUP_SET: '리스트/회선그룹 설정',
      LINEGROUP_ADD: '회선그룹 추가',
      CHECK: '조회',
      HUNTPILOT: '파일럿',
      HUNTLIST: '리스트',
      LINEGROUP: '회선그룹',
      LINEGROUPDN: '회선번호',
      SAVE_ORDER: '순서저장',
      TOTAL: '총',
      COUNT: '개',
      ALL_LIST: '전체리스트',
      PREPAGE: '이전페이지',
      MODEL: '모델',
      STATUS: '상태',
      DEVICE_INFO: '전화 정보',
      DEVICE_INFO_DETAIL: '전화 정보 상세',
      LINE_INFO: '회선 정보',
      DEVICE_NAME: '장치이름',
      DEVICEPOOL: '장치풀',
      SOFTKEY: '소프트키',
      NUMBER: '내선번호',
      LABEL: '레이블',
      SERIAL: '시리얼',
      PHONEBUTTON: '버튼',
      APPLY: '적용',
      EXPANSION_MODULE: '확장모듈',
      EXPANSION_MODULE_SET: '확장모듈 설정',
      PICKUPGROUP_SET: '당겨받기 설정',
      LINE_SET: '전화번호 설정',
      CSS: '발신검색',
      FORWARDNO: '착신전환',
      PICKUPGROUP: '당겨받기',
      REC: '녹취',
      REC_PERMISSION: '녹취 권한',
      EXCEL_DOWNLOAD: '엑셀 다운로드',
      EXCEL_UPLOAD: '엑셀 등록',
      EXCHANGER_SELECT: '교환기 조회',
      EXCHANGER_CHECK: '교환기 체크',
      EXCHANGER_APPLY: '교환기 등록',
      INDEX: '순번',
      LINE_ADD: '내선번호 추가',
      TARGET_DEVICE: '장치 대상',
      NEW_DEVICE: '신규 장치',
      CONTACT: '주소록',
      ALERTINGNAME: '표시(경고이름)',
      EXTERNALCALLCONTROLPROFILE: '외부통화제어',
      DISPLAY: '화면',
      DISPLAY_NAME: '화면표시',
      FIRST_NAME: '이름',
      MIDDLE_NAME: '중간이름',
      LASG_NAME: '성',
      CSF: 'PC JABBER',
      BOT: '안드로이드',
      TCT: '아이폰',
      TAB: '태블릿',
      CHECK_EXISTING_DEVICE: '기존 단말 삭제 여부',
      GROUP_NUMBER: '그룹번호',
      REG_NUMBER: '등록 내선',
      LICENSE_STATUS: '라이센스 현황',
      LICENSE_HISTORY: '라이센스 이력',
      LICENSE: '라이센스',
      APUSRASSIGNEDMODEL: '유저등록모델',
      SMARTASSIGNEDMODEL: '스마트등록모델',
      MODEL_SYNC: '모델 동기화',
      LICENSE_SYNC: '라이센스 동기화',
      SYNC: '동기화',
      CALL_MANAGER_GROUP: '콜매니저그룹',
      REGION: '지역',
      DATETIME_SETTING: '시간대',
      CREATE: '생성',
      WORK_ID: '작업 ID',
      RECENT_WORK_HISTORY: '작업 이력',
      EXCEL_FILE: '엑셀 파일',
      OTHERS_FILE: '기타 파일',
      ASCII_ALERTINGNAME: 'Ascii 경고이름',
      HUNTINFO: '헌트정보',
      PILOT_DESCRIPTION: '파일럿 설명',
      LIST_DESCRIPTION: '리스트 설명',
      AUTOMATION_MANAGEMENT: '자동화 관리',
      AUTOMATION_SETTING: '자동화 설정',
      AUTOMATION_CODE: '자동화 코드',
      TRANSFORM_NUMBER: '전환번호',
      EXISTENCE_CONFIRMATION_QUERY: '존재 확인 질의문',
      DELETE_QUERY: '삭제 질의문',
      FILE_DELETE_FLAG: '파일 삭제 유무',
      STORAGE_PERIOD: '보관기간 (개월)',
      DATA_MANAGEMENT_HISTORY: '데이터 관리 이력',
      HOT_LINE: '직통번호',
      MEMO: '메모',
      START_DATE: '시작일',
      END_DATE: '종료일',
      FORWARD_NUMBER: '착신번호',
      RESET: '리셋'
    },
  },
  EWM: {
    MESSAGE: {
      WORK_SCHEDULE_CANNOT_MODIFY_PAST_DATES: '과거 날짜는 근무변경 할 수 없습니다.',
      SELECT_COUNSELOR_TO_MODIFY: '수정할 상담원을 선택해주세요.',
      CONFIRM_REVOKE_ORDER: '발령을 취소하시겠습니까?',
      REQUIRED_AGENT_ID: '상담원ID를 입력하세요.',
      REQUIRED_AGENT_NM: '상담원명을 입력하세요.',
      REQUIRED_AGENT_DN: '내선번호를 입력하세요.',
      REQUIRED_DEPT: '부서를 선택하세요.',
      REQUIRED_JIKGUP: '직급을 선택하세요.',
      REQUIRED_JIKWEE: '직책를 선택하세요.',
      REQUIRED_AUTH_ID: '계정 권한을 선택하세요.',
      REQUIRED_WORKGROUP: '업무그룹을 선택하세요.',
      REQUIRED_USED_CTI_FL: 'CTI 연동 여부를 선택하세요.',
      REQUIRED_LOGIN_FL: '로그인 연동 여부를 선택하세요.',
    },
    WORD: {
      ATTENDANCE_STATUS_NORMAL: '정상',
      ATTENDANCE_STATUS_LATE: '지각',
      ATTENDANCE_STATUS_EARLY_LEAVE: '조퇴',
      ATTENDANCE_STATUS_ABSENCE: '결근',
    },
  },
  LLM: {
    MESSAGE: {
      WARNING_DELETE_PROJECT: '프로젝트 삭제시 모든 데이터가 함께 삭제됩니다. <br>삭제하시겠습니까?',
      UNSAVED_PROMPT_CHANGE_ALERT: '작업한 프롬프트 내용이 저장되지 않았습니다. <br>변경 하시겠습니까?',
      UNSAVED_PROMPT_MOVE_ALERT: '작업한 프롬프트 내용이 저장되지 않았습니다. <br>이동 하시겠습니까?',
      SAVE_SYSTEM_PROMPT: '시스템 프롬프트를 저장하시겠습니까?',
      SAVE_EVALUATION_PROMPT: '평가 프롬프트를 저장하시겠습니까?',
      PLEASE_ADD_PROMPT: '등록된 프롬프트가 없습니다. 새 프롬프트를 추가해주세요.',
      NEW_PROMPT_ADD_ALERT: '선택한 프롬프트를 수정하지 않고 새로운 프롬프트로 생성됩니다.',
      NEW_PROMPT_NAME_ALERT: '새로운 프롬프트의 제목을 입력하세요.',
      DUPLICATE_INDEX_FILE: '이미 중복된 파일이 등록되어 있습니다.',
      DELETE_INDEX_FILE_ALERT1: '인덱스 파일 삭제 시, 질의에 사용된 근거 문서도 함께 삭제 됩니다.',
      DELETE_INDEX_FILE_ALERT2: '선택한 {value}개 파일을 삭제하시겠습니까?',
      UNSET_PROMPT: '프롬프트가 선택되어 있지 않습니다.',
      UNSET_CONFIG: '설정값이 존재하지 않습니다.',
      PLEASE_ADD_INDEX: '등록된 인덱스가 없습니다. 새 인덱스를 추가해주세요.',
      DONT_EXIST_FILE: '선택할 근거 문서가 없습니다.',
      DONT_EXITS_SEARCH_RESULT: '검색 결과가 존재하지 않습니다.',
      SAVE_QUERY: '질의를 저장 하시겠습니까?',
      UNSELECT_SEARCH_DOCUMENT_ALERT: '문서 검색 해제시 근거 문서가 초기화 됩니다. <br/>해제 하시겠습니까?',
      INPUT_1_TO_2000: '1부터 2000까지의 정수만 입력 가능합니다.',
      RUNNING_TEST_ALERT1: '테스트 작업 진행에는 다소 시간이 걸릴 수 있습니다.',
      RUNNING_TEST_ALERT2: '테스트를 실행하시겠습니까?',
      CANCEL_TEST_ALERT: '해당 테스트를 취소하시겠습니까.',
      SUC_CANCEL: '정상적으로 취소되었습니다.',
    },
    WORD: {
      PROJECT: '프로젝트',
      PROJECT_ID: '프로젝트 아이디',
      WORK_CONFIG: '작업 설정',
      WORK: '작업',
      RUNNING_TEST: '테스트 실행',
      GO_TO_LIST: '목록 가기',
      TEST_WORK: '테스트 작업',
      DETAIL: '보기',
      PROMPT: '프롬프트',
      SYSTEM_PROMPT: '시스템 프롬프트',
      EVALUATION_PROMPT: '평가 프롬프트',
      PROMPT_ADD: '새 프롬프트 추가',
      SYSTEM_PROMPT_ADD: '새 시스템 프롬프트 추가',
      EVALUATION_PROMPT_ADD: '새 평가 프롬프트 추가',
      SELECT_PROMPT: '프롬프트 선택',
      QUERY_CONFIG: '질의 설정',
      INDEX_CONFIG: '인덱스 관리',
      QUERY_TYPE: '질문 유형',
      QUERY: '질문',
      CORRECT_ANSWER: '올바른 답변',
      SUPPORTING_DOCUMENT: '근거문서',
      SEARCH_DOCUMENT: '문서 검색',
      REPEAT_COUNT: '반복 횟수',
      SYNTHETIC_OPTION: '변형 옵션',
      SUBSTANTIVE_SYNONYM: '체언 유의어',
      SUBSTANTIVE_TYPO: '체언 오타',
      PREDICATE_SYNONYM: '용언 유의어',
      PREDICATE_TYPO: '용언 오타',
      PREDICATE_ENDING: '용언 어미',
      SYNTHETIC_COUNT: '변형 횟수',
      QUERY_ADD: '질의 등록',
      QUERY_UPDATE: '질의 수정',
      COPY_ADD: '새로 저장',
      CONTENT: '내용',
      MODEL_CONFIG: '모델 설정',
      MODEL: '모델',
      WORK_NAME: '작업 명칭',
      RUN: '실행',
      PROJECT_WORK_RUNNING: '프로젝트 작업 실행',
      TEST_NAME: '테스트 명칭',
      STATUS: '상태',
      CANCEL_TEST: '테스트 취소',
      RESULT: '결과',
      TEST_END_DATE_TIME: '테스트 종료일시',
    },
  },
  FORECAST: {
    MESSAGE: {
      CHECK_30_DAYS: '최대 30일 조회만 가능합니다.',
      CHECK_12_MONTHS: '최대 12개월 조회만 가능합니다.',
      PLEASE_SELECT_SKILL: '스킬별 조회시 스킬을 선택하여 주세요.',
      FORECAST_EVENT_INFO_MESSAGE1: '예측값과 실제값 사이에 큰 차이가 발생하는 경우에는 이벤트로 간주됩니다.',
      FORECAST_EVENT_INFO_MESSAGE2: '사용자가 미리 이벤트로 판단되는 일자를 추가할 수도 있습니다.',
      FORECAST_EVENT_INFO_MESSAGE3: '이벤트의 원인을 분석하고 유형별로 관리하면, 콜 예측의 정확성을 더욱 높일 수 있습니다.',
      FORECAST_MODEL_INFO_MESSAGE1: '일일 예측이 5회 연속으로, 월 예측이 3회 연속으로 실제 값과 현저한 차이를 보일 경우 예측 모델이 자동으로 변경됩니다.',
      FORECAST_MODEL_INFO_MESSAGE2: '인입 이력 차트에서는 연속 실패 시점을 포함해 지난 2주간의 유입 현황을 확인할 수 있습니다.',
      REQUIRED_EVENT_TYPE: '이벤트 등록 시 이벤트 유형은 필수 입니다.',
      PLEASE_SELECT_EVENT_TYPE: '이벤트 유형을 선택하여 주세요.',
    },
    WORD: {
      FORECASTED_OFFER: '예측 콜',
      ACTUAL_OFFER: '실제 콜',
      FULL_TIME_EQUIVALENT: '예측 근무 시간',
      EVENT_TYPE: '이벤트 유형',
      SELECT_EVENT_TYPE: '이벤트 유형 선택',
      EVENT_DATE: '이벤트 날짜',
      ADDED_DATE: '추가 일자',
      DAILY: '일별',
      MONTHLY: '월별',
      BY_SKILL: '스킬별',
      SKILL_GROUP: '스킬 그룹',
      SEARCHING_FORECAST: '예측 조회',
      FORECAST_CYCLE: '예측 주기',
      REPLACEMENT_DATE: '교체 날짜',
      FORECAST_FAIL_PERIOD: '예측 실패 기간',
      AVERAGE_ACCURACY: '평균 정확도',
      MODEL: '모델',
      HYPERPARAMETERS: '하이퍼 파라미터',
      OFFER_HISTORY: '인입 이력',
      FORECAST_FAIL_OFFER_HISTORY: '예측 실패 인입 이력',
      EXCEL: '엑셀',
      ALL_EVENTS_HISTORY: '이벤트 전체 이력',
    },
  },
  CC: {
    MESSAGE: {
      INPUT_GROUP_NAME: '그룹명을 입력해주세요.',
      REQUIRED_GROUP_NAME: '그룹명은 필수입니다.',
      SELECT_GROUP: '그룹을 선택해주세요.',
      DUPLICATE_NAME: '이미 등록되어 있는 명칭입니다.',
      CFM_UPDATE_INFO: '정보를 수정하시겠습니까?',
      CFM_DELETE_SELECTED_WITH_CHILD: '선택한 데이터를 삭제하시겠습니까?<br/>하위 데이터도 함께 삭제됩니다.',
      RESET_IBG: '인입그룹 설정 초기화',
      RESET_MAIN_NUMBER: '대표번호 설정 초기화',
      NO_DELETE_DNIS_NOTICE: '대표번호에 게시 중인 공지사항이 있어 삭제할 수 없습니다.',
      NO_DELETE_DNIS_SCHEDULE: '대표번호에 게시 중인 운영 스케줄이 있어 삭제할 수 없습니다.',
      START_DATE_OUT_OF_RANGE: '시작일은 종료일보다 작거나 같아야 합니다.',
      END_DATE_OUT_OF_RANGE: '종료일은 시작일보다 크거나 같아야 합니다.',
      START_TIME_OUT_OF_RANGE: '시작시간은 종료시간보다 작거나 같아야 합니다.',
      END_TIME_OUT_OF_RANGE: '종료시간은 시작시간보다 크거나 같아야 합니다.',
      NO_DISABLED_NOT_USED_DNIS_NOTICE: '대표번호에 설정된 공지사항은 미사용 상태로 변경할 수 없습니다.',
      NO_DISPLAY_USED_DNIS_NOTICE: '이미 다른 공지사항이 설정된 대표번호는<br>목록에서 노출되지 않습니다.',
    },
    WORD: {
      GROUP_INFO: '그룹정보',
      TH_GROUP_NAME: '차 그룹명',
      GROUP_NAME: '그룹명',
      CHILD_GROUP: '하위 그룹',
      CHILD: '하위',
      REGISTER_1ST_GROUP: '1차 그룹 등록',
      REGISTER_2RD_GROUP: '2차 그룹 등록',
      ADD_IBG: '인입그룹 추가',
      ADD_TEAM: '팀 추가',
      ADD_SKL: '상담그룹 추가',
      IBG: '인입그룹',
      IBG_NAME: '인입그룹명',
      TEAM: '팀',
      TEAM_NAME: '팀명',
      SKL: '상담그룹',
      SKL_NAME: '상담그룹명',
      ATTRIBUTE: '속성',
      ATTRIBUTE_NAME: '속성명',
      CONTACT_TYPE: '컨택타입',
      CONTACT_TYPE_NAME: '컨택타입명',
      NREASON_CODE: '이석사유코드',
      NREASON_NAME: '이석사유명',
      NREASON_FIELD: '이석사유필드',
      ERS_NAME: 'ERS 이름',
      CTI_NAME: 'CTI 이름',
      VIEW_DNIS_USE: '사용중인 DNIS만 보기',
      OPERATION_SETTINGS: '운영 설정',
      IBG_SETTINGS: '인입그룹 설정',
      CONFIG_SETTINGS: '환경 설정',
      IVR_NAME: 'IVR명',
      MAIN_NUMBER: '대표번호',
      MAIN_NUMBER_SETTING: '대표번호 설정',
      DNIS_TITLE: 'DNIS명',
      SVC_TP_TITLE: '서비스 템플릿',
      OP_SCHEDULE: '운영스케줄',
      SERVICECODE: '서비스코드',
      NOTICE: '공지사항',
      DISPLAY_PERIOD: '노출기간',
      END_TYPE: '종료여부',
      CONFIG_SETTING: '환경설정',
      KEY: '키',
      VALUE: '값',
      DESCRIPTION: '설명',
      SCHEDULE_NAME: '스케줄명',
      BASIC_INFO: '기본정보',
      BASIC_OP_TIME_SETTING: '기본 운영시간 설정',
      OP_SCHEDULE_NAME: '운영 스케줄명',
      OP_DAY_OF_WEEK: '운영 요일',
      OP_TIME: '운영시간',
      BREAK_TIME_APPLY_TYPE: '휴식시간 적용 여부',
      BREAK_TIME: '휴식시간',
      TWENTY_FOUR_HOUR: '24시',
      WORK_FLAG: '운영여부',
      AGT_TEAM_NM: '상담사 팀명',
      AGT_ID: '상담사 ID',
      AGT_NM: '상담사 이름',
      TEMPLATE: '템플릿',
      SERVICECODE_NM: '서비스코드명',
      NOTICE_FLAG: '공지여부',
    }
  },
};
