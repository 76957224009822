var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DxPopup',{attrs:{"visible":_vm.isOpen,"show-title":true,"title":_vm.propOption.title,"min-width":_vm.propOption.minWidth,"width":_vm.propOption.width,"min-height":_vm.propOption.minHeight,"height":_vm.propOption.height,"resize-enabled":true,"drag-enabled":true,"show-close-button":true,"close-on-outside-click":false},on:{"hiding":_vm.closeModal}},[_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":_vm.propOption.useSaveBtn,"options":{
			elementAttr: {
				class: 'default filled txt_S medium',
			},
			text: _vm.propOption.saveBtnTxt,
			width: '120',
			height: '40',
			useSubmitBehavior: true,
			onClick: () => _vm.saveModal(),
		}}}),_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":_vm.propOption.useCancelBtn,"options":{
			elementAttr: {
				class: 'white filled txt_XS medium',
			},
			text: _vm.propOption.cancelBtnTxt,
			width: '120',
			height: '40',
			onClick: () => _vm.closeModal(),
		}}}),_vm._t("content")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }