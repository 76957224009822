import { render, staticRenderFns } from "./noneMain.vue?vue&type=template&id=179cf336&scoped=true"
import script from "./noneMain.vue?vue&type=script&lang=js"
export * from "./noneMain.vue?vue&type=script&lang=js"
import style0 from "./noneMain.vue?vue&type=style&index=0&id=179cf336&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "179cf336",
  null
  
)

export default component.exports