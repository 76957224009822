<template>
    <div style="width:100%;height:100%">
        <Widget :widget="widgetProperty" :data="data" :mode="mode" ></Widget>
    </div>
</template>

<script>
    export default {
        components: {
            // 부모에 동일한 컴포넌트가 존재하기 때문에 컴포넌트 이름을 인식하지 못해서 비동기 로드함.
            Widget: () => import('@/components/report/dashboard/widget.vue')
        },
        props:{
          widgetProperty: {
            type: Object,
          },
          data: {
            type: Array,
          },
        },
        data() {
            return {
                mode: 'enlarge'
            }
        },
    }
</script>
