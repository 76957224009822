var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",attrs:{"id":"container"}},[_c('div',{staticClass:"page-sub-box locker_setting_list sub_new_style01 sub_ui_box1"},[_c('div',{staticClass:"cusmain-table-wrap"},[_c(_vm.searchBoxComponent,{ref:_vm.searchBoxComponent,tag:"component",attrs:{"newPopupUrl":_vm.url,"reportInfo":_vm.reportInfo,"pageLoaded":_vm.normalized,"getChkDepthTarget":_vm.getChkDepthTarget,"getSelectedReportFilterKeys":_vm.getSelectedReportFilterKeys},on:{"onSearchClick":_vm.onSearchClick}}),_c(_vm.gridComponent,{ref:"grid",tag:"reportGrid",attrs:{"pageData":_vm.pageData,"columns":_vm.gridColumns,"pageLoaded":_vm.normalized,"reportInfo":_vm.reportInfo,"columnsDesc":_vm.gridColumnDescs,"getReportLocalStorage":_vm.getReportLocalStorage},on:{"setReportLocalStorage":_vm.setReportLocalStorage,"onSearchReport":_vm.onSearchReport,"onClickLinkInGrid":_vm.onClickLinkInGrid}})],1),_c('DxPopup',{attrs:{"option":{
        title: '상세 이력',
        width: '1400',
        height: '700',
        minWidth: null,
        minHeight: null,
        useSaveBtn: false,
      },"isOpen":_vm.modal.isOpen,"toolbarYn":false},on:{"closeModal":function($event){return _vm.onClickToggleModal(false)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('HistoryPopupContent',{ref:"historyPopupContent",attrs:{"targetGridColumns":_vm.gridColumns,"reportRowData":_vm.modal.reportRowData,"historyColumns":_vm.modal.historyColumns,"historyDatas":_vm.modal.historyDatas}})]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }