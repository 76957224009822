<template>
    <div :class="['bigtabBox',{'on': isActive}]">
        <div class="fr_wrap">
        <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Tab'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isActive: true
        }
    },
    mounted() {
      this.$parent.selectTab(0);
    },
  }
</script>

