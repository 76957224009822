<template>
  <header>
    <div class="header fr" v-if="!$store.getters.getIsMobile">
      <div class="header-search fl">
        <div
            class="w-full h-full bg-no-repeat bg-center bg-auto cursor-pointer flex items-center justify-center"
            @click="$_goMainPage"
        >
          <img v-if="getLogoUrl" :src="getLogoUrl" style="max-width: 200px; max-height: 30px" @error="setDefaultLogo" alt="로고 이미지" />
          <img v-else src="@/assets/images/ecslogo_top.png" style="max-width: 200px; max-height: 30px" alt="로고 이미지" />
        </div>
        <!-- 검색 -->
        <SearchArea />
      </div>
      <div class="header-inner">
        <div class="header-logo" style="height: 26px; padding-right: 10px;">
          <img src="@/assets/images/timer.png" width="26" height="auto" alt="로그인 타임아웃 이미지"/>
          <span style="padding-left: 4px">{{ getTokenExpirationTime }}</span>
        </div>
        <div class="header-logo">
          <img v-if="getCustomerLogoUrl" width="60" height="20" :src="getCustomerLogoUrl" @error="setDefaultCustomerLogo" alt="Customer Logo Image" />
          <img v-else src="@/assets/images/gnb_logo.png" />
        </div>
        <button
            type="button"
            class="btn-profile"
            title="내 프로필"
            style="display: flex; align-items: center; justify-content: center; height: auto;width:auto;left:-90px;"
        >
          {{ $store.getters.getLoginId }}({{ $store.getters.getLoginNm }})
        </button>
        <!--프로필 클릭 팝업 정의 -->
        <div class="my-profile">
          <h3>{{ $store.getters.getLoginId }}({{ $store.getters.getLoginNm }})</h3>
          <ul :class="`clearfix grid gap-x-1 ${getAuthType === 'DEFAULT' ? 'grid-cols-2' : 'grid-cols-1'}`">
            <li v-if="getAuthType === 'DEFAULT'" class="w-full">
              <a class="cursor-pointer" @click="openProfile(true)">프로필 관리</a>
            </li>
            <li class="w-full">
              <a class="cursor-pointer" tabindex="-1" @click="logout">로그아웃</a>
            </li>
          </ul>
        </div>

        <!-- 검색 -->
        <DxButton v-if="seconds < 10" class="btn-icon total-search" :height="28" @click="showExcelProgressbar" text="엑셀로딩"
        >엑셀
        </DxButton>

        <!-- 팝업 -->
        <DxCustomPopup
            :option="{
						title: '내 정보관리',
						width: '700',
						height: '450',
						minWidth: '700',
						minHeight: '300',
					}"
            :isOpen="profile.status"
            @closeModal="openProfile(false)"
            @saveModal="saveProfile"
        >
          <template #content>
            <MyProfile ref="myProfile" />
          </template>
        </DxCustomPopup>
        <!-- 팝업 -->

        <DxPopup
            :visible="popupVisible"
            :drag-enabled="false"
            :hide-on-outside-click="true"
            :show-close-button="false"
            :show-title="true"
            :width="300"
            :height="180"
            container=".dx-viewport"
            title="엑셀 다운로드 상태창"
        >
          <DxPosition at="top" my="center" collision="fit" />
          <DxToolbarItem widget="dxButton" toolbar="top" locate-in-menu="always" />
          <DxToolbarItem widget="dxButton" toolbar="bottom" location="before" />
          <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="closeButtonOptions" ㄹF />

          <DxProgressBar
              id="progress-bar-status"
              :class="{ complete: seconds === 0 }"
              :min="0"
              :max="10"
              :status-format="statusFormat"
              :value="progressValue"
              width="90%"
          />
        </DxPopup>

        <!-- 알림창 -->
        <div class="header-right">
          <button type="button" class="btn-alim" title="알림" style="margin-left: 5px" @click="handleAlarmShow()">
            <span>알림</span>
            <i class="badge" v-if="hasUncheckedItems">{{ alarm.uncheckedList.length }}</i>
          </button>
          <!--검색 영역 오른쪽 컨포넌트 적용시 영역 정의-->
        </div>
        <AlarmDropDownPanel
            ref="myAlarm"
            @AlarmUnCheckedList="handleAlarmUnCheckedList"
            @UpdateAlarmChecked="handleUpdateAlarmChecked"
            v-click-outside="() => handleAlarmShow(false)"
        ></AlarmDropDownPanel>

        <div class="header-layout">
          <!--검색 영역 오른쪽 컨포넌트 적용시 영역 정의-->

          <button type="button" class="btn-layout" title="레이아웃" @click="toggleMyLayerLayoutPopup">
            <span class="hidden">레이아웃</span>
          </button>
          <transition name="slide">
            <div ref="element" v-if="isShowMyLayerLayout" class="layout-wrap" v-click-outside="closeMyLayerLayoutPopup">
              <div class="layout-header">
                <div class="layout-header-title">레이아웃 설정</div>
                <button type="button" class="btn-close-layout"><span class="hidden">닫기</span></button>
              </div>
              <div class="layout-cont" style="flex-flow: wrap">
                <p>메뉴영역고정</p>
                <div class="locker_switch_box clearfix">
                  <div class="locker_switch t_in_switch">
                    <input type="checkbox" id="switch2" v-model="isLayoutCheck" @change="handleSwitchChange" />
                    <label for="switch2">Toggle</label>
                  </div>
                </div>
                <div style="align-items: center; width: 100%; display: flex; top: 20px">
                  <p>메뉴 즐겨찾기</p>
                  <button class="btn_XS white" type="button" @click="openMenuFavorite">설정</button>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!-- 모바일 테마 설정 -->
    <div class="w-full flex header ecsmui red" v-if="$store.getters.getIsMobile">
      <button class="flex-none px-3" type="button" @click="openMenu">
        <svg width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
          <path
              id="lnb_icon1"
              d="M19.1667 16.7708C19.5359 16.771 19.8908 16.9132 20.158 17.1679C20.4252 17.4227 20.5841 17.7705 20.6018 18.1392C20.6196 18.508 20.4948 18.8694 20.2533 19.1486C20.0118 19.4278 19.6721 19.6035 19.3047 19.6391L19.1667 19.6458H3.83337C3.46421 19.6456 3.10926 19.5034 2.84209 19.2486C2.57491 18.9939 2.41599 18.6461 2.39825 18.2774C2.38051 17.9086 2.50531 17.5472 2.74679 17.268C2.98828 16.9887 3.32793 16.8131 3.69537 16.7775L3.83337 16.7708H19.1667ZM19.1667 10.0625C19.548 10.0625 19.9136 10.2139 20.1832 10.4835C20.4528 10.7531 20.6042 11.1187 20.6042 11.5C20.6042 11.8812 20.4528 12.2468 20.1832 12.5164C19.9136 12.786 19.548 12.9375 19.1667 12.9375H3.83337C3.45213 12.9375 3.08649 12.786 2.81691 12.5164C2.54732 12.2468 2.39587 11.8812 2.39587 11.5C2.39587 11.1187 2.54732 10.7531 2.81691 10.4835C3.08649 10.2139 3.45213 10.0625 3.83337 10.0625H19.1667ZM19.1667 3.35413C19.548 3.35413 19.9136 3.50558 20.1832 3.77516C20.4528 4.04474 20.6042 4.41038 20.6042 4.79163C20.6042 5.17287 20.4528 5.53851 20.1832 5.80809C19.9136 6.07768 19.548 6.22913 19.1667 6.22913H3.83337C3.45213 6.22913 3.08649 6.07768 2.81691 5.80809C2.54732 5.53851 2.39587 5.17287 2.39587 4.79163C2.39587 4.41038 2.54732 4.04474 2.81691 3.77516C3.08649 3.50558 3.45213 3.35413 3.83337 3.35413H19.1667Z"
              fill="#5F6368"
          ></path>
        </svg>
      </button>

      <div class="w-full h-auto grow px-3 inline-flex items-center justify-center">
        <img
            v-if="getLogoUrl"
            :src="getLogoUrl"
            @error="setDefaultLogo"
            style="max-width: 200px; max-height: 30px"
            alt="로고 이미지"
            class="cursor-pointer"
            @click="$_goMainPage"
        />
        <img
            v-else
            src="@/assets/images/ecslogo_top.png"
            style="max-width: 200px; max-height: 30px"
            alt="로고 이미지"
            class="cursor-pointer"
            @click="$_goMainPage"
        />
      </div>

      <div class="header_btn_group flex-none px-3">
        <button class="left btn-profile"></button>
        <div class="my-profile">
          <h3>{{ $store.getters.getLoginId }}({{ $store.getters.getLoginNm }})</h3>

          <ul class="clearfix">
            <li class="fl">
              <a class="cursor-pointer" @click="openProfile(true)">프로필 관리</a>
            </li>
            <li class="fr">
              <a class="cursor-pointer" @click="logout">로그아웃</a>
            </li>
          </ul>
        </div>

        <!-- 팝업 -->
        <DxCustomPopup
            :option="{
						title: '내 정보관리',
						width: '700',
						height: '450',
						minWidth: '700',
						minHeight: '300',
					}"
            :isOpen="profile.status"
            @closeModal="openProfile(false)"
            @saveModal="saveProfile"
        >
          <template #content>
            <MyProfile ref="myProfile" />
          </template>
        </DxCustomPopup>
        <!-- 팝업 -->

        <DxPopup
            :visible="popupVisible"
            :drag-enabled="false"
            :hide-on-outside-click="true"
            :show-close-button="false"
            :show-title="true"
            :width="300"
            :height="180"
            container=".dx-viewport"
            title="엑셀 다운로드 상태창"
        >
          <DxPosition at="top" my="center" collision="fit" />
          <DxToolbarItem widget="dxButton" toolbar="top" locate-in-menu="always" />
          <DxToolbarItem widget="dxButton" toolbar="bottom" location="before" />
          <DxToolbarItem widget="dxButton" toolbar="bottom" location="after" :options="closeButtonOptions" />

          <DxProgressBar
              id="progress-bar-status"
              :class="{ complete: seconds === 0 }"
              :min="0"
              :max="10"
              :status-format="statusFormat"
              :value="progressValue"
              width="90%"
          />
        </DxPopup>

        <button class="left btn-alim" @click="handleAlarmShow()"></button>

        <AlarmDropDownPanel
            ref="myAlarm"
            @AlarmUnCheckedList="handleAlarmUnCheckedList"
            @UpdateAlarmChecked="handleUpdateAlarmChecked"
            v-click-outside="() => handleAlarmShow(false)"
        ></AlarmDropDownPanel>
      </div>
    </div>
    <!-- 팝업-->
    <DxCustomPopup
        :option="{
				title: '메뉴 즐겨찾기 설정',
				width: '750',
				height: '750',
				minWidth: '750',
				minHeight: '750',
			}"
        :isOpen="menuFavorite.status"
        @closeModal="openMenuFavorite(false)"
        @saveModal="saveMenuFavorite"
    >
      <template #content>
        <div style="height: 600px; overflow-y: auto">
          <MenuFavorite ref="menuFavorite" />
        </div>
      </template>
    </DxCustomPopup>
  </header>
</template>

<script>
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
import DxCustomPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
import SearchArea from '@/layouts/search-area.vue';
import AlarmDropDownPanel from '@/pages/esp/system/alarm/drop-down-panel.vue';
import MyProfile from '@/pages/esp/user/profile.vue';
import MenuFavorite from '@/pages/esp/system/menu/favorite.vue';
import { DxProgressBar } from 'devextreme-vue/progress-bar';
import { isSuccess } from '@/plugins/common-lib';
import { SOCKET_ACTIONS } from '@/event/socket-action';


export default {
  name: 'Top',
  components: {
    DxPopup,
    DxPosition,
    DxToolbarItem,
    DxProgressBar,
    MyProfile,
    MenuFavorite,
    DxCustomPopup,
    AlarmDropDownPanel,
    SearchArea,
  },
  watch: {
    // 토큰 만료 시간시 로그아웃
    tokenExpirationTime(newValue) {
      if(newValue === 0) {
        this.$_Msg(this.$_msgContents('FORCE_LOGOUT'), { icon: 'warning' });
        this.logout();
        }
    },
  },
  data() {
    return {
      seconds: 10,
      buttonText: 'Start progress',
      inProgress: false,
      stylingMode: 'outlined',
      isShowSeachList: false,
      wsConnected: false,
      wsReconnectFunc: null,
      popupVisible: false,
      closeButtonOptions: {
        text: 'Close',
        onClick: () => {
          this.popupVisible = false;
        },
      },
      profile: {
        status: false,
      },
      menuFavorite: {
        status: false,
      },
      alarm: {
        timer: 1500,
        throttle: {},
        uncheckedList: [],
      },
      isShowMyLayerLayout: false,
      isLayoutCheck: false,
      favoriteMenuLength: -1,
      customLogoErrorHandled: false, // 커스텀 로고 이미지 에러 핸들링 여부
      logoErrorHandled: false, // 로고 이미지 에러 핸들링 여부
      tokenExpirationTime: 0, // 토큰 만료 시간(ESP_CORE yml 설정)
      tokenWarningTime: 300, // 토큰 경고 시간(초)
      tokenIntervalTimer: null,
      showLogoutWarning: false,
      idleTime: 0,
      idleTimer: null
    };
  },

  computed: {
    /**
     * 로그인 만료 시간 조회
     * @return {string}
     */
    getTokenExpirationTime() {
      if(this.tokenExpirationTime > 0) {
        const minutes = Math.floor((this.tokenExpirationTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((this.tokenExpirationTime % (1000 * 60)) / (1000));
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      } else {
        return '00:00';
      }
    },
    getAuthType() {
      return this.$_getSystemData('auth_type')?.configValue || 'DEFAULT';
    },
    /**
     * 고객 로고 이미지 URL
     * @return {string|string|string}
     */
    getCustomerLogoUrl() {
      const customerLogo = this.$store.getters.getThemeSetting?.customerLogo;
      return customerLogo ? this.$_getAttachFileURL(null, customerLogo) : '';
    },
    /**
     * 로고 이미지 URL
     * @return {string|string|string}
     */
    getLogoUrl() {
      const logoImg = this.$store.getters.getThemeSetting?.logoImg;
      return logoImg ? this.$_getAttachFileURL(null, logoImg) : '';
    },
    progressValue() {
      return 10 - this.seconds;
    },
    hasUncheckedItems() {
      return this.alarm.uncheckedList.length > 0;
    },
  },
  methods: {
    /**
     * 토큰 만료 시간 조회
     * @return {moment.Moment}
     */
    getCookieExpirationTime() {
      const token = this.$store.getters.getAccessToken;

      if (token) {
        const decodeToken = this.$jwt.decode(token);
        return this.$_commonlib.moment.unix(decodeToken.exp);
      }
    },
    // 상단 토큰 타이머 설정
    setupTokenExpirationTimer() {
      const accessTokenExpirationTime = this.getCookieExpirationTime();

      if (accessTokenExpirationTime) {
        this.updateTimer(); // 타이머 업데이트
        this.tokenIntervalTimer = setInterval(() => {
          this.updateTimer();
        }, 1000);
      }
    },
    // 토큰 타이머 업데이트
    updateTimer() {
      const currentTime = this.$_commonlib.moment();
      const expirationTime = this.getCookieExpirationTime();
      if(expirationTime === undefined) {
        return;
      }

      const timeDifferenceInMilliseconds = expirationTime.diff(currentTime);

      if (timeDifferenceInMilliseconds > 0) {
        this.tokenExpirationTime = timeDifferenceInMilliseconds;
        if (this.tokenExpirationTime / 1000 < this.tokenWarningTime &&
        !this.showLogoutWarning) {
          this.showLogoutWarning = true;
          this.warningLogoutConfirm();
        }
      } else {
        this.tokenExpirationTime = 0;
      }
    },
    // 토큰 만료 임박 시 경고 메시지 표시
    async warningLogoutConfirm() {
      if(await this.$_Confirm(this.$_msgContents('WARNING_FORCE_LOGOUT'))) {
        this.resetTokenExpirationTime();
      } else {
        await this.logout();
      }
    },
    statusFormat(ratio) {
      return `진행률 : ${ratio * 100}%`;
    },
    openMenu() {
      // 메뉴 element 가져오기
      const menu = document.querySelector('#myMenu');

      // 오픈여부 확인
      if (!menu.classList.contains('open')) {
        // 백드롭 항목 추가
        const backdrop = document.createElement('div');
        backdrop.className = 'backdrop backdrop-menu show';
        menu.parentNode.appendChild(backdrop);

        // 백드롭 클릭 시 메뉴 닫기
        backdrop.addEventListener('click', () => {
          this.closeMenu();
        });

        // 윈도우 크기에 맞게 높이 조절
        menu.style.height = window.innerHeight + 'px';

        // 메뉴 위치 결정
        let menuPosition = 'side-menu';
        if (menu.classList.contains('menu-right')) {
          menuPosition = 'side-menu-right';
          window.menu.position = 'right';
          const headers = document.getElementsByClassName('header');
          for (const header of headers) {
            if (!header.classList.contains('side-menu-right')) {
              header.classList.add('side-menu-right');
            }
          }
        }

        // 부모 요소에 메뉴 위치 클래스 추가
        const parent = menu.parentNode;
        if (parent.classList.contains('body')) {
          parent.classList.add(menuPosition);
        } else {
          document.getElementsByTagName('body')[0].classList.add(menuPosition);
        }
      }

      // 메뉴 요소에 open 클래스 추가
      menu.classList.add('open');
      // 메뉴 열려있는지 확인하는 이벤트 추가
      const openMenuEvent = new CustomEvent('openMenu', {
        detail: {
          menu: menu,
        },
      });
      document.dispatchEvent(openMenuEvent);

      // "firedCloseMenu" 이벤트가 트리거될 때 메뉴를 닫는 리스너 추가
      document.addEventListener('firedCloseMenu', () => {
        this.closeMenu();
      });
    },
    closeMenu() {
      const menu = document.querySelector('#myMenu');
      let backdrop = document.querySelector('.backdrop-menu');

      if (!menu.classList.contains('open')) return false;

      let closeMenuEvent = new CustomEvent('closeMenu', {
        detail: { menu },
      });
      document.dispatchEvent(closeMenuEvent);
      menu.classList.remove('open');
      backdrop.remove();

      const headers = document.getElementsByClassName('header');
      for (const header of headers) {
        header.classList.remove('side-menu-right');
      }

      const parentElement = menu.parentNode;
      if (parentElement.classList.contains('body')) {
        parentElement.classList.remove('side-menu');
      } else {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('side-menu');
      }
    },
    openProfile(flag = true) {
      this.$refs.myProfile.resetAllInfo();
      this.profile.status = flag;
    },
    async saveProfile() {
      const closeFlag = await this.$refs.myProfile.updateProfile();
      if (closeFlag) {
        await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
        if (this.$refs.myProfile.changePwdFlag) {
          await this.logout();
        } else {
          this.openProfile(false);
        }
      }
    },
    openMenuFavorite(flag = true) {
      this.$refs.menuFavorite.resetSelect();
      this.menuFavorite.status = flag;
    },
    async saveMenuFavorite() {
      const { closeFlag, error, isChanged = false } = await this.$refs.menuFavorite.saveForModal();
      if (!closeFlag) {
        this.$_Msg(error);
        return;
      }
      if (isChanged) {
        this.$_eventbus.$emit('getFavoriteMenu');
        await this.$_Msg(this.$_msgContents('CMN_SUC_UPDATE'));
      }
      this.openMenuFavorite(false);
    },

    // profile end
    onButtonClick() {
      if (!this.inProgress) {
        if (this.seconds === 0) {
          this.seconds = 10;
        }
      }

      this.inProgress = !this.inProgress;
    },

    showExcelProgressbar() {
      this.popupVisible = true;
      this.onButtonClick();
    },

    showToastMsgByAlarm(alarm) {
      const myAlarm = this.$refs.myAlarm;

      if (this.excludeAlarmNoti(alarm)) {
        return;
      }

      this.$_Toast(myAlarm.templateAlarmTitle(alarm), { position: 'bottom-end', timer: this.alarm.timer, icon: 'info' });
    },
    excludeAlarmNoti(alarm) {
      let progressBar = alarm?.actionList?.find(action => action.actionType === 'PROGRESS_BAR') ?? undefined;
      return !!(progressBar && !(progressBar?.actionContent === 0 || progressBar?.actionContent === 100));
    },
    updateAlarmUncheckedCount(alarm) {
      const updateKey = alarm.updateKey;

      if (this.excludeAlarmNoti(alarm)) {
        return;
      }

      if (!this.alarm.uncheckedList.includes(updateKey)) {
        this.alarm.uncheckedList.push(updateKey);
      }
    },

    /**
     * 로그 아웃
     * @return {Promise<void>}
     */
    async logout() {
      await this.$store.dispatch('LOGOUT', {
        params: {
          loginId: this.$store.getters.getLoginId,
        },
      });
    },
    wsConnect() {
      clearInterval(this.wsReconnectFunc);
      this.socket = new SockJS(`${window.location.origin}/esp/ws`);
      const options = { debug: false, protocols: ['v12.stomp'] };
      this.stompClient = Stomp.over(this.socket, options);

      this.stompClient.connect(
          { loginId: this.$store.getters.getLoginId },
          () => {
            this.wsConnected = true;

            // 알람 구독
            this.stompClient.subscribe(`/subscribe/alarm/${this.$store.getters.getLoginId ?? null}`, this.onAlarmMessageReceived);

            // store 변경 이벤트
            this.stompClient.subscribe(`/subscribe/store`, this.onStoreMessageReceived);
            this.stompClient.subscribe(`/subscribe/store/${this.$store.getters.getLoginId ?? null}`, this.onStoreMessageReceived);
            this.stompClient.subscribe(`/subscribe/logout/${this.$store.getters.getLoginId ?? null}`, this.logout);
          },
          () => {
            this.wsDisconnect();
            // 접속이 끊어지면 재접속을 시도한다.
            this.startWsReconnect();
          },
      );
    },
    wsDisconnect() {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
      this.wsConnected = false;
    },
    onAlarmMessageReceived(msg) {
      const myAlarm = this.$refs.myAlarm;
      const alarmList = JSON.parse(msg.body) ?? [];

      alarmList.forEach(alarm => {
        // 모달이 켜지있지 않으면 카운트올림
        if (!(myAlarm.alarmInfo?.isOpen ?? false)) {
          // 여기에 unchecked updateKey를 담고 그거에대한 길이로한다.
          this.updateAlarmUncheckedCount(alarm);
        }

        this.showToastMsgByAlarm(alarm);

        // insert 이벤트추가
        myAlarm.handleInsertNewAlarm(alarm);
      });
    },
    onStoreMessageReceived(msg) {
      const data = JSON.parse(msg.body);
      const { eventNm, payload } = data;
      if (typeof SOCKET_ACTIONS[eventNm] === 'function') {
        SOCKET_ACTIONS[eventNm](JSON.parse(payload));
      }
    },
    // 알림 체크 이벤트
    sendAlarmCheckMessage(ids) {
      const loginId = this.$store.getters.getLoginId ? this.$store.getters.getLoginId : 'unknown';

      if (this.stompClient && this.stompClient.connected) {
        const msg = { ids, loginId };
        this.stompClient.send('/app/alarm/update', JSON.stringify(msg), {});
      }
    },
    startWsReconnect() {
      this.wsReconnectFunc = setInterval(() => {
        if (!this.wsConnected) {
          this.wsConnect();
        }
      }, 5000);
    },
    setUi() {
      // 설정된 UI에 따라 스타일 부여
      document.querySelector('html').classList.add('themes_gp_style01');
      // 쿠키에 저장된 스타일 설정 부여
      this.isLayoutCheck = this.readCookie('layerChecked') === 'true';
      this.toggleMyLayerLayout();
    },
    // 레이아웃 설정 팝업 표출
    toggleMyLayerLayoutPopup() {
      this.isShowMyLayerLayout = !this.isShowMyLayerLayout;
    },
    closeMyLayerLayoutPopup() {
      this.isShowMyLayerLayout = false;
    },

    // 레이아웃 설정 > 메뉴영역 고정 스위치 이벤트
    handleSwitchChange() {
      this.toggleMyLayerLayout();
      this.saveLayerLayoutToCookie();
    },
    // 레이아웃 설정 결과 토글
    toggleMyLayerLayout() {
      const wrap = document.querySelector('#wrap');
      const isRouteRoot = this.$route.path === '/';

      this.$store.commit('setIsShowMyLayerLayout', this.isLayoutCheck);

      wrap.classList.toggle('menuitemshow', this.isLayoutCheck);
      wrap.classList.toggle('left-show', this.isLayoutCheck && !isRouteRoot);
    },
    // 레이아웃 설정 시 쿠키 저장
    saveLayerLayoutToCookie() {
      const expirationDate = new Date();
      expirationDate.setMonth(expirationDate.getMonth() + 1); // 만료기간 한달
      document.cookie = `layerChecked=${this.isLayoutCheck}; expires=${expirationDate.toUTCString()}; path=/`;
    },

    // 페이지 호출 시 쿠키 읽어서 레이아웃 설정
    readCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
    },
    resetTokenExpirationTime() {
      if(this.idleTime > 30 ||
        Math.floor((this.tokenExpirationTime % (1000 * 60)) / (1000)) < 30) {
        this.$store.dispatch('REFRESH_TOKEN');
      }
      this.idleTime = 0;
    },

    // alarm 관련추가 메소드
    handleAlarmUnCheckedList(list = []) {
      this.alarm.uncheckedList = [...list];
    },

    handleUpdateAlarmChecked({ ids }) {
      if (ids?.length) {
        this.sendAlarmCheckMessage(ids);
      }
    },
    handleAlarmShow(flag = !this.$refs.myAlarm?.alarmInfo?.isOpen) {
      this.$refs.myAlarm.toggleAlarm(flag);
    },
    findMenuFromStore(id, depth = 3) {
      return this.$store.getters.getMenuList.find(d => d.id === id && d.menuDepth === depth);
    },
    /**
     * 커스텀 로고 이미지 로드 실패 시 기본 이미지로 변경
     * @param e
     */
    setDefaultCustomerLogo(e) {
      if (!this.customLogoErrorHandled) { // 기본 이미지 못 불러올시 반복 호출 중지 로직
        e.target.src = require('@/assets/images/gnb_logo.png');
        this.customLogoErrorHandled = true;
      }
    },
    /**
     * 로고 이미지 로드 실패 시 기본 이미지로 변경
     * @param e
     */
    setDefaultLogo(e) {
      if (!this.logoErrorHandled) { // 기본 이미지 못 불러올시 반복 호출 중지 로직
        e.target.src = require('@/assets/images/ecslogo_top.png');
        this.logoErrorHandled = true;
      }
    },
  },
  created() {
    const encryptionTypeConfig = this.$store.getters.getSystemList.find(system => system.configKey === 'encryption_type');
    this.$store.commit('setEncryptionType', encryptionTypeConfig?.configValue || 'SHA256'); // 로그인 암호화 타입 설정
    this.$_eventbus.$on('getFavoriteMenu', async () => {
      const payload = {
        actionname: 'MENU_FAVORITE_LIST',
        loading: false,
      };

      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        const resultList = res.data.data;
        const filterList = resultList.reduce((result, check) => {
          const menu = this.findMenuFromStore(check.menuId);
          if (menu != null) {
            result.push({
              id: check.id,
              menuId: check.menuId,
              order: check.order,
            });
          }
          return result;
        }, []);
        this.$store.commit('setFavorite', filterList);
        this.favoriteMenuLength = filterList.length;
      }
    });
  },
  mounted() {
    this.setupTokenExpirationTimer();
    this.wsConnect();
    this.setUi();

    // 토큰 만료시간 갱신 이벤트 추가
    document.addEventListener('keypress', this.resetTokenExpirationTime);
    document.addEventListener('click', this.resetTokenExpirationTime);
    document.addEventListener('scroll', this.resetTokenExpirationTime);
    this.idleTimer = setInterval(() => {
      this.idleTime++;
    }, 1000);
  },
  beforeDestroy() {
    this.$_eventbus.$off('getFavoriteMenu');
    clearInterval(this.idleTimer);
    clearInterval(this.tokenIntervalTimer);
    document.removeEventListener('keypress', this.resetTokenExpirationTime);
    document.removeEventListener('click', this.resetTokenExpirationTime);
    document.removeEventListener('scroll', this.resetTokenExpirationTime);
    clearInterval(this.wsReconnectFunc);
    this.wsDisconnect();
  },
};
</script>
