<!--
  PACKAGE_NAME : src/components/euc
  FILE_NAME : confirm-user-box.vue
  AUTHOR : jhcho
  DATE : 2024-05-28
  DESCRIPTION :
-->
<template>
  <div class="template-card-box justify-self-center">
    <div class="template-card-body h-24">
      <div
          class="template-card-main h-full"
          :class="{
            'bg-complete': status === 'COMPLETE',
            'bg-hold': status === 'HOLD'
          }"
      >
        <div class="template-m-hard text-start flex items-center w-full">
          <span class="text-lg pr-1">{{ title }}</span>
          <i v-if="status === 'COMPLETE'" class="dx-icon-check text-lg"></i>
          <i v-else-if="status === 'READY'" class="dx-icon-video text-lg"></i>
        </div>
        <div class="template-m-hard text-start flex w-full items-end user-info">
          <span class="text-2xl">{{ userNm }}</span>
          <span class="text-base pl-2">{{ gradeNm }}</span>
          <span class="text-base" v-if="gradeNm">&nbsp;/&nbsp;</span>
          <span class="text-base">{{ deptNm }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    userNm: {
      type: String,
      default: ''
    },
    gradeNm: {
      type: String,
      default: ''
    },
    deptNm: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    process: {
      type: String,
      default: ''
    },
    approverUserNo: {
      type: String,
      default: ''
    },
    workerUserNo: {
      type: String,
      default: ''
    }
  },
  components: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {
  },
  created() {
  }
}
</script>
<style scoped>
:global(.template-card-box .template-card-body .template-card-main .template-m-buttonbox) {
  padding: 12px !important;
}
.bg-complete {
  background-color: rgba(139, 195, 74, .32) !important;
}
.bg-hold {
  background-color: rgba(0,0,0,.16) !important;
}
.user-info {
  padding-top: 0px !important;
}
.template-m-hard span {
  margin-bottom: 0px !important;
}
</style>