import { EnumEntry, EnumMap } from '@/configs/enums/util';

/**
 * 공지사항 - 종료여부 Enum 클래스
 * @enum {EnumMap}
 */
class StringEndTypeFlag extends EnumEntry {
	YES = {
		...EnumMap,
		key: 'CC_STRING_END_TYPE_FLAG_YES',
		value: 'Y',
		label: '전화종료',
		group: 'CC_STRING_END_TYPE_FLAG',
		group_label: '종료여부(문자열)',
	};

	NO = {
		...EnumMap,
		key: 'CC_STRING_END_TYPE_FLAG_NO',
		value: 'N',
		label: '계속전화',
		group: 'CC_STRING_END_TYPE_FLAG',
		group_label: '종료여부(문자열)',
	};

	values = [this.YES, this.NO];
}

/**
 * 운영스케줄 - 공휴일설정 운영여부 Enum 클래스
 */
class StringHolidayWorkFlag extends EnumEntry {
	YES = {
		...EnumMap,
		key: 'CC_STRING_HOLIDAY_WORK_FLAG_YES',
		value: 'Y',
		label: '운영',
		group: 'CC_STRING_HOLIDAY_WORK_FLAG',
		group_label: '공휴일 운영여부(문자열)',
	};

	NO = {
		...EnumMap,
		key: 'CC_STRING_HOLIDAY_WORK_FLAG_NO',
		value: 'N',
		label: '미운영',
		group: 'CC_STRING_HOLIDAY_WORK_FLAG',
		group_label: '공휴일 운영여부(문자열)',
	};

	values = [this.YES, this.NO];
}


function getEnumAllCodes() {
	return [].concat(
		new StringEndTypeFlag().values,
		new StringHolidayWorkFlag().values,
	);
}

export default {
	stringEndTypeFlag: new StringEndTypeFlag(),
	stringHolidayWorkFlag: new StringHolidayWorkFlag(),
	getEnumAllCodes: getEnumAllCodes(),
};
