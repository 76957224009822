<template>
	<div v-if="open">
		<slot />
	</div>
</template>

<script>
function copyStyles(sourceDoc, targetDoc) {
	Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
		if (styleSheet.cssRules) {
			const newStyleEl = sourceDoc.createElement('style');

			Array.from(styleSheet.cssRules).forEach(cssRule => {
				newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
			});

			targetDoc.head.appendChild(newStyleEl);
		} else if (styleSheet.href) {
			const newLinkEl = sourceDoc.createElement('link');

			newLinkEl.rel = 'stylesheet';
			newLinkEl.href = styleSheet.href;
			targetDoc.head.appendChild(newLinkEl);
		}
	});
}

export default {
	model: {
		prop: 'open',
		event: 'close',
	},
	props: {
		datas: Object,
		params: Object,
		urls: String,
		open: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			windowRef: null,
			popup_name: '',
		};
	},
	watch: {
		open(newOpen) {
			if (newOpen) {
				let now = new Date();
				this.popup_name = now;
				const searchParams = JSON.stringify({ options: this.params, params: this.datas });
				sessionStorage.setItem(this.popup_name, searchParams);
				this.openPopup();
				this.$parent.status.windowPopup = false;
			}
		},
	},
	methods: {
		openPopup() {
			console.log('this.urls: ', this.urls);
			this.windowRef = window.open(this.urls, this.popup_name, 'width=1400,height=800,left=600,top=200');
			copyStyles(window.document, this.windowRef.document);
		},
		closePopup() {
			if (this.windowRef) {
				this.windowRef.close();
				this.windowRef.removeEventListener('beforeunload', this.closePopup);
				this.windowRef = null;
				this.$emit('popup', false); // 부모창의 binding된 open에게 값을 넘김
				sessionStorage.removeItem(this.popup_name);
			}
		},
	},
	created() {
		this.$emit('popup', false); // 부모창의 binding된 open에게 값을 넘김
	},
	mounted() {},
	updated() {},
	beforeDestroy() {
		sessionStorage.removeItem(this.popup_name);
	},
};
</script>
