<template>
  <div v-if="open">
    <slot />
    <input id="test" />
  </div>
</template>

<script>
  export default {
    model: {
      prop: 'open',
      event: 'close',
    },
    props: {
      datas: Object,
      urls: String,
      open: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        windowRef: null,
        popup_name: '',
      };
    },
    watch: {
      open(newOpen) {
        if (newOpen) {
          this.popup_name = new Date();
          this.openPopup();
          this.$parent.windowPopup = false;
        }
      },
    },
    methods: {
      openPopup(param) {
        this.windowRef = window.open(this.urls, this.popup_name, 'width=1400,height=800,left=600,top=200');
      },
      closePopup() {
        if (this.windowRef) {
          this.windowRef.close();
          this.windowRef.removeEventListener('beforeunload', this.closePopup);
          this.windowRef = null;
        }
      },
    },
    created() {
      this.$emit('popup', false); // 부모창의 binding된 open에게 값을 넘김
    },
    mounted() {},
    beforeDestroy() {},
  };
</script>