<!-- pie 차트 형태일 경우의 컴포넌트 입니다 -->
<template>
  <DxPieChart
    :ref="`chart${widget.id}`"
    :id="`chart${widget.id}`"
    :data-source="widgetDataSource"
    palette="Dark Violet"
    @point-click="pointClickHandler($event)"
    @legend-click="legendClickHandler($event)"
  >
  <!-- DxPieChart palette type : Soft Blue / Green Fog / Dark Violet / Carmine / Dark Moon / Soft Pastel / Bright -->
  <DxCommonSeriesSettings :type="widget.chartType" argument-field="NRSNNAME" />
  <DxCommonAxisSettings>
    <DxGrid :visible="true" />
  </DxCommonAxisSettings>
  <DxSeries
    v-for="chatDataSeries in chart.series"
    :key="chatDataSeries.value"
    :value-field="chatDataSeries.value"
    :name="chatDataSeries.name"
  >
    <DxLabel :visible="true">
      <DxConnector :visible="true" :width="1" />
    </DxLabel>
  </DxSeries>
  <DxMargin :bottom="0"/>
  <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
    <DxLabel>
      <DxFormat type="decimal"/>
    </DxLabel>
  </DxArgumentAxis>
  <DxLegend vertical-alignment="top" horizontal-alignment="right" />
  <DxLoadingIndicator :show="true" :enabled="true" background-color="none" text="로딩중...">
    <DxFont :size="18" :weight="1000" family="sans-serif" color="#939292" />
  </DxLoadingIndicator>
  <DxExport :enabled="false"/>
  <DxTooltip :enabled="true" :customize-tooltip="pieTooltip">
    <DxFormat type="fixedPoint" :precision="0"/>
  </DxTooltip>
  <DxAdaptiveLayout :keep-labels="true" :width="100" :height="100"/>
  </DxPieChart>
</template>
<script>
import {
	DxAdaptiveLayout,
	DxArgumentAxis,
	DxCommonAxisSettings,
	DxCommonSeriesSettings,
	DxConnector,
	DxExport,
	DxFont,
	DxFormat,
	DxGrid,
	DxLabel,
	DxLegend,
	DxLoadingIndicator,
	DxMargin,
	DxSeries,
	DxTooltip,
} from 'devextreme-vue/chart';
import DxPieChart from 'devextreme-vue/pie-chart';
import moment from "moment/moment";

export default {
	components: {
		DxTooltip,
		DxGrid,
		DxExport,
		DxCommonSeriesSettings,
		DxMargin,
		DxFormat,
		DxLegend,
		DxLabel,
		DxSeries,
		DxAdaptiveLayout,
		DxArgumentAxis,
		DxConnector,
		DxLoadingIndicator,
		DxFont,
		DxPieChart,
		DxCommonAxisSettings,
	},
  props: {
    widget: {
      type: Object,
    },
    data : {
      type: Array,
    }
  },
  data () {
    return {
      chart: {
        series: [],
        type: '',
      },
      duration: {
        recent: '1130',
      },
      // 날짜/시간 형식
      dateFormat: {
        daily: 210,
        hour: 213,
      },
    };
  },
  computed: {
    widgetDataSource() {
      // 날짜 재정의 및 포맷팅
      let dayStart;
      let dayEnd;
      if (this.widget.durationType === this.duration.recent) {
        const modifiedDate = this.dayStartChangeWhenRecent();
        dayStart = new Date(this.dateObjectFormating(modifiedDate));
        dayEnd = new Date();
      }

      // 1차 가공 > 날짜
      const filteredDataWithDate = this.data.filter(item => {
        //TODO: columnGroupList가 YMD일때는 아래가 맞지만 아닐 떄는 수정 필요
        //const columnGroupList = this.widget.columnGroupList.join();
        //const itemDate = new Date(this.dateObjectFormating(item[columnGroupList]));
        const itemDate = new Date(this.dateObjectFormating(item['YMD']));
        // itemDate가 startDate와 endDate 사이에 있는지 확인
        return itemDate >= dayStart && itemDate <= dayEnd;
      });

      // 2차 가공 > 이석명
      const filteredDataWithNrsnName = filteredDataWithDate.reduce((acc, obj) => {
        let key = obj['NRSNNAME'];
        if (!acc[key]) {
          acc[key] = {NRSNNAME: obj.NRSNNAME, NRSNCNT: obj.NRSNCNT};
        } else {
          acc[key].NRSNCNT += obj.NRSNCNT;
        }
        return acc;
      }, {});
      const result = Object.values(filteredDataWithNrsnName);
      return result;
    },
  },
  methods: {
    //최근 ( )일 조회일 경우 날짜 재정의 메서드
    dayStartChangeWhenRecent() {
      //최근 ( )일
      const recent = this.widget.recent;
      let date = new Date();
      const dateGroupCode = this.widget.dateGroupCode;
      let dayStart = '';
      if (dateGroupCode === this.dateFormat.daily || dateGroupCode === this.dateFormat.hour) {
        const daysAgo = date.getDate() - (recent-1);
        //date = date.setDate(daysAgo); 이렇게 설정하는 순간 타임스탬프 형식으로 변경됨
        date.setDate(daysAgo);
        dayStart = moment(date).format('YYYYMMDD');
      }
      return dayStart;
    },
    //날짜 포맷 변경 메서드
    dateObjectFormating(dateString){
      let formattedDate = dateString.substring(0, 4) + '-' + dateString.substring(4, 6) + '-' + dateString.substring(6, 8);
      return formattedDate;
    },
    //차트 Y축 컬럼 및 속성값(컬러, 변수)셋팅 메서드
    async setChatDataSeries() {
      this.chart.series = [];
      //TODO : 조회항목이 없는 경우 setChatDataSeries 메서드 종료시켜야함, 로직 수정 필요
      if ((await this.widget.columnList) === null) {
        return;
      }
      //FIXME: props 로 넘어온 변수를 let, const로 쓸 경우 use strict 모드에서 읽기 속성값이라는 오류 뿜기 때문에 var로 설정했으나 use strict 제거 작업 필요
      ('use strict');
      var ayIds = Object.freeze(this.widget.columnList);
      var ayNms = Object.freeze(this.widget.columnNameList);
      var arrayColumnIds = [];
      var arrayColumnNms = [];

      if (typeof ayIds === 'undefined' || typeof ayNms === 'undefined') {
        return;
      }

      //템플릿에 따른 재료
      arrayColumnIds = Array.isArray(ayIds) ? ayIds : ayIds?.split(',');
      arrayColumnNms = Array.isArray(ayNms) ? ayNms : ayNms?.split(',');

      let columnList;

      if (typeof this.widget.columnList === 'undefined') {
        return;
      }

      if (!Array.isArray(this.widget.columnList)) {
        columnList = this.widget.columnList?.split(',');
      } else {
        columnList = this.widget.columnList;
      }

      const arraySeriesColors = this.widget.widgetTp.seriesColors ? this.widget.widgetTp.seriesColors.split(',') : null;
      var series = this.chart.series;

      if (series.length === 0) {
        for (let i = 0; i < columnList.length; i++) {
          let data = {};

          let columnIdx = '';
          arrayColumnIds.find((e, idx) => {
            if (e === columnList[i]) {
              columnIdx = idx;
              data['value'] = columnList[i];
            }
          });

          data['name'] = arrayColumnNms[columnIdx];

          if (arraySeriesColors) {
            data['color'] = arraySeriesColors[columnIdx];
          }
          this.chart.series.push(data);
        }
      }
    },
    //차트 리스트 초기 세팅 메서드
    async selectChatDataList() {
      this.setChatDataSeries();
      this.chart.type = this.widget.chartType;
    },
    //파이 차트일 경우 툴팁 메서드
    pieTooltip(point) {
      return {
        text: `${point.argumentText}               ${point.valueText.toLocaleString('ko-KR')}`,
      }
    },
    pointClickHandler(e) {
      this.toggleVisibility(e.target);
    },
    legendClickHandler(e) {
      const arg = e.target;
      const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

      this.toggleVisibility(item);
    },
  },
  mounted() {
    this.selectChatDataList();
  },
};
</script>