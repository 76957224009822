<!--
  PACKAGE_NAME : src/components/common
  FILE_NAME : esp-modal-download-reason.vue.vue
  AUTHOR : jhcho
  DATE : 2024-06-20
  DESCRIPTION : 다운로드 사유 입력 모달
-->
<template>
  <div class="container h-full w-full inline-block text-right">
    <DxTextArea
        v-model="reason"
        :max-length="maxLength"
        style="height: calc(100% - 20px);"
        :styling-mode="stylingMode"
        @input="onUpdatedComment"
    />
    <span>({{ reasonLength }} / {{ maxLength }})자</span>
  </div>
</template>
<script>
import { DxTextArea } from "devextreme-vue/text-area";

export default {
  components: { DxTextArea },
  props: {
    maxLength: {
      type: Number,
      default: 512
    }
  },
  data() {
    return {
      reason: '',
      reasonLength: 0,
      stylingMode: 'outlined'
    }
  },
  methods: {
    onUpdatedComment(e) {
      const value = e.event.currentTarget.value;
      this.reasonLength = value.length;
    },
  },
  mounted() {
  },
  created() {
  }
}
</script>
<style scoped>
</style>