<!-- table 표 형태일 경우의 컴포넌트 입니다 -->
<template>
  <DxDataGrid
    :ref="`chart${widget.id}`"
    :id="`chart${widget.id}`"
    :data-source="widgetDataSource"
    :show-borders="true"
    :show-column-lines="true"
  >
    <template v-for="(column, index) in widget.widgetTp.columnGroupList.split(',')">
      <DxColumn
        :caption="widget.widgetTp.columnGroupNameList.split(',')[index]"
        :key="index + 100"
        :data-field="column"
        :visible="true"
        alignment="center"
      >
      </DxColumn>
    </template>
    <template v-for="(column, index) in Array.isArray(widget.columnList) ? widget.columnList : widget.columnList.split(',')">
      <DxColumn
        :caption="
            Array.isArray(widget.columnNameList) ? widget.columnNameList[index] : widget.columnNameList.split(',')[index]
          "
        :key="index"
        :data-field="column"
        :visible="true"
        alignment="center"
      >
        <DxFormat type="fixedPoint" :precision="0"> </DxFormat>
      </DxColumn>
    </template>
    <DxScrolling mode="standard" :use-native="true" />
  </DxDataGrid>
</template>
<script>
import { DxDataGrid, DxColumn, DxFormat, DxScrolling} from 'devextreme-vue/data-grid';
import moment from "moment";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFormat,
    DxScrolling,
  },
  props: {
    widget: {
      type: Object,
    },
    data : {
      type: Array,
    }
  },
  data () {
    return {
      chart: {
        series: [],
        type: '',
      },
      duration : {
        fixeDuration: '1128',
        cumulativeDuration: '1129',
        recent: '1130'
      },
      // 날짜/시간 형식
      dateFormat: {
        daily:  210,
        monthly:211,
        year:   212,
        hour:   213,
      },
    };
  },
  computed: {
    widgetDataSource() {
      // 날짜 재정의 및 포맷팅
      let dayStart;
      let dayEnd;
      if (this.widget.durationType === this.duration.recent) {
        const modifiedDate = this.dayStartChangeWhenRecent();
        dayStart = new Date(this.dateObjectDayFormating(modifiedDate));
        dayEnd = new Date();
      }

      //TODO : 2개일 때에 대한 로직 분기 필요
      const columnGroupList = this.widget.columnGroupList.join();

      // 재정의한 날짜를 통해 필터링
      let filteredData;
      // 조회항목이 YMD가 아닐경우 기간별 필터링이 필요없으므로 분기 // TODO : 모든 위젯 타입 공통 적용
      if(columnGroupList === 'YMD'){
        filteredData = this.data.filter(item => {
          const itemDate = new Date(this.dateObjectDayFormating(item[columnGroupList]));
          return itemDate >= dayStart && itemDate <= dayEnd;
        });

        // sortType에 따라서 정렬
        if (this.widget.sortType === 1) {
          filteredData.sort((a, b) => new Date(this.dateObjectDayFormating(a.YMD)) - new Date(this.dateObjectDayFormating(b.YMD)));
        } else if (this.widget.sortType === 0) {
          filteredData.sort((a, b) => new Date(this.dateObjectDayFormating(b.YMD)) - new Date(this.dateObjectDayFormating(a.YMD)));
        }
      }if(columnGroupList === 'TIMESTAMP'){
        filteredData = this.data.filter(item => {
          const itemDate = new Date(this.dateObjectFormating(item[columnGroupList]));
          return itemDate >= dayStart && itemDate <= dayEnd;
        });

        // '20240720 00:00:00' 형태의 문자열을 Date 객체로 변환
        const parseDate = (dateString) => {
          const [datePart, timePart] = dateString.split(' ');
          const formattedDate = `${datePart.substring(0, 4)}-${datePart.substring(4, 6)}-${datePart.substring(6, 8)}T${timePart}`;
          return new Date(formattedDate);
        };
        if (this.widget.sortType === 1) {
          filteredData.sort((a, b) => parseDate(a.TIMESTAMP) - parseDate(b.TIMESTAMP));
        } else if (this.widget.sortType === 0) {
          filteredData.sort((a, b) => parseDate(b.TIMESTAMP) - parseDate(a.TIMESTAMP));
        }
      }if(columnGroupList === 'AGTID'){
        filteredData = this.data;
        // sortType에 따라서 정렬
        if (this.widget.sortType === 1) {
          filteredData.sort((a, b) => new Date(this.dateObjectDayFormating(a.AGTID)) - new Date(this.dateObjectDayFormating(b.AGTID)));
        } else if (this.widget.sortType === 0) {
          filteredData.sort((a, b) => new Date(this.dateObjectDayFormating(b.AGTID)) - new Date(this.dateObjectDayFormating(a.AGTID)));
        }
      }

      return filteredData;
    },
  },
  methods: {
    //날짜 포맷 변경 메서드
    dateObjectDayFormating(dateString) {
      let formattedDate = dateString.substring(0, 4) + '-' + dateString.substring(4, 6) + '-' + dateString.substring(6, 8);
      return formattedDate;
    },
    //최근 ( )일 조회일 경우 날짜 재정의 메서드
    dayStartChangeWhenRecent(){
      //최근 ( )일
      const recent = this.widget.recent;
      let date = new Date();
      const dateGroupCode = this.widget.dateGroupCode;
      let dayStart = '';
      if (dateGroupCode === this.dateFormat.daily || dateGroupCode === this.dateFormat.hour) {
        const daysAgo = date.getDate() - (recent-1);
        //date = date.setDate(daysAgo); 이렇게 설정하는 순간 타임스탬프 형식으로 변경됨
        date.setDate(daysAgo);
        dayStart = moment(date).format('YYYYMMDD');
      }
      return dayStart;
    },

  },
};
</script>
