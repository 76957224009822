<template>
  <li :key="index">
    <drag
        @drag="drag"
        @dragend="dragend"
        :draggable="true"
        :transfer-data="widgetTemplateData"
    >
      <div class="widget-box">
        <div class="widget-chart-box">
          <div class="widget-chart-icon">
            <img
                :src="require(`@/assets/images/report/widget_icon_${item.chartTypeCode.codeValue}.png`)"
                :title="item.title"
                draggable="false"
                class="w-2/5"
            />
            <p :title="item.title" v-if="textLength(item.title) <= 16">
              {{ item.title }}
            </p>
            <p class="leading-5" :title="item.title" v-if="textLength(item.title) > 16">
              {{item.title.substr(0, 8)}}<br>{{textLength(item.title) > 16? item.title.substring(8, 16)+'...' : item.title.substring(8, 16)}}
            </p>
          </div>
        </div>
      </div>
    </drag>
  </li>
</template>

<script>
import { Drag } from 'vue-drag-drop';
let mouseXY = {"x": null, "y": null};
export default {
  components: {
    Drag,
  },
  props: ['item','index'],
  methods: {
    drag(){
      this.$emit('drag')
    },
    dragend(){
      this.$emit('dragend')
    },
    textLength(str) {
      let len = 0;
      for (var i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length == 6) {
          len++;
        }
        len++;
      }
      return len;
    },
  },
  computed: {
    widgetTemplateData() {
      //조회 기간
      const dayStart = this.item.searchConditionValue.split('~')[0];
      const dayEnd = this.item.searchConditionValue.split('~')[1];

      //조회 대상 테이블 설정
      const dateGroupCode = this.item.dateGroupCode;
      //TODO : suffixes 전역변수로 변경
      const suffixes = {
        210: '_D',
        213: '_H'
      }
      const tableName = `${this.item.targetQuery}${suffixes[dateGroupCode]}`;
      const columnList = this.item.columnList.split(',');
      const columnGroupList = this.item.columnGroupList.split(',');
      const columnNameList = this.item.columnNameList.split(',');
      //차트가 스코어 카드일경우 subjectType(비교대상) 기본값 설정
      const subjectType = this.item.chartTypeCd === 1229? '1251' : '';
      return {
        id: 'temp',
        widgetTpId: this.item.id,
        pinned: false,
        width: this.item.width,
        height: this.item.height,
        positionX: this.item.positionX,
        positionY: this.item.positionY,
        title: this.item.title,
        columnList: columnList,
        columnNameList: columnNameList,
        columnGroupList: columnGroupList,
        dayStart: dayStart,
        dayEnd: dayEnd,
        widgetTp: this.item,
        durationType: this.item.durationType,
        isCalculationComplete: false,
        tableName:tableName,
        recent: 1, // U_WIDGET_TP 테이블에는 없는 컬럼이므로 기본값 설정
        subjectType: subjectType
      };
    }
  },
  mounted() {
    document.addEventListener("dragover", function (e) {
      mouseXY.x = e.clientX;
      mouseXY.y = e.clientY;
    }, false);
  }
};
</script>
