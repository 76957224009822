import Contact from '@/pages/euc/contact/index.vue';
import Top from '@/layouts/top.vue';
import Bottom from '@/layouts/bottom.vue';
import LeftMenu from '@/layouts/left-menu.vue';
import Breadcrumbs from '@/layouts/breadcrumbs.vue';
import ApprovalRequest from '@/pages/euc/phone/approval/request.vue';
import WorkerRequest from '@/pages/euc/phone/worker/request.vue';

export default [
  {
    path: '/euc/contact',
    name: 8516, // FIXME : 메뉴 ID 를 써야하는 하드코딩 변경 필요
    components: { default: Contact, top: Top, bottom: Bottom, left: LeftMenu, breadcrumbs: Breadcrumbs },
    meta: { requiresAuth: true, historyType: 2 },
  },
  {
    path: '/euc/phone/approval/request',
    name: 8538,
    components: { default: ApprovalRequest, top: Top, bottom: Bottom, left: LeftMenu, breadcrumbs: Breadcrumbs },
    meta: { requiresAuth: true, historyType: 2 },
  },
  {
    path: '/euc/phone/worker/request',
    name: 8552,
    components: { default: WorkerRequest, top: Top, bottom: Bottom, left: LeftMenu, breadcrumbs: Breadcrumbs },
    meta: { requiresAuth: true, historyType: 2 },
  },
];
