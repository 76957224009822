<template>
  <div id="default-tooltip">
    <div class='default-tooltip-header'>{{point.argumentText}}</div>
    <div class='default-tooltip-body'>
      <div class='series-name'><span class='top-series-name'>{{point.seriesName}}</span></div>
      <div class='value-text'><span class='top-series-value'>{{point.valueText}}</span></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    point: {
      type: Object,
    },
  },
}
</script>
<style>
.dxc-tooltip:has(#default-tooltip) {
  z-index: 3;
}

.default-tooltip-header {
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 1px solid #c5c5c5;
}

.default-tooltip-body {
  width: 130px;
}

.default-tooltip-body .series-name {
  font-weight: normal;
  opacity: 0.6;
  display: inline-block;
  line-height: 1.5;
  padding-right: 10px;
  width: 86px;
}

.default-tooltip-body .value-text {
  display: inline-block;
  line-height: 1.5;
  width: 30px;
}
</style>
