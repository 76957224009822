<template>
  <div class="page-title lin1">
    <h1 :style="isNormalMenu ? 'float: left;' : ''">{{ currentMenu.menuNm }}</h1>
    <div v-if="isNormalMenu">
      <button v-if="isFavoriteMenu" class="rounded-button on-star" @click="deleteFavorite"></button>
      <button v-else class="rounded-button off-star" @click="saveFavorite"></button>
    </div>
    <div class="page-text-map-group fr" v-if="!hidingMenuGroup">
      <span><router-link to="/">홈</router-link></span>
      <span v-for="menu in menuArray" :key="menu.menuNm">
        <router-link v-if="menu.pageUrl" :to="menu.pageUrl">{{ menu.menuNm }}</router-link>
        <span v-else>{{ menu.menuNm }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    menuId() {
      return parseInt(this.$route.params.menuid || this.$route.name) || 0;
    },
    currentMenu() {
      const defaultMenu = {
        menuNm: '종합현황',
        menuDepth: 1,
        menuTypeCd: this.$_enums.common.menuType.NORMAL_PAGE,
      };

      if (this.menuId === 0) {
        return defaultMenu;
      }

      const menu = this.$store.getters.getMenuList.find(menu => menu.id === this.menuId);
      return {
        menuNm: menu.menuNm,
        menuDepth: menu.menuDepth,
        parentId: menu.parentId,
        menuTypeCd: menu.menuTypeCd,
        pageUrl: menu.pageUrl || '',  // 기본 경로로 빈 문자열을 설정
      };
    },
    hidingMenuGroup() {
      return this.menuId === 0;
    },
    menuArray() {
      const menuArray = [];
      let menu = this.currentMenu;

      // 현재 메뉴를 배열에 추가
      menuArray.unshift(menu);

      // 상위 메뉴들을 배열에 추가
      while (menu.menuDepth > 1) {
        menu = this.findMenuById(menu.parentId);
        menuArray.unshift(menu);
      }

      return menuArray;
    },
    isNormalMenu() {
      return this.currentMenu.menuTypeCd === this.$_enums.common.menuType.NORMAL_PAGE;
    },
    isFavoriteMenu() {
      return this.$store.getters.getFavorite.some(d => d.menuId === this.menuId);
    },
  },
  methods: {
    findMenuById(id) {
      return this.$store.getters.getMenuList.find(menu => menu.id === id);
    },
    async deleteFavorite() {
      const payload = {
        actionname: 'MENU_FAVORITE_DELETE',
        data: {
          data: [
            {
              menuId: this.menuId,
            },
          ],
        },
        useErrorPopup: true,
      };
      await this.toggleFavorite(payload);
    },
    async saveFavorite() {
      const payload = {
        actionname: 'MENU_FAVORITE_SAVE',
        data: [{ menuId: this.menuId }],
        loading: true,
        useErrorPopup: true,
      };
      await this.toggleFavorite(payload);
    },
    async toggleFavorite(payload) {
      await this.CALL_API(payload);
      this.$_eventbus.$emit('getFavoriteMenu');
    },
  },
};
</script>

<style scoped>
.rounded-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-size: cover;
  cursor: pointer;
  outline: none;
  border: none;
  top: 5px;
  left: -5px;
}

.rounded-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
</style>